import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { PermissionsModel } from "../models/permissions.model";
import { catchError, Observable, tap } from "rxjs";

interface Permission {
  name: string;
}

interface Role {
  name: string;
  description?: string;
  permissions: Permission[];
}

@Injectable({
  providedIn: "root",
})
export class PermissionService extends BaseService<any> {
  override baseUrl: string = `${this.baseUrl}/roles`;

  // listar cargos
  listRoles(): Observable<any> {
    return this.api.get<any>(this.baseUrl).pipe(
      tap((_) => this.log("Cargos carregados")),
      catchError(this.handleError("Erro ao carregar cargos"))
    );
  }

  // adicionar um novo cargo
  addRole(payload: any): Observable<any> {
    return this.api.post<any>(this.baseUrl, payload).pipe(
      tap((_) => this.log("Cargo adicionado")),
      catchError(this.handleError("Erro ao adicionar cargo"))
    );
  }

  // atualizar um cargo existente
  updateRole(id: string, payload: Role): Observable<Role> {
    return this.api.put<Role>(`${this.baseUrl}/${id}`, payload).pipe(
      tap((_) => this.log("Cargo atualizado com sucesso")),
      catchError(this.handleError("Erro ao atualizar cargo"))
    );
  }

  // remover um cargo
  deleteRole(id: string): Observable<void> {
    return this.api.delete<void>(`${this.baseUrl}/${id}`).pipe(
      tap((_) => this.log(`Cargo com id ${id} removido com sucesso.`)),
      catchError(this.handleError(`Erro ao remover cargo com id ${id}`))
    );
  }
}
