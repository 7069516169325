<div mat-dialog-content class="dialog-form">
  <h2>
    Antes de prosseguir, nomeie o modelo de avaliação e defina a sua nota de
    corte.
  </h2>
  <mat-form-field appearance="outline">
    <mat-label>Nome do Modelo</mat-label>
    <input
      matInput
      [(ngModel)]="formData.title"
      maxlength="80"
      type="text"
      placeholder="Modelo Padrão"
      required
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Nota de Corte</mat-label>
    <input
      matInput
      [(ngModel)]="formData.passing_score"
      maxlength="80"
      type="text"
      placeholder="Ex.: 7"
      required
    />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="onNoClick()" class="button-rounded">
    Cancelar
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!formData.title || !formData.passing_score"
    (click)="onYesClick()"
    class="button-rounded"
  >
    Salvar avaliação
  </button>
</div>
