import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-mini-add-card",
  templateUrl: "./mini-add-card.component.html",
  styleUrls: ["./mini-add-card.component.sass"],
})
export class MiniAddCardComponent {
  @Input() route!: string;
  @Input() icon: string = "add"; // Icon default é 'today'

  constructor(private router: Router, private currentRoute: ActivatedRoute) {}

  navigate(): void {
    this.router.navigate([`${this.route}`], {
      relativeTo: this.currentRoute,
    });
  }
}
