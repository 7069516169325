<div class="row">
  <div>
    <h1 class="mb-0">Minhas Turmas</h1>
    <p class="sub-title">
      Faça o controle de suas turmas. Atualize a frequência e faça lançamento de
      notas.
    </p>
  </div>
</div>
<mat-card>
  <mat-card-content>
    <div class="container">
      <app-loading-spinner
        [isLoading]="isLoading"
        [containerHeight]="'50vh'"
      ></app-loading-spinner>
      <!-- <div *ngIf="profWoutTurma$ | async as response"></div> -->
      <table
        mat-table
        [dataSource]="turmas"
        *ngIf="!isLoading"
        class="manage-table"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Turma</th>
          <td
            style="cursor: pointer"
            mat-cell
            *matCellDef="let element"
            (click)="navigateWithData(element)"
          >
            <b>{{ element.title }}</b>
          </td>
        </ng-container>

        <ng-container matColumnDef="subjectGrade">
          <th mat-header-cell *matHeaderCellDef>Disciplina - Série</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subjectName }} - {{ element.grade_title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numStudent">
          <th mat-header-cell *matHeaderCellDef class="txt-algn-center">
            Estudantes
          </th>
          <td mat-cell *matCellDef="let element" class="txt-algn-center">
            {{ element.student_count }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activityYear">
          <th mat-header-cell *matHeaderCellDef class="txt-algn-center">
            Ano de atividade
          </th>
          <td mat-cell *matCellDef="let element" class="txt-algn-center">
            {{ element.activity_year }}
          </td>
        </ng-container>

        <ng-container matColumnDef="shift">
          <th mat-header-cell *matHeaderCellDef>Turno</th>
          <td mat-cell *matCellDef="let element">{{ element.shift }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <!-- <app-custom-pagination
        [paginationData]="pagination"
        (pageEventEmitter)="changeData($event)"
      >
      </app-custom-pagination> -->
    </div>
  </mat-card-content>
</mat-card>
