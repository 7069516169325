import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ActivityService } from '../../../shared/services/activity.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { UserSubjectClassService } from '../../../shared/services/user-subject-class.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { StudentService } from '../../../shared/services/student.service';
import { UserActivityService } from '../../../shared/services/user-activity.service';
import { UserService } from '../../../shared/services/user.service';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from '../../../../../../environments/env';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogOverview } from '../../../gestao/alunos/read-alunos/read-alunos.component';


const API_URL = environment.api_url;

export interface AtividadeSample {
  id: number,
  title: string,
  description: string,
  banner_url: string,
  status: string,
  user_id: number,
  subject_id: number,
  class_id: number,
  start_date: any,
  end_date: any,
  created_at: any,
  updated_at: any
}

@Component({
  selector: 'app-view-atividade',
  templateUrl: './view-atividade.component.html',
  styleUrls: ['./view-atividade.component.sass']
})
export class ViewAtividadeComponent {

  update: AtividadeSample[] = [];
  dataSource: AtividadeSample[] = [];
  displayedColumns: string[] = ['activity', 'series', 'status', 'deadline', 'more-options'];

  activity_id: string = '';

  activity_infos: any

  alunos_activity: any[] = []
  all_alunos_response: any[] = []
  alunosInClass: any

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private activityService: ActivityService,
    public dialog: MatDialog,
    private studentService: StudentService,
    private userActivityService: UserActivityService,
    private userService: UserService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) {
    this.route.params.subscribe(params => {
      this.activity_id = params['id'];
      this.getActivity(this.activity_id)
    });
    this.gets();
  }

  gets() {
    this.getAllResponse();
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@read', 'Atividades');
    this.breadcrumbService.set('@view', `Visualização`);
  }

  // getAllActivity() {
  //   this.activityService.getAllActivity(1, 10)
  //     .subscribe({
  //       next: (data) => {
  //         this.dataSource = data.data.data
  //         this.update = data.data.data
  //       },
  //       error: (error) => {
  //         console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
  //       },
  //       complete: () => {
  //         console.log("fetch infos atividades");
  //       },
  //     });
  // }

  getActivity(activity_id: string) {
    this.activityService.getOne(activity_id)
      .subscribe({
        next: (data) => {
          this.activity_infos = data.data
          this.getStudentsByClass();
          this.breadcrumbService.set("@view", `${this.activity_infos.title}`);
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
        },
        complete: () => {
          //console.log("fetch infos atividades");
        },
      });
  }

  openTurmasDialog() {
    this.dialog.open(TurmasDialog, {
      data: {
        activity_infos: this.activity_infos,
      },
    });
  }

  openCorrigirDialog(devolutiva: boolean, aluno: any) {
    const dialogRef = this.dialog.open(CorrigirDialog, {
      data: {
        activity_infos: this.activity_infos,
        devolutiva: devolutiva,
        resposta: aluno,
        activity_id: this.activity_id
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('O diálogo foi fechado');
      this.gets();
    });
  }

  openVerCorrigidas(devolutiva: boolean, aluno: any) {
    const dialogRef = this.dialog.open(VerCorrecaoDialog, {
      data: {
        activity_infos: this.activity_infos,
        devolutiva: devolutiva,
        resposta: aluno
      },
    });
  }


  getResponseSends() {
    this.userActivityService.getUsersActivity(this.activity_id, 1, 10, "Enviada para professor").subscribe({
      next: (data) => {
        this.alunos_activity = data.data.data;
        // console.log(this.alunos_activity)
      },
      error: (error) => {
        //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
      },
      complete: () => {
        //console.log("fetch infos atividades");
      },
    });
  }
  
  getAllResponse() {
    this.userActivityService.getAllUsersActivity(this.activity_id, 1, 10).subscribe({
      next: (data) => {
        this.all_alunos_response = data.data.data;
        this.alunos_activity = data.data.data;
        //Type status:
        // - Enviada para professor
        // - Atividade incompleta
        // - Corrigida pelo professor
        // console.log(this.all_alunos_response)
      },
      error: (error) => {
        //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
      },
      complete: () => {
        //console.log("fetch infos atividades");
      },
    });
  }

  getStudentsByClass() {
    this.userService.getUserByClass(this.activity_infos.class_id).subscribe({
      next: (apiData) => {
        this.alunosInClass = apiData.data!.data;
        // console.log(this.alunosInClass);
      },
      error: (error) => {
        // this.logService.error(`error in fetch the student by turma`);
      },
    });
  }

  file(download: boolean, fileUrl: string) {
    // console.log(`${API_URL}/${fileUrl}`);
    this.apiService.downloadFile(`${API_URL}/${fileUrl}`).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);

      if (download) {
        const link = document.createElement('a');
        link.href = url;
        link.download = this.extractFileName(fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        window.open(url);
      }
    });
  }
  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split('/');
    return urlParts[urlParts.length - 1];
  }

  deletActivity(id: string) {
    this.activityService.delete(id)
      .subscribe({
        next: (data) => {
          this.router.navigate(['/privado/atividade']);
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
        },
        complete: () => {
          // console.log("fetch infos atividades");
          // console.log(this.update);
        },
      });
  }

  despublicar(id: any) {

    this.activity_infos.published = null

    this.activityService.editActivity(id, this.activity_infos)
      .subscribe({
        next: (data) => {
          this.snackBar.open('Enviado com sucesso', 'Fechar', {
            duration: 3000,
            panelClass: ['mat-snackbar-success'],
          });
          // this.router.navigate(['/privado/atividade']);
          this.getActivity(this.activity_id)
        },
        error: (error) => {
          // this.erro();
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          //console.log("fetch infos estudante complete");
        },
      });
  }

  publicar(id: any) {
    let now = new Date()

    this.activity_infos.published = now

    this.activityService.editActivity(id, this.activity_infos)
      .subscribe({
        next: (data) => {
          this.snackBar.open('Enviado com sucesso', 'Fechar', {
            duration: 3000,
            panelClass: ['mat-snackbar-success'],
          });
          // this.router.navigate(['/privado/atividade']);
          this.getActivity(this.activity_id)
        },
        error: (error) => {
          // this.erro();
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          //console.log("fetch infos estudante complete");
        },
      });
  }

}
@Component({
  selector: 'turmas-dialog',
  templateUrl: './turmas-dialog.html',
  styleUrls: ['./turmas-dialog.sass'],
  standalone: true,
  imports: [MatDialogModule, NgIf, SharedModule],
})
export class TurmasDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity_infos: any,
    private userSubjectClassService: UserSubjectClassService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.currentUser = this.authService.getCurrentUser();
  }

  ngOnInit(): void {
    this.getUserSubjectClassService();
  }

  displayedColumns: string[] = ['select', 'turma'];
  sub: any
  currentUser: any

  selected_ids: number[] = [];
  getUserSubjectClassService() {
    this.userSubjectClassService.getUserSubjectClassService(this.currentUser.id)
      .subscribe({
        next: (data: any) => {
          this.sub = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title
            },
            class: {
              id: item.class.id,
              title: item.class.title
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title
            }
          }));
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          this.checkSelected()
          // console.log("fetch infos estudante complete");
        },
      });
  }

  selectAll(checked: boolean): void {
    this.sub.forEach((turma: any) => (turma.isSelected = checked));
    this.updateSelectedIds();
  }

  // Lógica para selecionar/deselecionar um estudante individualmente
  selectTurma(turma: any, checked: boolean): void {
    turma.isSelected = checked;
    this.updateSelectedIds();
  }

  // Atualiza o array selected_ids com os IDs dos estudantes selecionados
  updateSelectedIds(): void {
    this.selected_ids = this.sub
      .filter((turma: any) => turma.isSelected)
      .map((turma: any) => turma.class.id);
    // console.log(this.selected_ids);
  }

  checkSelected() {
    this.sub.forEach((turma: any) => {
      if (this.activity_infos.activity_infos.class_id == turma.class.id) {
        turma.isSelected = true
      }
    });
    this.updateSelectedIds();
  }
}


@Component({
  selector: 'corrigir-dialog',
  templateUrl: './corrigir-dialog.html',
  styleUrls: ['./corrigir-dialog.sass'],
  standalone: true,
  imports: [MatDialogModule, NgIf, SharedModule],
})
export class CorrigirDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity_infos: any,
    public dialogRef: MatDialogRef<DialogOverview>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private userActivityService: UserActivityService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    // console.log(this.activity_infos.resposta)
    this.devolutiva = this.activity_infos.devolutiva
    this.currentUser = this.authService.getCurrentUser();
  }


  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.activity_id = params['id'];
    });

    this.devolverForm = this.formBuilder.group({
      devolutiva: ['', [Validators.required]],
    });
    if (this.activity_infos.activity_infos.has_score) {
      this.corrigirForm = this.formBuilder.group({
        nota: ['', [Validators.required]],
        comentario: [''],
      });
    } else {
      this.corrigirForm = this.formBuilder.group({
        comentario: [''],
      });
    }
  }

  devolutiva: boolean

  submited: boolean = false

  currentUser: any

  devolverForm: any

  corrigirForm: any

  activity_id: any

  isLoading = false;

  corrigir() {
    this.isLoading = true;
    let corrigir
    if (this.corrigirForm.valid) {

      if (this.activity_infos.activity_infos.has_score) {
        corrigir = {
          "score": this.f2.nota.value,
          "status_describe": this.f2.comentario.value
        }
      } else {
        corrigir = {
          "score": 0,
          "status_describe": this.f2.comentario.value
        }
      }


      this.userActivityService.corrigirActivity(this.activity_infos.resposta.id, corrigir)
        .subscribe({
          next: (data) => {
            this.snackBar.open('Enviado com sucesso', 'Fechar', {
              duration: 3000,
              panelClass: ['mat-snackbar-success'],
            });
            this.onNoClick();
          },
          error: (error) => {
            // this.erro();
            this.snackBar.open('Erro ao enviar', 'Fechar', {
              duration: 3000,
              panelClass: ['mat-snackbar-success'],
            });
            //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
          },
          complete: () => {
            this.isLoading = false;
            //console.log("fetch infos estudante complete");
          },
        });


    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open('Operação com problema, preencha os campos obrigatórios', 'Fechar', {
        duration: 3000,
        panelClass: ['mat-snackbar-success'],
      });
    }
  }

  devolver() {
    this.isLoading = true;
    let devol
    if (this.devolverForm.valid) {

      devol = {
        "status_describe": this.f.devolutiva.value,
      }


      this.userActivityService.devolverActivity(this.activity_infos.resposta.id, devol)
        .subscribe({
          next: (data) => {
            this.snackBar.open('Enviado com sucesso', 'Fechar', {
              duration: 3000,
              panelClass: ['mat-snackbar-success'],
            });
            this.onNoClick();
          },
          error: (error) => {
            // this.erro();
            this.snackBar.open('Erro ao enviar', 'Fechar', {
              duration: 3000,
              panelClass: ['mat-snackbar-success'],
            });
            //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
          },
          complete: () => {
            this.isLoading = false;
            //console.log("fetch infos estudante complete");
          },
        });


    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open('Operação com problema, preencha os campos obrigatórios', 'Fechar', {
        duration: 3000,
        panelClass: ['mat-snackbar-success'],
      });
    }

  }

  get f() {
    return this.devolverForm.controls;
  }
  get f2() {
    return this.corrigirForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'ver-correcao-dialog',
  templateUrl: './ver-correcao-dialog.html',
  styleUrls: ['./ver-correcao-dialog.sass'],
  standalone: true,
  imports: [MatDialogModule, NgIf, SharedModule],
})
export class VerCorrecaoDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity_infos: any,
    public dialogRef: MatDialogRef<DialogOverview>,
  ) {
    this.devolutiva = this.activity_infos.devolutiva
    this.aluno = this.activity_infos.resposta
    //console.log(this.aluno);
  }


  ngOnInit(): void {

  }

  devolutiva: boolean

  aluno: any



  onNoClick(): void {
    this.dialogRef.close();
  }
}
