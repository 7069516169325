import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { ClassScheduleSubjectModel } from "../models/class-schedule-subject.model";
import { catchError, Observable, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";

@Injectable({
  providedIn: "root",
})
export class ClassScheduleSubjectService extends BaseService<ClassScheduleSubjectModel> {
  override baseUrl: string = `${this.baseUrl}/class-schedule`;


  public getGradeInterativa(
    class_id: string,
    class_slot_id?: string
  ): Observable<ApiResponse<ClassScheduleSubjectModel>> {
    let url = `${this.baseUrl}/class-subjects/${class_id}`;
    if (class_slot_id) {
      url = `${url}?class_slot_id=${class_slot_id}`;
    }
    return this.api.get<ApiResponse<ClassScheduleSubjectModel>>(url).pipe(
      tap((_) => this.log(`fetched grade da turma ${class_id}`)),
      catchError(this.handleError(`fetch grade`))
    );
  }

  public updtClassSlotId(
    class_slot_id: string,
    data: any
  ): Observable<ApiResponse<any>> {
    let url = `${this.baseUrl}/class-slot/${class_slot_id}`;
    return this.api.put<ApiResponse<ClassScheduleSubjectModel>>(url,data).pipe(
      tap((_) => this.log(`fetched grade da turma ${class_slot_id}`)),
      catchError(this.handleError(`fetch grade`))
    );
  }
}
