<div
  role="group"
  class="example-tel-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField.getLabelId()"
  [class.editing]="isEditing"
  [class.error]="errorState"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <div class="input-wrapper">
    <input
      class="example-tel-input-element"
      formControlName="start_time"
      [matTooltip]="getErrorMessage(1)"
      matTooltipPosition="above"
      matTooltipClass="error-tooltip"
      size="3"
      aria-label="start time"
      type="time"
      [class.error]="errorState"
      (input)="_handleInput(parts.controls.start_time, 'start_time')"
      [value]="parts.value.start_time"
      #startTime
    />
  </div>
  <span class="example-tel-input-spacer">-</span>
  <div class="input-wrapper">
    <input
      class="example-tel-input-element"
      formControlName="end_time"
      [matTooltip]="getErrorMessage(2)"
      matTooltipPosition="above"
      matTooltipClass="error-tooltip"
      aria-label="end time"
      type="time"
      [class.error]="errorState"
      (input)="_handleInput(parts.controls.end_time,'end_time')"
      [value]="parts.value.end_time"
      #endTime
    />
  </div>
</div>
