import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../professor/shared/components/dialog-score-plus-comment/dialog-score-plus-comment.component';

@Component({
  selector: "app-dialog-cpf",
  templateUrl: "./dialog-cpf.html",
//   styleUrls: ["./link-dialog.component.sass"],
})
export class DialogCpfComponent {
  readonly name = inject<DialogData>(MAT_DIALOG_DATA);
  constructor(
    public dialogRef: MatDialogRef<DialogCpfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}