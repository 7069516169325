import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: "app-mini-card-w-sidenav",
  templateUrl: "./mini-card-w-sidenav.component.html",
  styleUrls: ["./mini-card-w-sidenav.component.sass"],
})
export class MiniCardWSidenavComponent {
  @Input() title!: string;
  @Input() description: string = 'lorem ipsum';
  @Input() icon: string = "list_alt_check"; // Icon default é 'today'
  @Output() openSidenav = new EventEmitter<void>();

  constructor() {}

  onClick(): void {
    // Emit the event to the parent when the card is clicked
    this.openSidenav.emit();
  }
}
