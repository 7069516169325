import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { CustomPagination } from "../../shared/models/custom-pagination.model";
import { PermissionDialogComponent } from "../../shared/component/permission-dialog/permission-dialog.component";
import {
  PermissionEnum,
  PermissionsDetails,
} from "src/app/core/enum/permissions.enum";
import { PermissionService } from "../../../shared/services/permission.service";
import { PermissionsModel } from "../../../shared/models/permissions.model";
import { GestorService } from "../../../shared/services/gestor.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
interface Positions {
  permission: string;
  position: { [key: string]: string };
}
interface Manager {
  name: string;
}

interface ManagerForms {}
@Component({
  selector: "app-read-cargos-e-permissoes",
  templateUrl: "./read-cargos-e-permissoes.component.html",
  styleUrls: ["./read-cargos-e-permissoes.component.sass"],
})
export class ReadCargosEPermissoesComponent implements AfterViewInit, OnInit {
  displayedColumnsCollaborator: string[] = [
    "name",
    "position",
    "function",
    "action",
  ];
  displayedColumnsPositions: string[] = ["permissions"];
  dataSourceManagers = new MatTableDataSource<Manager>([]);
  dataSourcePositions!: MatTableDataSource<Positions>;
  roles: PermissionsModel[] = [];
  managerForm!: FormGroup;
  isLoading: Boolean = false;
  originalData: any[] = [];
  searchForm = this.fb.group({
    searchControl: "",
  });
  formacoes = [
    { label: "Nível Superior Completo", value: "Nível Superior Completo" },
    { label: "Nível Superior Incompleto", value: "Nível Superior Incompleto" },
    { label: "Pós-graduação Completo", value: "Pós-graduação Completo" },
    { label: "Pós-graduação Incompleto", value: "pos_graduacao_incompleto" },
    { label: "Mestrado", value: "Mestrado" },
    { label: "Doutorado", value: "Doutorado" },
    { label: "Ensino Médio Completo", value: "Ensino Médio Completo" },
    { label: "Ensino Médio Incompleto", value: "Ensino Médio Incompleto" },
  ];
  functions = [
    { label: "Secretário", value: "Secretário" },
    { label: "Administrador", value: "Administrador" },
    { label: "Gestão de professores", value: "Gestão de professores" },
    { label: "Controle de alunos", value: "Controle de alunos" },
  ];
  selectedFormacao: string = "";
  selectedFunction: string = "";
  selectedPermission: string = "";
  isEditing: boolean = false;
  currentManagerId: string | null = null;

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private permissionService: PermissionService,
    private managersService: GestorService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.dataSourcePositions = new MatTableDataSource<Positions>(
      this.ELEMENT_DATA_POSITIONS
    );

    this.sendDataManagers();
    this.fetchRoles();
    this.fetchManagers();
  }

  sendDataManagers() {
    const dataSend = (this.managerForm = this.fb.group({
      cpf: ["", [Validators.required]],
      name: ["", [Validators.required]],
      birthDate: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", [Validators.required]],
      registration: ["", [Validators.required]],
      formacao: ["", [Validators.required]],
      function: ["", [Validators.required]],
      permission: ["", [Validators.required]],
    }));

    return dataSend;
  }

  submitManagers(): void {
    this.isLoading = true;

    if (this.managerForm.valid) {
      const formData = this.managerForm.value;

      const managerPayload = {
        name: formData.name,
        username: formData.cpf,
        password: "ativedu",
        role_id: this.selectedPermission,
        metadata: [
          { name: "cpf", value: formData.cpf },
          { name: "birthDate", value: formData.birthDate },
          { name: "email", value: formData.email },
          { name: "phone", value: formData.phone },
          { name: "registration", value: formData.registration },
          { name: "formacao", value: formData.formacao },
          { name: "function", value: formData.function },
          { name: "permission", value: formData.permission },
        ],
      };

      console.log("managerPayload:", managerPayload);
      console.log("role_id:", this.selectedPermission);

      if (this.isEditing && this.currentManagerId) {
        this.managersService
          .editGestor(this.currentManagerId, managerPayload)
          .subscribe({
            next: (response) => {
              this.isEditing = false;
              this.isLoading = false;
              this.currentManagerId = null;
              this.showMessageSnackBar("Gestor atualizado com sucesso!");
              this.fetchManagers();

              setTimeout(() => {
                this.changeTab(0);
                this.resetForm();
              }, 2000);
            },
            error: (err) => {
              this.isLoading = false;
              console.error("Erro ao atualizar o gestor:", err);
              this.showMessageSnackBar("Erro ao atualizar o gestor.");
            },
          });
      } else {
        this.saveManager(managerPayload);
      }
    }
  }
  /* this.saveManager(managerPayload);
    } else {
      this.isLoading = false;
      this.showMessageSnackBar(
        "Operação com problema, preencha os campos obrigatórios."
      );
      console.log("Formulário inválido");
    }
  } */

  saveManager(manager: any, linkUserSchool: number = 0): void {
    this.managersService.saveGestor(manager, linkUserSchool).subscribe({
      next: (response) => {
        let message = "Gestor(a) cadastrado(a) com sucesso";

        if (
          response.data?.message != null &&
          response.data?.status_code == 202
        ) {
          message = response.data?.message;
        }
        this.resetForm();
        this.fetchManagers();
        this.showMessageSnackBar(message);
        this.isLoading = false;

        setTimeout(() => {
          this.changeTab(0);
        }, 2000);

        console.log("Gestor salvo com sucesso:", response);
      },
      error: (err) => {
        this.isLoading = false;
        console.error("Erro ao salvar o gestor:", err);
        this.showMessageSnackBar("Erro ao salvar o gestor. Tente novamente.");
      },
    });
  }

  redirectToEditManager(managerId: string): void {
    this.isEditing = true;
    this.currentManagerId = managerId;

    this.managersService.getManagerById(managerId).subscribe({
      next: (response) => {
        const manager = response.data;
        console.log("dados do gestor retornados pela API:", manager);

        const metadata = manager.metadata || [];

        this.managerForm.patchValue({
          name: manager.name || "",
          cpf: metadata.find((meta: any) => meta.name === "cpf")?.value || "",
          birthDate:
            metadata.find((meta: any) => meta.name === "birthDate")?.value ||
            "",
          email:
            metadata.find((meta: any) => meta.name === "email")?.value || "",
          phone:
            metadata.find((meta: any) => meta.name === "phone")?.value || "",
          formacao:
            metadata.find((meta: any) => meta.name === "formacao")?.value || "",
          function:
            metadata.find((meta: any) => meta.name === "function")?.value || "",
          permission:
            metadata.find((meta: any) => meta.name === "permission")?.value ||
            "",
          registration:
            metadata.find((meta: any) => meta.name === "registration")?.value ||
            "",
        });

        this.selectedPermission = manager.role_id;

        /* console.log("dados preenchidos no formulário:", this.managerForm.value); */

        this.tabGroup.selectedIndex = 1;
      },
      error: (err) => {
        console.error("Erro ao buscar o gestor:", err);
        this.isLoading = false;
        this.showMessageSnackBar("Erro ao carregar os dados do gestor.");
      },
    });
  }

  resetForm() {
    this.managerForm.reset();

    Object.keys(this.managerForm.controls).forEach((key) => {
      this.managerForm?.get(key)?.setErrors(null);
    });
  }

  changeTab(number: number): void {
    this.tabGroup.selectedIndex = number;
  }

  cancel() {
    this.changeTab(0);
    this.resetForm();
  }

  fetchManagers(): void {
    this.managersService.listManagers().subscribe({
      next: (response) => {
        this.originalData = response?.data?.data || [];
        this.dataSourceManagers.data = [...this.originalData];
        console.log(response?.data?.data);
      },
      error: (err) => {
        console.log("Erro ao buscar os cargos do backend:", err);
      },
    });
  }

  getFunctionValue(metadata: any[]): string {
    const functionObj = metadata.find((item) => item.name === "function");
    return functionObj ? functionObj.value : "Sem função";
  }

  submitSearch() {
    this.search(this.searchForm.value.searchControl);
  }

  search(value: any) {
    const lowerCaseValue = (value ?? "").toLowerCase();

    this.dataSourceManagers.data = this.originalData.filter((manager: any) =>
      manager.name.toLowerCase().includes(lowerCaseValue)
    );
  }

  showMessageSnackBar(message: string): void {
    this.snackBar.open(message, "Fechar", {
      duration: 5000,
      panelClass: ["mat-snackbar-success"],
    });
  }

  translateRoleName(roleName: string): string {
    const roleTranslations: { [key: string]: string } = {
      student: "Estudante",
      teacher: "Professor",
      teacher_administrator: "Professor Administrador",
      school_administrator: "Administrador da Escola",
      administrator: "Administrador",
    };

    return roleTranslations[roleName] || roleName;
  }

  reducingColumnCharacters(text: string, maxLength: number = 13): string {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  }

  fetchRoles(): void {
    this.permissionService.listRoles().subscribe({
      next: (response) => {
        const roles = response?.data?.data;
        /* console.log(roles); */
        this.roles = roles;
        if (Array.isArray(roles)) {
          roles.forEach((role: any) => {
            const translatedName = this.translateRoleName(role.name);

            if (
              !["Estudante", "Professor", "Professor Administrador"].includes(
                translatedName
              )
            ) {
              this.displayedColumnsPositions.push(translatedName);
              this.dataSourcePositions.data.forEach((position) => {
                position.position[translatedName] = "";
              });
            }
          });

          this.dataSourcePositions.data = [...this.dataSourcePositions.data];
        } else {
          console.error("Formato de resposta inválido", roles);
        }
      },
      error: (err) => {
        console.error("Erro ao buscar os cargos do backend:", err);
      },
    });
  }

  hasPermission(event: any, column: any) {
    const originalColumnName = this.roles.find(
      (role: any) => this.translateRoleName(role.name) === column
    )?.name;

    const role: any = this.roles.find(
      (role: any) => role.name === originalColumnName
    );
    const hasPermission = role?.permissions.find(
      (permission: any) => permission.name === event.permission
    );

    return hasPermission;
  }

  /* PAGINAÇÃO - NÃO IMPLEMENTADO */
  ngAfterViewInit() {}

  pagination: CustomPagination = {
    length: 0,
    pageSize: 25,
  };

  changeData(event: PageEvent) {
    /* this.getAllTTeachers(event.pageIndex + 1, event.pageSize); */
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(PermissionDialogComponent, {
      data: {
        title: "Dados da Permissão",
        inputLabel: "Nome",
        titleConfirmationButton: "Continuar",
        isInputVisible: true,
        buttonVisible: true,
        message: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openConfirmationDialog(result);
      }
    });
  }

  openConfirmationDialog(result: string): void {
    const dialogRef = this.dialog.open(PermissionDialogComponent, {
      data: {
        title: "Atenção!",
        message: `Você está criando um novo perfil de permissões chamado "${result}", deseja continuar?`,
        titleConfirmationButton: "Sim",
        isInputVisible: false,
        buttonVisible: true,
      },
    });

    dialogRef.afterClosed().subscribe((confirmationResult) => {
      if (confirmationResult) {
        this.addColumn(result);
        this.openSuccessDialog();
      }
    });
  }

  openSuccessDialog(): void {
    this.dialog.open(PermissionDialogComponent, {
      data: {
        title: "Pronto!",
        message:
          "Um novo perfil de permissões foi criado. Agora você pode definir as permissões disponíveis para este cargo!",
        isInputVisible: false,
        buttonVisible: false,
      },
    });
  }

  addColumn(cargo: string): void {
    this.displayedColumnsPositions.push(cargo);

    this.dataSourcePositions.data.forEach((position) => {
      position.position[cargo] = "";
    });

    const rolePayload: any = {
      name: cargo,
      description: "",
      permissions: [{ id: "", name: "authentication" }],
    };

    this.permissionService.addRole(rolePayload).subscribe({
      next: (response) => {
        console.log("Cargo adicionado com sucesso:", response);
        this.roles.push(response.data);
        this.dataSourcePositions.data = [...this.dataSourcePositions.data];
        console.log(this.roles);
      },
      complete: () => {
        console.log("Adicionado com sucesso");
      },
      error: (err) => {
        console.error(
          "Erro ao adicionar cargo:",
          err.error || err.message || err
        );
      },
    });
  }

  openDialogDelete(column: string): void {
    const dialogRef = this.dialog.open(PermissionDialogComponent, {
      data: {
        title: "Atenção!",
        message: `Você deseja mesmo deletar o cargo "${column}"?`,
        titleConfirmationButton: "Sim",
        isInputVisible: false,
        buttonVisible: true,
      },
    });

    dialogRef.afterClosed().subscribe((confirmationResult) => {
      if (confirmationResult) {
        this.removeColumn(column);
        this.openSuccessDialogDelete();
      }
    });
  }

  openSuccessDialogDelete(): void {
    this.dialog.open(PermissionDialogComponent, {
      data: {
        title: "Pronto!",
        message: "Cargo deletado com sucesso!",
        isInputVisible: false,
        buttonVisible: false,
      },
    });
  }

  /* funcao que manipula o scroll a cima da tabela */
  syncScroll(source: HTMLElement, target: HTMLElement): void {
    target.scrollLeft = source.scrollLeft;
  }

  /* remove o cargo */
  removeColumn(column: string): void {
    const columnIndex = this.displayedColumnsPositions.indexOf(column);

    if (columnIndex > -1) {
      const role: any = this.roles.find((role: any) => role.name === column);

      console.log("removendo cargo com id:", role?.id);

      if (role?.id) {
        this.permissionService.deleteRole(role.id).subscribe({
          next: () => {
            this.displayedColumnsPositions.splice(columnIndex, 1);
            this.dataSourcePositions.data.forEach((position) => {
              delete position.position[column];
            });
            this.dataSourcePositions.data = [...this.dataSourcePositions.data];
            /* remove o cargo da lista de roles */
            this.roles = this.roles.filter((r) => r.id !== role.id);
          },
          error: (err) => {
            console.error("Erro ao remover cargo:", err);
          },
        });
      } else {
        console.error("ID do cargo não encontrado.");
      }
    }
  }

  togglePermission(event: any, column: string, position: any): void {
    const checked = event.checked;

    const role: any = this.roles.find((role: any) => role.name === column);

    const hasPermission = role?.permissions.find(
      (permission: any) => permission.name === position.permission
    );

    if (checked) {
      if (hasPermission) {
        console.log("Permissão já existente");
        return;
      } else {
        role.permissions.push({ name: position.permission });
        console.log("Permissão adicionada com sucesso", position);
      }
    } else {
      const index = role.permissions.findIndex(
        (permission: any) => permission.name === position.permission
      );
      if (index > -1) {
        role.permissions.splice(index, 1);
      }
    }

    this.permissionService.updateRole(role.id, role).subscribe({
      next: () => {
        this.dataSourcePositions.data = [...this.dataSourcePositions.data];
      },
      error: (err) => {
        console.error("Erro ao adicionar permissão:", err);
      },
    });
  }

  getPermissionTitle(permission: string): string {
    const permissionDetail = PermissionsDetails[permission as PermissionEnum];
    return permissionDetail ? permissionDetail.title : "Título não encontrado";
  }

  highlightRowAndColumn(
    event: MouseEvent,
    columnIndex: number,
    columnName: string
  ) {
    const target = event.target as HTMLElement;

    // adiciona classe para a linha inteira
    const row = target.closest("tr");
    row?.classList.add("highlight");

    // adiciona classe para todas as celulas na coluna especifica
    const cells = document.querySelectorAll(
      `.custom-cell:nth-child(${columnIndex + 2})`
    );
    cells.forEach((cell) => cell.classList.add("highlight"));
  }

  isModifyAllowed(element: any): boolean {
    const role: any = this.roles.find((role: any) => role.name === element);

    return !role?.modify === true ? true : false;
  }

  // para remover o destaque
  removeHighlight(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const row = target.closest("tr");
    row?.classList.remove("highlight");

    // para remover a classe de destaque das celulas da coluna
    const cells = document.querySelectorAll(".custom-cell.highlight");
    cells.forEach((cell) => cell.classList.remove("highlight"));
  }

  ELEMENT_DATA_POSITIONS: Positions[] = [
    { permission: PermissionEnum.REGISTER_MANAGER, position: {} },
    { permission: PermissionEnum.EDIT_MANAGER_PROFILE, position: {} },
    { permission: PermissionEnum.REGISTER_STUDENT, position: {} },
    { permission: PermissionEnum.EDIT_STUDENT_PROFILE, position: {} },
    { permission: PermissionEnum.GENERATE_STUDENT_PASSWORD, position: {} },
    { permission: PermissionEnum.REGISTER_TEACHER, position: {} },
    { permission: PermissionEnum.EDIT_TEACHER_PROFILE, position: {} },
    { permission: PermissionEnum.GENERATE_TEACHER_PASSWORD, position: {} },
    { permission: PermissionEnum.CREATE_CLASS, position: {} },
    { permission: PermissionEnum.UPDATE_CLASS, position: {} },
    { permission: PermissionEnum.ADD_SPECIAL_SUBJECTS, position: {} },
    { permission: PermissionEnum.ADD_STUDENTS_TO_CLASS, position: {} },
    { permission: PermissionEnum.FILL_CLASS_SCHEDULE, position: {} },
    { permission: PermissionEnum.CREATE_SUBJECT, position: {} },
    { permission: PermissionEnum.EDIT_SUBJECT, position: {} },
    { permission: PermissionEnum.EXPORT_REPORT_CARD, position: {} },
    { permission: PermissionEnum.ACCESS_ATTENDANCE, position: {} },
    { permission: PermissionEnum.EDIT_SUBJECT_HOURS, position: {} },
    { permission: PermissionEnum.EDIT_CLASS_EVALUATION_MODEL, position: {} },
    { permission: PermissionEnum.MANAGE_EVALUATIONS, position: {} },
  ];
}
