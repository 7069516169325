<!-- <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav" mode="side">
        <mat-nav-list>
            <a mat-list-item *ngFor="let link of navLinks" [routerLink]="link.route">
                <mat-icon color="primary">{{ link.icon }}</mat-icon>
                <h5>{{ link.label }}</h5>
            </a>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <p><button mat-button (click)="sidenav.toggle()">Toggle</button></p>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container> -->
<app-navbar></app-navbar>
<button mat-icon-button class="display-none-desktop button-rounded" (click)="leftMenu()">
  <mat-icon>menu</mat-icon>
</button>
<mat-drawer-container class="main-page">
  <mat-drawer id="sidebar" mode="side" opened class="side-bar">
    <mat-nav-list>
      <ng-container *ngFor="let link of navLinks">
        <a [ngClass]="{'display-none-desktop': link.label === 'Mudar escola' }"
          (click)="logout(link.label)" mat-list-item [routerLink]="link.route" routerLinkActive="active-link"
          *ngIf="isLinkVisible(link.roles)">
          <div class="icon-container">
            <mat-icon color="primary">{{ link.icon }}</mat-icon>
          </div>
          <p class="mat-small">{{ link.label }}</p>
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer [opened]="drawerCondition" class="min-width" position="end">
    <app-drawer-card *ngIf="!drawerType" [exams]="drawerContent"></app-drawer-card>
    <app-drawer-text *ngIf="drawerType" [content]="drawerContent"></app-drawer-text>
  </mat-drawer>
  <mat-drawer-content class="main-content">
    <app-breadcrumb></app-breadcrumb>

    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<!-- <app-footer></app-footer> -->