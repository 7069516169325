import { Injectable } from "@angular/core";
import { BaseService } from "src/app/core/services/base.service";
import { TimeSlotModel } from "../../gestao/shared/models/time-slot.model";
import { catchError, Observable, tap } from "rxjs";
import { ApiResponse } from "src/app/core/models/api-response";
import { ClassScheduleSubjectModel } from "../models/class-schedule-subject.model";
import { gradeInterativaDTO } from "../models/classSchedule/gradeInterativaDTO.model";
import { AddScheduleModelDTO } from "../models/addScheduleModelDTO.model";

@Injectable({
  providedIn: "root",
})
export class SchoolScheduleService extends BaseService<TimeSlotModel> {
  protected override baseUrl: string = `${this.baseUrl}/class-schedule/models`;
  public getOneByClassAndUserSubjectClass(
    schoolSchedule_id: string,
    class_id: string,
    user_subject_class_id?: string
  ): Observable<ApiResponse<gradeInterativaDTO>> {
    let url = `${this.baseUrl}/${schoolSchedule_id}?class_id=${class_id}`;
    if (user_subject_class_id) {
      url = `${url}&user_subject_class_id=${user_subject_class_id}`;
    }
    return this.api.get<ApiResponse<gradeInterativaDTO>>(url).pipe(
      tap((_) =>
        this.log(`fetched schoolSchedule by grade and user_subject_class`)
      ),
      catchError(this.handleError(`fetch schoolSchedule`))
    );
  }

  public addScheduleModel2Class(
    class_id: string,
    schedule_model_id: AddScheduleModelDTO
  ): Observable<ApiResponse<any>> {
    let url = `${this.baseUrl}/add-to-class/${class_id}`;
    return this.api
      .put<any>(url, schedule_model_id)
      .pipe(tap(), catchError(this.handleError("Ocorreu um erro")));
  }
}
