<div class="wrap-title">
    <h1>
        Controle de Atividades
    </h1>
</div>
<p class="sub-title">
    Faça o controle de suas atividades, crie, edite ou habilite as atividades para suas turmas
</p>

<div class="container">
    <mat-card>
        <mat-card-content class="div-comand">
            <!-- <div class="row">
                <div class="col">
                    <h2 class="title-activity">
                        Simulado ENEM - Redação
                    </h2>
                    <h3 class="title-activity">
                        Módulo 3: Criação e Redação
                    </h3>
                </div>
                <div class="col">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-rounded">
                        <button routerLink="../../edit/{{activity_id}}" mat-menu-item>
                            <mat-icon>edit</mat-icon>
                            <span>Editar Atividade</span>
                        </button>

                        <button mat-menu-item routerLink="../../edit/{{activity_id}}" [queryParams]="{ copy: 'true' }">
                            <mat-icon>file_copy</mat-icon>
                            <span>Copiar Atividade</span>
                        </button>
                        <button mat-menu-item (click)="deletActivity(activity_id)">
                            <mat-icon>delete</mat-icon>
                            <span>Deletar Atividade</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="line"></div> -->
            <div class="row">
                <div class="col">
                    <h2 class="title-activity">
                        {{activity_infos.title}}
                    </h2>
                    <h3 class="title-activity">
                        <div [innerHTML]="activity_infos.description"></div>
                    </h3>
                </div>
                <div class="col">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="menu-rounded">
                        <button routerLink="../../edit/{{activity_id}}" [queryParams]="{ copy: 'false' }" mat-menu-item>
                            <mat-icon>edit</mat-icon>
                            <span>Editar Atividade</span>
                        </button>
                        <button *ngIf="activity_infos.published != null" (click)="despublicar(activity_id)"
                            mat-menu-item>
                            <mat-icon>download</mat-icon>
                            <span>Despublicar</span>
                        </button>
                        <button *ngIf="activity_infos.published == null" (click)="publicar(activity_id)" mat-menu-item>
                            <mat-icon>upload</mat-icon>
                            <span>Publicar</span>
                        </button>
                        <!-- <button mat-menu-item (click)="openTurmasDialog()" class="rotate90">
                            <mat-icon>switch_access_shortcut</mat-icon>
                            <span>Atribuir a Turma</span>
                        </button> -->
                        <!-- <button mat-menu-item>
                            <mat-icon>download</mat-icon>
                            <span>Baixar Atividade</span>
                        </button> -->
                        <button mat-menu-item routerLink="../../edit/{{activity_id}}" [queryParams]="{ copy: 'true' }">
                            <mat-icon>file_copy</mat-icon>
                            <span>Copiar Atividade</span>
                        </button>
                        <button mat-menu-item (click)="deletActivity(activity_id)">
                            <mat-icon>delete</mat-icon>
                            <span>Deletar Atividade</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>
<div class="container">
    <h2>Atividades Salvas</h2>
    <mat-card>
        <mat-card-content>

                    <div class="row div-list">
                        <div class="col">
                            <h2 class="title-activity">
                                Atividades Recebidas
                            </h2>
                            <h3 class="title-activity">
                                Recebidos:
                                <span *ngIf="all_alunos_response.length != undefined|| all_alunos_response.length != 0">
                                    {{all_alunos_response.length}}
                                </span>
                                <span *ngIf="all_alunos_response.length == undefined">
                                    0
                                </span>/{{alunosInClass.length}}
                            </h3>
                        </div>
                    </div>

                    <table mat-table [dataSource]="alunos_activity" class="mat-elevation-z8">

                        <ng-container matColumnDef="activity">

                            <th mat-header-cell *matHeaderCellDef> Aluno </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.user.name != undefined">{{element.user.name}}</span>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="series">
                            <th mat-header-cell *matHeaderCellDef> Arquivo enviado </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="nome_arquivo" *ngIf="element.attachment_url"
                                    [matMenuTriggerFor]="matMenuArquivo">
                                    <mat-icon>attachment</mat-icon>
                                    {{extractFileName(element.attachment_url)}}
                                </span>
                                <span class="sem_arquivo" *ngIf="!element.attachment_url">
                                    (Sem anexo)
                                </span>
                                <mat-menu #matMenuArquivo="matMenu" class="menu-rounded">
                                    <button mat-menu-item (click)="file(false, element.attachment_url)">
                                        <mat-icon>remove_red_eye</mat-icon>
                                        <span>Ver arquivo</span>
                                    </button>
                                    <button mat-menu-item (click)="file(true, element.attachment_url)">
                                        <mat-icon>file_download</mat-icon>
                                        <span>Baixar arquivo</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </ng-container>
                        
                        <!-- Weight Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> <span>{{element.status}}</span>
                            </td>

                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="deadline">
                            <th mat-header-cell *matHeaderCellDef> Recebido em </th>
                            <td mat-cell *matCellDef="let element"> <span
                                    *ngIf="element.send_date != undefined">{{element.send_date|date:'dd/MM/yyyy HH:mm'}}</span>
                            </td>
                        </ng-container>

                        <!-- Weight Column -->

                        <ng-container matColumnDef="more-options">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="menu-rounded">
                                    <button (click)="openCorrigirDialog(false, element)" mat-menu-item *ngIf="element.status == 'Enviada para professor'">
                                        <mat-icon>check_box</mat-icon>
                                        <span>Corrigir</span>
                                    </button>
                                    <button (click)="openCorrigirDialog(true,element)" mat-menu-item *ngIf="element.status == 'Enviada para professor'">
                                        <mat-icon>compare_arrows</mat-icon>
                                        <span>Dar devolutiva ao aluno</span>
                                    </button>
                                    <button (click)="openVerCorrigidas(false, element)" mat-menu-item *ngIf="element.status == 'Corrigida pelo professor'">
                                        <mat-icon>check_box</mat-icon>
                                        <span>Ver correção</span>
                                    </button>
                                    <button (click)="openVerCorrigidas(true, element)" mat-menu-item *ngIf="element.status == 'Atividade incompleta'">
                                        <mat-icon>compare_arrows</mat-icon>
                                        <span>Ver devolutiva</span>
                                    </button>
                                </mat-menu>
                                <!-- <span *ngIf="element.turmas != undefined">{{element.turmas}}</span> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div class="div-sem-respostas"
                        *ngIf="alunos_activity.length == undefined || alunos_activity.length == 0">
                        <span class="nenhuma-atividade">
                            Nenhuma resposta recebida
                        </span>
                    </div>

        </mat-card-content>
    </mat-card>
</div>