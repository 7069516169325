import { NgModule } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { CreateTurmasComponent } from "./turmas/create-turmas/create-turmas.component";
import { GradeEscolarComponent } from "./grade-escolar/grade-escolar.component";
import { TurmaComponent } from "./turmas/turma/turma.component";
import { EditTurmasComponent } from "./turmas/edit-turmas/edit-turmas.component";
import { RouterModule } from "@angular/router";
import { ReadTurmasComponent } from "./turmas/read-turmas/read-turmas.component";
import { SharedModule as GestaoSharedModule } from "./shared/shared.module";
import { SharedModule } from "../shared/shared.module";
import { DisciplinaComponent } from "./disciplina/disciplina.component";
import { CreateDisciplinaComponent } from "./disciplina/create-disciplina/create-disciplina.component";
import { ReadDisciplinaComponent } from "./disciplina/read-disciplina/read-disciplina.component";
import { EditDisciplinaComponent } from "./disciplina/edit-disciplina/edit-disciplina.component";
import { AlunosComponent } from "./alunos/alunos/alunos.component";
import { ReadAlunosComponent } from "./alunos/read-alunos/read-alunos.component";
import { EditAlunosComponent } from "./alunos/edit-alunos/edit-alunos.component";
import { ProfessoresComponent } from "./professores/professores/professores.component";
import { ReadProfessoresComponent } from "./professores/read-professores/read-professores.component";
import { EditProfessoresComponent } from "./professores/edit-professores/edit-professores.component";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import ptBr from "@angular/common/locales/pt";
import { CustomDateAdapter } from "../../shared/adapters/custom-date-adapter";
import { UtcDatePipe } from "../../shared/adapters/utc-date.pipe";
import { ReadGestoresComponent } from "./gestores/read-gestores/read-gestores.component";
import { GestoresComponent } from "./gestores/gestores/gestores.component";
import { EditGestoresComponent } from "./gestores/edit-gestores/edit-gestores.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PlanoLetivoComponent } from "./planoletivo/plano-letivo/plano-letivo.component";
import { ReadAvalComponent } from "./planoletivo/read-aval/read-aval.component";
import { PlanoLetivoContentComponent } from "./planoletivo/plano-letivo-content/plano-letivo-content.component";
import { CreateAvalComponent } from "./planoletivo/create-aval/create-aval.component";
import { CargoEPermissaoComponent } from "./cargos-e-permissoes/cargo-e-permissao/cargo-e-permissao.component";
import { ReadCargosEPermissoesComponent } from './cargos-e-permissoes/read-cargos-e-permissoes/read-cargos-e-permissoes.component';
import { SchoolScheduleComponent } from './planoletivo/school-schedule/school-schedule.component';
import { SchoolGradeTopicsComponent } from "./planoletivo/school-grade-topics/school-grade-topics.component";

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    CreateTurmasComponent,
    GradeEscolarComponent,
    TurmaComponent,
    EditTurmasComponent,
    ReadTurmasComponent,
    AlunosComponent,
    ReadAlunosComponent,
    DisciplinaComponent,
    CreateDisciplinaComponent,
    ReadDisciplinaComponent,
    EditDisciplinaComponent,
    EditAlunosComponent,
    ProfessoresComponent,
    ReadProfessoresComponent,
    EditProfessoresComponent,
    UtcDatePipe,
    ReadGestoresComponent,
    GestoresComponent,
    EditGestoresComponent,
    PlanoLetivoComponent,
    ReadAvalComponent,
    PlanoLetivoContentComponent,
    CreateAvalComponent,
    CargoEPermissaoComponent,
    ReadCargosEPermissoesComponent,
    SchoolScheduleComponent,
    SchoolGradeTopicsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GestaoSharedModule,
    SharedModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: "pt" },
  ],
  exports: [],
})
export class GestaoModule {}
