import { ApiResponse } from './../models/api-response';
import { Injectable } from '@angular/core';
import { map, distinctUntilChanged, tap, catchError, take } from "rxjs/operators";

import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { environment } from '../../../environments/env';
import { User } from '../models/user.model';
import { UserAuth } from './../models/user-login.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { School } from 'src/app/modulos/private/shared/models/school.model';
const API_URL = `${environment.api_url}/authentication/login`;
const API_TOKEN = `/authentication/refresh-token`;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private router: Router
  ) { }
  
  public getTokenData(): any {
    const token = this.localStorageService.getToken();
    return this.localStorageService.getDecodedToken(token);
  }

  public populate(): Observable<any>{
    if( this.currentUserSubject.value.id != null){
      return of(null);
    }
    if (this.localStorageService.getToken()) {
      return this.apiService.get(API_TOKEN+"?school_id="+this.localStorageService.getSelectedSchool()).pipe(
        tap((data) => {
          this.setAuth(data.data.user, data.data.token); 
        }),
        catchError((error) => {
          this.purgeAuth();
          return of(null);
        })
      );
    } else {
      this.purgeAuth();
      return of(null);
    }
  }

  public changeSchool(school: School): Observable<any> {
    // console.log("changescholl",school)
    return this.apiService.get(API_TOKEN+"?school_id="+school.id).pipe(
      map((data: any) => {
        this.setAuth(data.data.user, data.data.token, school);
        return data;
      })
    );
  }

  private setAuth(user: User, token = null, school?: School) {
    if(user.role_id == 1){
      return; 
    }
    const tokenDecrypt = this.localStorageService.getDecodedToken(token);
    const schoolDecrypt: School = {
      id: tokenDecrypt.user_info.school_id,
      title: tokenDecrypt.user_info.school_name
    }
    this.localStorageService.setSelectedSchool(school == null ? schoolDecrypt : school);
    this.localStorageService.saveToken(token);
    this.setCurrentUser(user);
    this.isAuthenticatedSubject.next(true);
  }
  public purgeAuth() {
    this.localStorageService.destroyToken();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);

    return this.router.navigate(["/"]);
  }

  public attemptAuth(userInfo: UserAuth): Observable<User> {
    const body = {
      username: userInfo.name,
      password: userInfo.password,
      platform: userInfo.platform,
    };
    return this.http.post(API_URL, body).pipe(
      map((data: any) => {
        this.setAuth(data.data.user, data.data.token);

        return data;
      })
    );
  }

  public getCurrentUser(): User {
    // console.log(this.currentUserSubject.value)
    return this.currentUserSubject.value;
  }

  public setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }
}
