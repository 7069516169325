<div class="drawer-container">
  <div title="Fechar">
    <button mat-button tabindex="-1" class="button-font button-rounded" (click)="closeDrawer()" >
        <mat-icon color="primary" fontIcon="close" class="custom-icon" />
        fechar
    </button>
  </div>

  <main class="discipline-detail-container">
    <h3>Detalhes de disciplina</h3>

    <div class="content-container" *ngIf="content.subject || content.grade">
      <div class="discipline-container">
        <div>
          <span>Disciplina</span>
          <p>{{ content.subject.title }}</p>
        </div>
        <div>
          <span>BNCC</span>
          <p>{{ content.subject.subject_base?.title || "" }}</p>
        </div>
      </div>
      <div class="discipline-container">
        <div>
          <span>Série</span>
          <p>{{ content.grade?.description }}</p>
        </div>
        <div>
          <span>Carga Horária</span>
          <p>{{ getWeeklyHours(content.subject) }}h/semana</p>
        </div>
      </div>
      <div class="p-blue-container">
        <span>Professores</span>
        <p>{{ getTeacherNames(content.subject) }}</p>
      </div>
      <div class="p-blue-container">
        <span>Turmas</span>
        <p>{{ getClassNames(content.subject) }}</p>
      </div>
    </div>
  </main>
</div>
