import { PermissionEnum } from "../enum/permissions.enum";

const permissionIndex = [
  {
    icon: "home",
    label: "Início",
    route: "/privado/main",
    roles: [PermissionEnum.AUTHENTICATION],
  },
  {
    icon: "today",
    label: "Plano Letivo",
    permission: "user_get",
    route: "/privado/planoletivo",
    //TODO - Add real roles here
    roles: [PermissionEnum.REGISTER_STUDENT],
  },
  {
    icon: "move_down",
    label: "Mudar escola",
    permission: "user_get",
    route: "/privado/switch",
    roles: [PermissionEnum.AUTHENTICATION],
  },
  {
    icon: "menu_book",
    label: "Turmas",
    permission: "user_get",
    route: "/privado/turma",
    roles: [
      PermissionEnum.CREATE_CLASS,
      PermissionEnum.UPDATE_CLASS,
      PermissionEnum.ADD_SPECIAL_SUBJECTS,
      PermissionEnum.ACCESS_ATTENDANCE,
      PermissionEnum.ADD_STUDENTS_TO_CLASS,
      PermissionEnum.FILL_CLASS_SCHEDULE,
    ],
  },
  {
    icon: "analytics",
    label: "Disciplina",
    permission: "user_get",
    route: "/privado/disciplina",
    roles: [
      PermissionEnum.CREATE_SUBJECT,
      PermissionEnum.EDIT_SUBJECT,
      PermissionEnum.EDIT_SUBJECT_HOURS,
    ],
  },
  {
    icon: "local_library",
    label: "Professores",
    permission: "user_get",
    route: "/privado/professores",
    roles: [
      PermissionEnum.REGISTER_TEACHER,
      PermissionEnum.EDIT_TEACHER_PROFILE,
      PermissionEnum.GENERATE_TEACHER_PASSWORD,
    ],
  },
  /* {
    icon: "architecture",
    label: "Gestores",
    permission: "user_get",
    route: "/privado/gestores",
    roles: [
      PermissionEnum.REGISTER_MANAGER,
      PermissionEnum.EDIT_MANAGER_PROFILE,
    ],
  }, */
  {
    icon: "school",
    label: "Alunos",
    permission: "user_get",
    route: "/privado/aluno",
    roles: [
      PermissionEnum.REGISTER_STUDENT,
      PermissionEnum.EDIT_STUDENT_PROFILE,
      PermissionEnum.GENERATE_STUDENT_PASSWORD,
    ],
  },
  {
    icon: "badge",
    label: "Cargos e Permissões",
    permission: "user_get",
    route: "/privado/cargosepermissoes",
    roles: [
      PermissionEnum.REGISTER_MANAGER,
      PermissionEnum.EDIT_MANAGER_PROFILE,
    ],
  },
  {
    icon: "menu_book",
    label: "Conteúdo",
    permission: "user_get",
    route: "/privado/conteudo",
    roles: [PermissionEnum.TEACHER_AREA],
  },
  {
    icon: "article",
    label: "Atividades",
    permission: "user_get",
    route: "/privado/atividade",
    roles: [PermissionEnum.TEACHER_AREA],
  },
  {
    icon: "school",
    label: "Minhas Turmas",
    permission: "user_get",
    route: "/privado/livrodeclasse",
    roles: [PermissionEnum.TEACHER_AREA],
  },
  // {
  //   icon: "playlist_add_check",
  //   label: "Exercícios",
  //   permission: "user_get",
  //   route: "/privado/exercicios",
  //   roles: ["Admin"],
  // },

  /* {
    icon: "person",
    label: "Perfil",
    permission: "user_get",
    route: "/privado/account",
    roles: [PermissionEnum.AUTHENTICATION],
  },
  {
    icon: "logout",
    label: "Sair",
    permission: "user_get",
    route: "/privado/sair",
    roles: [PermissionEnum.AUTHENTICATION],
  },*/
];

export default permissionIndex;
