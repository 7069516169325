import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends BaseService<any> {

  override baseUrl: string = `${this.baseUrl}/invitations`;
  public sendInvite(id: any, message?: string): Observable<any> {
    const url = `${this.baseUrl}`;
    let msg = 'Você foi convidado a participar do corpo docente';
    let body = {
      message: msg,
      receiver_id: id
    }
    return this.api.post<any>(url, body, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError("Ocorreu um erro"))
    );
  }
}
