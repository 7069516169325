<div class="wrap-title">
  <h1>Avaliações</h1>
</div>
<p class="sub-title">
  Defina os critérios de avaliação das provas de forma personalizada, adaptando
  a nota de corte, média e nota máxima para cada turma e disciplina.
</p>
<button
  *ngIf="!hasExams"
  mat-raised-button
  color="primary"
  class="button-rounded"
  (click)="navigateToCreateNewModel()"
>
  Configurar avaliação
</button>
<div class="card-container" *ngIf="hasExams">
  <app-mini-card-w-sidenav
    *ngFor="let card of schoolExams; let i = index"
    [title]="card.title"
    [icon]="'list_alt_check'"
    (openSidenav)="openSidenav(card.id, card.title)"
  >
  </app-mini-card-w-sidenav>
  <app-mini-add-card [route]="'create'"></app-mini-add-card>
</div>
