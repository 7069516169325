import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { TimeSlotModel } from "../../models/time-slot.model";
import { FocusMonitor } from "@angular/cdk/a11y";
import { Subject } from "rxjs";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { startTimeBeforeEndTimeValidator } from "../../validators/start-before-endTime";

export class TimeSlot {
  constructor(
    public start_time: string,
    public end_time: string,
    public isEditing?: boolean,
    public isValid?: boolean
  ) {}
}

@Component({
  selector: "app-time-slot-input",
  templateUrl: "./time-slot-input.component.html",
  styleUrls: ["./time-slot-input.component.sass"],
  providers: [
    { provide: MatFormFieldControl, useExisting: TimeSlotInputComponent },
  ],
  host: {
    "[class.example-floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class TimeSlotInputComponent
  implements ControlValueAccessor, MatFormFieldControl<TimeSlot>, OnDestroy
{
  static nextId = 0;
  @Input() isEditing!: boolean;
  @Input() timeSlotContent!: { start_time: string; end_time: string };
  @Output() timeSlotChange = new EventEmitter<{ key: string; value: string }>();
  @ViewChild("area") areaInput!: HTMLInputElement;
  @ViewChild("exchange") exchangeInput!: HTMLInputElement;
  @ViewChild("subscriber") subscriberInput!: HTMLInputElement;
  parts: FormGroup<{
    start_time: FormControl<string | null>;
    end_time: FormControl<string | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = "example-tel-input";
  id = `example-tel-input-${TimeSlotInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = formBuilder.group(
      {
        start_time: [
          { value: "", disabled: !this.isEditing },
          [Validators.required],
        ],
        end_time: [
          { value: "", disabled: !this.isEditing },
          [Validators.required],
        ],
      },
      { validators: startTimeBeforeEndTimeValidator() }
    );
  }

  get empty() {
    const {
      value: { start_time, end_time },
    } = this.parts;

    return !start_time && !end_time;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input("aria-describedby") userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TimeSlot | null {
    if (this.parts.valid) {
      const {
        value: { start_time, end_time },
      } = this.parts;
      return new TimeSlot(start_time!, end_time!);
    }
    return null;
  }
  set value(timeSlot: TimeSlot | null) {
    const { start_time, end_time } = timeSlot || new TimeSlot("", "");
    this.parts.setValue({ start_time, end_time });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["timeSlotContent"] && this.timeSlotContent) {
      this.parts.patchValue(this.timeSlotContent); // Update the form with input changes
    }

    if (changes["isEditing"]) {
      this.toggleFormControls(this.isEditing);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  toggleFormControls(isEditing: boolean) {
    if (isEditing) {
      this.parts.enable();
    } else {
      this.parts.disable();
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }
  //TODO - start to use it again
  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    const value = control.value;
    if (
      !control.errors &&
      value &&
      value.length === 5 &&
      /^\d{2}:\d{2}$/.test(value) &&
      nextElement
    ) {
      this._focusMonitor.focusVia(nextElement, "program");
    }
  }

  autoFocusPrev(
    control: AbstractControl,
    prevElement: HTMLInputElement,
    event: Event
  ): void {
    const value = control.value;
    const keyboardEvent = event as KeyboardEvent;
    const teste = keyboardEvent.target as HTMLInputElement;
    const cursorPosition = (keyboardEvent.target as HTMLInputElement)
      .selectionStart;

    if (cursorPosition === 0 && value.length === 0) {
      this._focusMonitor.focusVia(prevElement, "program");
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".example-tel-input-container"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick() {
    if (this.parts.controls.start_time.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, "program");
    } else if (this.parts.controls.end_time.valid) {
      this._focusMonitor.focusVia(this.exchangeInput, "program");
    } else {
      this._focusMonitor.focusVia(this.areaInput, "program");
    }
  }

  writeValue(tel: TimeSlot | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, key: "start_time" | "end_time"): void {
    if (control.value) {
      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (!timeRegex.test(control.value)) {
        control.setErrors({ invalidTime: true });
      } else {
        control.setErrors(null);
        this.timeSlotChange.emit({ key, value: control.value });
      }
    }
    //this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
    // this.onTimeChange(control.target.value, 'start_time');
  }

  getErrorMessage(order: number) {
    if (this.parts.hasError("startTimeAfterEndTime")) {
      return "O horário de início não pode ser depois do fim";
    }
    if (this.parts.controls.start_time.hasError("required") && order === 1) {
      return "Horário de início é necessário!";
    }
    if (this.parts.controls.end_time.hasError("required") && order === 2) {
      return "Horário de término é necessário!";
    }
    return "";
  }

  onTimeChange(value: string, key: "start_time" | "end_time") {
    this.timeSlotChange.emit({ key, value });
  }
}
