import { Component } from "@angular/core";
import { BreadcrumbService } from "xng-breadcrumb";

@Component({
  selector: "app-cargo-e-permissao",
  templateUrl: "./cargo-e-permissao.component.html",
  styleUrls: ["./cargo-e-permissao.component.sass"],
})
export class CargoEPermissaoComponent {
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.set("@read", "Cargos e Permissões");
  }
}
