import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-permission-dialog",
  templateUrl: "./permission-dialog.component.html",
  styleUrls: ["./permission-dialog.component.sass"],
})
export class PermissionDialogComponent {
  permissionTitle: string = "";

  constructor(
    public dialogRef: MatDialogRef<PermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onContinue(): void {
    if (this.data.isInputVisible) {
      this.dialogRef.close(this.permissionTitle);
    } else {
      this.dialogRef.close(true);
    }
  }
}
