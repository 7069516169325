import { ChangeDetectorRef, Component, SimpleChanges } from "@angular/core";
import { BreadcrumbService } from "xng-breadcrumb";
import { TimeSlotModel } from "../../shared/models/time-slot.model";
import { SchoolScheduleService } from "../../../shared/services/school-schedule.service";
import { finalize, scheduled } from "rxjs";

@Component({
  selector: "app-school-schedule",
  templateUrl: "./school-schedule.component.html",
  styleUrls: ["./school-schedule.component.sass"],
})
export class SchoolScheduleComponent {
  hasHorario: boolean = false;
  creationInProgress!: boolean;
  editingInProgress!: boolean;
  timeSlotSchoolItems: TimeSlotModel[] = [];
  timeSlotCreate: TimeSlotModel = {
    title: "title",
    schedule_time_slots: [{ start_time: "00:00", end_time: "00:00" }],
  };
  isSaved: boolean = false;
  isLoading: boolean = false;
  constructor(
    private breacrumbService: BreadcrumbService,
    private schoolScheduleService: SchoolScheduleService,
    private cdr: ChangeDetectorRef
  ) {
    this.breacrumbService.set("@schedule", "Grade de aulas");
  }

  ngOnInit(): void {
    this.creationInProgress = false;
    this.editingInProgress = false;
    this.fetchItems();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  fetchItems(): void {
    this.isLoading = true;
    this.schoolScheduleService
      .getAllWoutPagination()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.hasHorario = true;
            this.timeSlotSchoolItems = response.data.map(
              (item: TimeSlotModel) => {
                return {
                  id: item.id,
                  title: item.title,
                  schedule_time_slots: item.schedule_time_slots,
                };
              }
            );
          }
        },
        error: (err) => {
          //console.error("Error fetching items:", err);
        },
        complete: () => {},
      });
  }

  createSchoolSchedule() {
    if (this.creationInProgress) {
      return;
    }
    this.timeSlotCreate.title = "";
    this.timeSlotCreate.schedule_time_slots = [
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
    ];

    this.creationInProgress = true;
    const newTimeSlot = {
      ...this.timeSlotCreate, // Create a new object based on `timeSlotCreate`
      title: `Horário ${this.timeSlotSchoolItems.length + 1}`, // Default title
    };

    if (!this.timeSlotSchoolItems) {
      this.timeSlotSchoolItems = [];
    }
    this.timeSlotSchoolItems.push(newTimeSlot);
    this.hasHorario = true;
  }

  editingSchoolSchedule(value: boolean) {
    this.editingInProgress = value;
  }

  creatingSchoolSchedule(value: boolean) {
    this.creationInProgress = value;
  }

  createDuplicate(timeSlotDuplicated: any) {
    const baseName = timeSlotDuplicated.title.replace(/\(\d+\)$/, "").trim();

    const existingDuplicates = this.timeSlotSchoolItems.filter((slot: any) =>
      slot.title.startsWith(baseName)
    );

    const nextNumber = existingDuplicates.length + 1;

    const duplicatedTimeSlot = {
      ...timeSlotDuplicated,
      title: `${baseName} (${nextNumber})`,
    };

    this.schoolScheduleService.save(duplicatedTimeSlot).subscribe({
      next: (response) => {
        if (response.data) {
          this.timeSlotSchoolItems.push(duplicatedTimeSlot);
        }
      },
      error: (err) => {},
      complete: () => {},
    });
  }

  saveTimeSchedule(timeSlot2Create: TimeSlotModel, index: number) {
    //console.log(timeSlot2Create);
    this.timeSlotSchoolItems[index] = timeSlot2Create;
    if (timeSlot2Create.id) {
      this.schoolScheduleService
        .update(timeSlot2Create.id, timeSlot2Create)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.cdr.detectChanges();
            }
          },
          error: (err) => {
            //console.log("error in updating new timeSchedule", err);
          },
          complete: () => {},
        });
    } else {
      this.schoolScheduleService.save(timeSlot2Create).subscribe({
        next: (response) => {
          if (response.data) {
            this.timeSlotSchoolItems[index] = response.data;
            this.cdr.detectChanges();
          }
        },
        error: (err) => {
          //console.log("error in creating new timeSchedule", err);
        },
        complete: () => {},
      });
    }
  }

  deleteSchedule(index: any) {
    if (index >= 0 && index < this.timeSlotSchoolItems.length) {
      const timeSlot2Delete = this.timeSlotSchoolItems[index];
      if (timeSlot2Delete.id) {
        this.schoolScheduleService.delete(timeSlot2Delete.id).subscribe({
          next: (response) => {
            // console.log(response.data)
          },
          error: (err) => {},
          complete: () => {
            this.timeSlotSchoolItems.splice(index, 1);
          },
        });
      } else {
        this.timeSlotSchoolItems.splice(index, 1);
      }
    } else {
      //console.warn("Invalid index for deletion");
    }
  }
}
