import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutbaseComponent } from "src/app/modulos/private/layoutbase/layoutbase.component";
import { LayoutBasePublicComponent } from "src/app/modulos/public/layout-base-public/layout-base-public.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { PermissionEnum } from "./core/enum/permissions.enum";

const routes: Routes = [
  { path: "", component: LayoutBasePublicComponent},
  {
    path: "privado",
    component: LayoutbaseComponent,
    loadChildren: () =>
      import("./modulos/private/private.module").then((m) => m.PrivateModule),
    data: { breadcrumb: { skip: true }, roles: [PermissionEnum.AUTHENTICATION] },
    canActivate: [AuthGuard]
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
