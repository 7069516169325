<div class="wrap-title">
    <h1>
        Professores
    </h1>
</div>
<p class="sub-title">
    Faça o cadastro de novos professores, edite perfis e faça todo o controle dos professores.
</p>

<div class="container">
    <mat-card>
        <mat-card-content>

            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Professores">

                    <p class="card-sub-title">
                        Visualize lista completa de professores na escola e tenha
                        acesso às
                        informações de cada um.
                    </p>

                    <app-alert-usage *ngIf="schoolWoutTeacher" [message]="schoolWoutTeacherTxt"></app-alert-usage>
                    <app-alert-usage *ngIf="hasTeacherWoutAccess" [message]="hasTeacherWoutAccessTxt"></app-alert-usage>
                    <app-alert-usage *ngIf="hasTeacherWoutClass" [message]="hasTeacherWoutClassTxt" [link]="linkTurma"
                        [splitTxt]="splitTeacher"></app-alert-usage>

                    <div class="row-search-bar">
                        <div class="div-search">
                            <form [formGroup]="searchForm" class="form-search" (ngSubmit)="submitSearch()">
                                <mat-form-field appearance="fill">
                                    <mat-label>Buscar Professor</mat-label>
                                    <input matInput formControlName="search" placeholder="Buscar">
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                                <button mat-raised-button color="primary" type="submit" class="button-rounded">
                                    Pesquisar</button>
                            </form>
                        </div>
                        <!-- <div class="div-btn-acesso">
                            <button mat-raised-button color="primary" (click)="createAcessos()"
                                class="button-rounded">Criar senha
                                de acesso</button>
                        </div> -->
                    </div>

                    <table mat-table [dataSource]="update" class="mat-elevation-z8">

                        <ng-container matColumnDef="name">

                            <th mat-header-cell *matHeaderCellDef> Professor(a) </th>
                            <td mat-cell *matCellDef="let element">
                                <a routerLink="edit/{{element.id}}">
                                    <span *ngIf="element.name != undefined">{{element.name}}</span>
                                </a>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="discipline">
                            <th mat-header-cell *matHeaderCellDef> Especialidade </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.discipline != undefined">{{element.discipline}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef> Categoria </th>
                            <td mat-cell *matCellDef="let element"> <span
                                    *ngIf="element.category != undefined">{{element.category}}</span> </td>
                        </ng-container>

                        <ng-container matColumnDef="turma">
                            <th mat-header-cell *matHeaderCellDef> Turma </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngFor="let element2 of element.turma; let last = last">
                                    <a [routerLink]="'/privado/turma/edit/' + element2.class.id">
                                        {{ element2.class.title }}
                                    </a>
                                    <ng-container *ngIf="!last">,</ng-container>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> <mat-icon class="icon-status" aria-hidden="false"
                                    aria-label="icon" fontIcon="filter_list"></mat-icon><span> Status </span></th>
                            <td mat-cell *matCellDef="let element">
                                <span class="status-span status-ativo-text" *ngIf="element.status">
                                    <span>Ativo</span>
                                </span>
                                <span class="status-span status-inativo-text" *ngIf="!element.status">
                                    <span>Sem acesso</span>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Ações </th>
                            <td mat-cell *matCellDef="let element">
                                <button 
                                    data-test-id='test-btn-delet-teacher' 
                                    mat-icon-button class="button-rounded"
                                    color="warn" 
                                    title="Deletar professor"
                                    (click)="deleteTeacher(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <app-custom-pagination [paginationData]="pagination" (pageEventEmitter)="changeData($event)">
                    </app-custom-pagination>
                </mat-tab>

                <mat-tab label="Cadastrar novo" id="new-student">

                    <div class="content-form">
                        <p class="card-sub-title sub-title-student">
                            Preencha os dados abaixo para cadastrar um novo professor
                        </p>
                        <form [formGroup]="teacherForm" class="form-student">
                            <div class="div-form">
                                <div class="col-form">
                                    <h2>Pessoal</h2>
                                </div>
                                <div class="col-form">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome Completo</mat-label>
                                        <input required matInput maxlength="80" type="text" formControlName="full_name"
                                            placeholder="Nome Completo">
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.full_name.valid && submited">
                                            Nome é obrigatório.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>CPF</mat-label>
                                        <input maxlength="14" required matInput type="text" formControlName="cpf"
                                            placeholder="CPF" mask="000.000.000-00" (change)="verifyCpf()">
                                        <span class="input-invalid" *ngIf="!teacherForm.controls.cpf.valid && submited">
                                            CPF é obrigatório.
                                        </span>
                                        <span class="input-invalid" *ngIf="teacherForm.controls.cpf.errors?.invalidCpf">
                                            CPF inválido.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Data de Nascimento</mat-label>
                                        <input required matInput [matDatepicker]="picker" formControlName="birthday"
                                            placeholder="Data de Nascimento" [max]="today" [readonly]="true">
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <!-- <mat-icon matSuffix>today</mat-icon> -->
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.birthday.valid && submited">
                                            Data de nascimento é obrigatório.
                                        </span>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="div-form">
                                <div class="col-form">
                                    <h2>Contato</h2>
                                </div>
                                <div class="col-form">
                                    <mat-form-field appearance="outline">
                                        <mat-label>E-mail</mat-label>
                                        <input required matInput maxlength="80" type="email"
                                            formControlName="teacher_email" placeholder="E-mail do professor">
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.teacher_email.valid && submited">
                                            E-mail é obrigatório.
                                        </span>

                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Celular</mat-label>
                                        <input maxlength="15" required matInput type="text"
                                            mask="(00) 0000-0000||(00) 00000-0000" formControlName="teacher_cellphone"
                                            placeholder="Celular do professor">
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.teacher_cellphone.valid && submited">
                                            Celular é obrigatório.
                                        </span>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div class="div-form">
                                <div class="col-form">
                                    <h2>Profissional</h2>
                                </div>
                                <div class="col-form">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Categoria</mat-label>
                                        <select matNativeControl formControlName="category" title="categoria" required>
                                            <option selected disabled value="">Categoria</option>
                                            <option value="Permanente">Permanente</option>
                                            <option value="Substituto">Substituto</option>
                                        </select>
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.category.valid && submited">
                                            Categoria é obrigatório.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Escolaridade</mat-label>
                                        <select matNativeControl formControlName="education" title="escolaridade"
                                            required>
                                            <option selected disabled value="">Escolaridade</option>
                                            <option value="Ensino Superior Completo">Ensino Superior Completo</option>
                                            <option value="Especialização Incompleta">Especialização Incompleta</option>
                                            <option value="Especialização Completa">Especialização Completa</option>
                                            <option value="Mestrado Incompleto">Mestrado Incompleto</option>
                                            <option value="Mestrado Completo">Mestrado Completo</option>
                                            <option value="Doutorado Incompleto">Doutorado Incompleto</option>
                                            <option value="Doutorado Completo">Doutorado Completo</option>
                                            <option value="Pós-Doutorado">Pós-Doutorado</option>
                                        </select>
                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.education.valid && submited">
                                            Escolaridade é obrigatório.
                                        </span>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Especialidade</mat-label>

                                        <mat-select title="disciplina" matNativeControl formControlName="discipline"
                                            multiple required>
                                            <mat-option *ngFor="let especialista of specialtiesList"
                                                [value]="especialista">{{especialista}}</mat-option>
                                        </mat-select>

                                        <span class="input-invalid"
                                            *ngIf="!teacherForm.controls.discipline.valid && submited">
                                            Especialidade é obrigatório.
                                        </span>
                                    </mat-form-field>

                                    <div class="slide-row">
                                        <div class="slide-text">
                                            <p class="slide-title">Tornar professor coordenador</p>
                                            <span class="slide-sub-text">Professores coordenadores são responsáveis pela
                                                disciplina</span>
                                        </div>

                                        <div class="slide-slide">
                                            <mat-slide-toggle formControlName="slide_coordination"
                                                (change)="slideCoordination($event)" color="primary"></mat-slide-toggle>
                                        </div>
                                    </div>


                                    <mat-form-field *ngIf="coordination_control" appearance="outline">
                                        <mat-label>Disciplina BNCC</mat-label>
                                        <select matNativeControl formControlName="discipline_bncc"
                                            title="disciplina bncc">
                                            <option selected disabled value="">Disciplina</option>
                                            <option *ngFor="let element of disciplineBncc" value="{{element.id}}">
                                                {{element.title}}</option>
                                        </select>
                                    </mat-form-field>



                                </div>
                            </div>



                        </form>
                        <div class="row row-btns">
                            <button mat-raised-button color="secundary" type="submit" class="button-rounded"
                                (click)="cancelar()">
                                Cancelar</button>

                            <button mat-raised-button color="primary" type="submit" (click)="submitUser()"
                                class="button-rounded add-student" [disabled]="isLoading">
                                <span *ngIf="isLoading">
                                    <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
                                </span>
                                <span *ngIf="!isLoading">
                                    Cadastrar
                                </span>
                            </button>
                        </div>
                    </div>


                </mat-tab>

            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>