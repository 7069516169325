import { MatDialog } from "@angular/material/dialog";
import { CanDeactivateFn } from "@angular/router";
import { ConfirmDialogComponent } from "../component/confirm-dialog/confirm-dialog.component";
import { map } from "rxjs";
import { inject } from "@angular/core";
import { WarningDialogComponent } from "../component/warning-dialog/warning-dialog.component";

export const unsavedChangesGuardForms: CanDeactivateFn<{
  formIsDirty: () => boolean;
}> = (component, currentRoute, currentState, nextState) => {
  if (component.formIsDirty()) {
    return confirm(
      "You have unsaved changes. Do you really want to leave this page?"
    );
  }
  return true;
};
export interface UnsavedChangesComponent {
  isScheduleEditable: boolean;
}

export const unsavedChangesGuard: CanDeactivateFn<{ isScheduleEditable: boolean, scheduleModelId:any}> = (
  component,
  _currentRoute,
  _currentState,
  _nextState
) => {
  if (component.scheduleModelId && component.isScheduleEditable ) {
    const dialog = inject(MatDialog);
    return dialog
      .open(WarningDialogComponent, {
        data: {
          text: "Salve a Grade Interativa e escolha qual grade ficará visível para os estudantes dessa turma no seletor antes de sair.",
        },
        maxWidth: "356px"
      })
      .afterClosed()
      .pipe(map((result) => !!result));
  }
  return true;
};
