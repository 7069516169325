import { Injectable } from "@angular/core";
import { SchoolExams } from "../models/exams-school.model";
import { BaseService } from "src/app/core/services/base.service";
import { ApiResponse } from "src/app/core/models/api-response";
import { catchError, Observable, tap } from "rxjs";
import { PaginationData } from "src/app/core/models/pagination-data";
import { SchoolExamsModel } from "../models/school-exams.model";

@Injectable({
  providedIn: "root",
})
export class ExamSchoolService extends BaseService<SchoolExamsModel> {
  override baseUrl: string = `${this.baseUrl}/school-exams`;

  public getOneBySchoolExamModel(
    id: string
  ): Observable<ApiResponse<PaginationData<SchoolExamsModel>>> {
    const url = `${this.baseUrl}?school_exam_model_id=${id}`;
    return this.api.get<ApiResponse<SchoolExams>>(url).pipe(
      tap((_) => this.log(`fetched Entity school_exam_model_id=${id}`)),
      catchError(this.handleError(`getOne school_exam_model_id=${id}`))
    );
  }
}
