import { Component, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { BreadcrumbService } from "xng-breadcrumb";
import { DrawerService } from "../../../shared/services/drawer.service";
import { ExamSchoolModelService } from "../../../shared/services/exam-school-model.service";
import { ExamSchoolService } from "../../../shared/services/exam-school.service";
import { SchoolExamsModModels } from "../../../shared/models/exams-school-model.model";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-read-aval",
  templateUrl: "./read-aval.component.html",
  styleUrls: ["./read-aval.component.sass"],
})
export class ReadAvalComponent {
  schoolExams!: SchoolExamsModModels[];
  hasExams: boolean = false;
  drawerContent: any;
  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private drawerService: DrawerService,
    private examSchoolModelService: ExamSchoolModelService,
    private examSchoolService: ExamSchoolService
  ) {
    this.breadcrumbService.set("@create", "Avaliações");
  }
  ngOnInit(): void {
    this.examSchoolModelService.getAll().subscribe({
      next: (response) => {
        this.schoolExams = response.data?.data ?? [];
      },
      error: (error) => {},
      complete: () => {
        if (this.schoolExams.length > 0) {
          this.hasExams = true;
        } else {
          this.hasExams = false;
        }
      },
    });
  }
  navigateToCreateNewModel(): void {
    this.router.navigate(["create"], {
      relativeTo: this.route,
    });
  }

  openSidenav(contentId: any, contentTitle: string): void {
    this.examSchoolService.getOneBySchoolExamModel(contentId).subscribe({
      next: (response) => {
        this.drawerContent = {
          title: contentTitle,
          id: contentId,
          content: response.data?.data,
          isText: false,
        };
      },
      error: (error) => {},
      complete: () => {
        this.drawerService.toggleDrawer(this.drawerContent);
      },
    });
  }
}
