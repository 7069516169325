import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SchoolExamsModModels } from "src/app/modulos/private/shared/models/exams-school-model.model";

@Component({
  selector: "app-aval-dialog",
  templateUrl: "./aval-dialog.component.html",
  styleUrls: ["./aval-dialog.component.sass"],
})
export class AvalDialogComponent {
  formData: Partial<SchoolExamsModModels> = {
    title: "",
    passing_score: 0
  };
  constructor(
    public dialogRef: MatDialogRef<AvalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.formData.title = this.data.title
      this.formData.passing_score = this.data.passing_score
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close(this.formData);
  }
}
