<div class="wrap-title">
  <h1>Avaliações</h1>
</div>
<p class="sub-title">
  Configure abaixo os principais requisitos de avaliação da sua escola, ex: Nota
  máxima, peso e nota de corte.
</p>
<div class="avaliacoes">
    <app-aval-card
    *ngFor="let card of examsList; let i = index"
    [isAvaliacao]="card.exam_type"
    [avalCounter]="card.exam_type === 'Avaliação' ? getAvalCounter(i) : null"
    [content]="card"
    (formData)="updateFormData(i, $event)">
  </app-aval-card>
</div>
<button class="button-rounded" mat-raised-button color='primary' (click)="saveAll()">Salvar modelo de avaliação</button>