import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: "app-plano-letivo",
  templateUrl: "./plano-letivo.component.html",
  styleUrls: ["./plano-letivo.component.sass"],
})
export class PlanoLetivoComponent {
  constructor(private breadcrumbService: BreadcrumbService){
    this.breadcrumbService.set("@read", "Plano Letivo");
  };
}
