import { Injectable } from "@angular/core";
import { Observable, catchError, tap } from "rxjs";
import { BaseService } from "src/app/core/services/base.service";

@Injectable({
  providedIn: "root",
})
export class GestorService extends BaseService<any> {
  override baseUrl: string = `${this.baseUrl}/user`;
  public saveGestor(teacher: any, linkUserSchool: number): Observable<any> {
    const url = `${this.baseUrl}?linkUserSchool=${linkUserSchool}`;
    return this.api
      .post<any>(url, teacher, this.httpOptions)
      .pipe(tap(), catchError(this.handleError("Ocorreu um erro")));
  }

  public editGestor(id_user: any, metadata: any): Observable<any> {
    const url = `${this.baseUrl}/${id_user}`;
    return this.api
      .put<any>(url, metadata)
      .pipe(tap(), catchError(this.handleError("Ocorreu um erro")));
  }

  public getAllGestores1(page: number, limit: number): Observable<any> {
    const url = `${this.baseUrl}/?page=${page}&limit=${limit}&role=4&withMetas=true&withUserSubjectClass=true`;
    return this.api
      .get<any>(url)
      .pipe(tap(), catchError(this.handleError("Ocorreu um erro")));
  }

  listManagers(): Observable<any> {
    const url = `${this.baseUrl}/list/managers/?withMetas=true`;
    return this.api.get<any>(url).pipe(
      tap((_) => this.log("Gestores carregados")),
      catchError(this.handleError("Erro ao carregar gestores"))
    );
  }

  getManagerById(managerId: string): Observable<any> {
    const url = `${this.baseUrl}/${managerId}`;
    return this.api.get<any>(url).pipe(
      tap((_) => this.log("Gestores carregados")),
      catchError(this.handleError("Erro ao buscar gestores"))
    );
  }

  public resetPass(professor_id: object): Observable<any> {
    const url = `${this.baseUrl}/reset-passwords`;
    return this.api
      .patch<any>(url, professor_id)
      .pipe(tap(), catchError(this.handleError("Ocorreu um erro")));
  }
}
