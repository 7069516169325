import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: "app-plano-letivo-content",
  templateUrl: "./plano-letivo-content.component.html",
  styleUrls: ["./plano-letivo-content.component.sass"],
})
export class PlanoLetivoContentComponent {
}
