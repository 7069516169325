import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-time-slot-dialog",
  templateUrl: "./time-slot-dialog.component.html",
  styleUrls: ["./time-slot-dialog.component.sass"],
})
export class TimeSlotDialogComponent {
  formData!: string;
  constructor(
    public dialogRef: MatDialogRef<TimeSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.formData = this.data.title;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close(this.formData);
  }
}
