<mat-card>
  <mat-card-title>{{ isAvaliacao === "Avaliação" ? avalCounter+'ª ' : '' }}{{ title }}</mat-card-title>

  <p class="sub-title">Nota máxima</p>
  <div class="number-input">
    <button color="primary" id="remove" mat-fab (click)="decrease()" [disabled]="notaMaxima === 0">
      <mat-icon>remove</mat-icon>
    </button>
    <input matInput type="number" [value]="notaMaxima" readonly />
    <button color="primary" id="add" mat-fab (click)="increase()" [disabled]="notaMaxima === 10">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <p class="sub-title">Peso</p>
  <div class="peso-section">
    <mat-button-toggle-group
      [disabled]="!isPesoEnabled"
      [(ngModel)]="selectedPeso"
      (change)="onPesoChange($event.value)"
      name="peso"
      class="peso-buttons"
    >
      <mat-button-toggle value='2'>2x</mat-button-toggle>
      <mat-button-toggle value='3'>3x</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <p class="sub-title">Tipo</p>
  <div class="tipo-section">
    <mat-radio-group
      color="primary"
      [(ngModel)]="selectedType"
      (change)="onTypeChange($event.value)"
    >
      <mat-radio-button value="Avaliação">Avaliação</mat-radio-button>
      <mat-radio-button value="Recuperação">Recuperação</mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>
