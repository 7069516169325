import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Status } from "src/app/core/enum/status.enum";
import { bodyModels } from "src/app/modulos/private/shared/models/gridModels/body.model";
import { ExamScoresService } from "src/app/modulos/private/shared/services/exam-scores.service";

interface ExamResult {
  score: number;
  student_id: string;
  exam_registry_id: string;
}

interface TableBody {
  id: string;
  exam_score: ExamResult[];
}

interface StudentScore {
  student_id: string;
  class_name: string;
  student_name: string;
  scores: { score: number | null; exam_registry_id: string }[];
}

@Component({
  selector: "app-table-body",
  templateUrl: "./table-body.component.html",
  styleUrls: ["./table-body.component.sass"],
})
export class TableBodyComponent {
  @Input() tableContent!: bodyModels[];
  @Input() currentExams!: any[];
  @Input() maxColSize!: number;
  @Input() colNumbers!: number;
  @Output() openDialogByExam = new EventEmitter<[number, number]>();
  studentTitleColSize = 2;
  numDefaultValue = 0;
  currentExamsValues!: ExamResult[];
  examScoresByExamId!: { id: string; exam_scores: number[] }[];
  examScoresByStudentId!: any;

  addBtnColSize = this.hasHeaderAddBtn() ? 1 : 0;

  hoverStates: boolean[] = [];

  private previousValues: Map<string, number> = new Map();

  constructor(
    private cdr: ChangeDetectorRef,
    private examScoreService: ExamScoresService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  getTotalCols(): number {
    return this.colNumbers + this.studentTitleColSize + this.addBtnColSize;
  }

  ngOnInit(): void {
    this.hoverStates = Array(this.tableContent.length).fill(false);
    this.currentExamsValues = [...this.currentExams];
    this.examScoresByExamId = this.currentExams.map((item) => ({
      id: item.id,
      class_name: item.class.title,
      school_exam_name: item.school_exam.title,
      exam_status: item.status,
      exam_scores: item.exam_scores.length > 0 ? item.exam_scores : null, // Initialize with 0 if empty
    }));
    this.examScoresByStudentId = this.extractStudentScores(
      this.examScoresByExamId
    );
    this.examScoresByStudentId.forEach((student: any) => {
      student.scores.forEach((exam: any) => {
        const key = `${student.student_id}-${exam.exam_registry_id}`;
        this.previousValues.set(key, exam.score);
      });
    });
  }

  hasHeaderAddBtn() {
    return (
      true ||
      this.currentExams.length < this.maxColSize ||
      this.currentExams.every((item: any) => item.status === Status.publish2All)
    );
  }

  validateValue(value: any, studentId: string, exam_registry_id: string) {
    const temp = this.currentExams.find((exam) => exam.id == exam_registry_id);
    const MIN = 0;
    const MAX = temp?.school_exam.max_score;

    if (value <= MIN) {
      value = MIN;
    } else if (value >= MAX) {
      value = MAX;
    }

    const findStudentExam = this.findStudentExam(studentId, exam_registry_id);
    if (findStudentExam) {
      findStudentExam.score = value;
      this.cdr.detectChanges();
    }
  }

  findStudentExam(studentId: string, examRegistryId: string) {
    const student = this.examScoresByStudentId.find(
      (student: any) => student.student_id == studentId
    );
    if (student) {
      return student.scores.find(
        (exam: any) => exam.exam_registry_id == examRegistryId
      );
    }
    return null;
  }

  saveChange(studentInfo: any, examScore: any) {
    //console.log("Saving score:", studentInfo, examScore);
    const key = `${studentInfo.student_id}-${examScore.exam_registry_id}`;
    const previousValue = this.previousValues.get(key);
    if (previousValue != examScore.score) {
      // console.log(`Value changed from ${previousValue} to ${examScore.score}`);
      this.previousValues.set(key, examScore.score);
      const patchScore = {
        score: examScore.score,
        student_id: studentInfo.student_id,
        exam_registry_id: examScore.exam_registry_id,
      };
      this.examScoreService
        .update(examScore.exam_score_id, patchScore)
        .subscribe({
          next: (response) => {},
          error: (error) => {},
          complete: () => {},
        });
    }
  }

  getBadgeSize(messageCount: number): "small" | "medium" | "large" {
    if (messageCount > 10) {
      return "medium";
    } else {
      return "small";
    }
  }

  getBadgeContent(messageCount: number): string {
    return messageCount > 0 ? messageCount.toString() : "\u00A0"; // Non-breaking space
  }

  hasBadgeContent(messageCount: number): boolean {
    return messageCount > 0 ? true : false;
  }

  getStudentScore(exam: any, studentId: number | undefined) {
    const scoreObj = exam.exam_scores.find(
      (score: any) => score.student_id === studentId
    );
    return scoreObj ? scoreObj.score : null;
  }

  navigateToExam(exam: any, row: any) {
    const routeData = {
      exam_id: exam.exam_score_id,
      av: exam.school_exam_name,
      nota: exam.score,
      chat_id: exam.chat_id,
      exam_status: exam.exam_status,
      score: exam.score,
      class_name: row.class_name,
    };
    this.router.navigate(["comments", exam.exam_score_id], {
      state: { data: routeData },
      relativeTo: this.route,
    });
  }

  emitOpenDialog(exam: any) {
    this.openDialogByExam.emit([exam.exam_registry_id, exam.exam_score_id]);
  }

  extractStudentScores(data: any[]): StudentScore[] {
    const studentScoresMap: { [studentId: string]: StudentScore } = {};

    data.forEach((item) => {
      const exam_registry_id = item.id;
      item.exam_scores?.forEach((score: any) => {
        const student_id = score.student_id;
        const student_name = score.student.name;
        const class_name = item.class_name;
        const newScore = {
          exam_score_id: score.id,
          exam_status: item.exam_status,
          school_exam_name: item.school_exam_name,
          score: score.score,
          exam_registry_id: exam_registry_id,
          messageCount: score.chat?.messageCount ?? 0,
          chat_id: score.chat_id ?? null,
        };

        if (!studentScoresMap[student_id]) {
          studentScoresMap[student_id] = {
            student_id: student_id,
            student_name: student_name,
            class_name: class_name,
            scores: [newScore],
          };
        } else {
          studentScoresMap[student_id].scores.push(newScore);
        }
      });
    });
    const studentScores: StudentScore[] = Object.values(studentScoresMap);
    studentScores.sort((a, b) => a.student_name.localeCompare(b.student_name));
    return studentScores;
  }

  getExamStatus(exam: Status){
    if(exam == Status.saved){
      return true
    } else if (exam == Status.publish2Students){
      return true
    } else if (exam == Status.publish2All){
      return false
    } else {
      return false
    }
  }

  getExamStatusWMsg(exam: Status, isChatBubble: number) {

    if (exam == Status.saved) {
      return true;
    } else if (isChatBubble > 0 && exam == Status.publish2Students) {
      return false;
    } else if (isChatBubble == 0 && exam == Status.publish2Students) {
      return true;
    } else if (exam == Status.publish2All) {
      return false;
    } else {
      return false;
    }
  }

  onMouseEnter(rowIndex: number) {
    this.hoverStates[rowIndex] = true;
  }

  onMouseLeave(rowIndex: number) {
    this.hoverStates[rowIndex] = false;
  }
}
