<div class="wrap-title">
  <h1>Controle de disciplinas</h1>
  <button
    mat-raised-button
    color="primary"
    type="submit"
    class="button-rounded"
    routerLink="create"
  >
    Criar Disciplina
  </button>
</div>
<p class="sub-title">
  Crie disciplinas para cada serie da sua escola para vincular professores a
  Turmas.
</p>

<div class="container">
  <mat-card>
    <mat-card-content>
      <!-- <ng-container *ngIf="(asyncTabs | async) === null">
            Carregando Disciplinas...
          </ng-container> -->
      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        (selectedTabChange)="onTabChanged($event)"
      >
        <mat-tab
          *ngFor="let tab of gradesBySchool; index as i"
          (click)="applyGradeFilter(tab.id!)"
        >
          <h3 class="card-sub-title mt-20">
            Acesse a lista completa de disciplinas do {{ tab.description }}
          </h3>
          <div class="row-search-bar">
            <div class="div-search">
              <form
                [formGroup]="searchForm"
                class="form-search"
                (ngSubmit)="applyFilter($event)"
              >
                <mat-form-field appearance="fill">
                  <mat-label>Buscar disciplina</mat-label>
                  <input
                    matInput
                    formControlName="search"
                    placeholder="Buscar"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  class="button-rounded"
                >
                  Pesquisar
                </button>
              </form>
            </div>
            <!-- <div class="div-btn-acesso">
                    <button mat-raised-button color="primary" (click)="createAcessos()"
                        class="button-rounded">Criar senha
                        de acesso</button>
                </div> -->
          </div>
          <ng-template mat-tab-label>{{ tab.title }}</ng-template>
          <div *ngIf="isTableVisible">
            <app-loading-spinner
              [isLoading]="isTableVisible"
              [containerHeight]="'50vh'"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!isTableVisible">
            <table
              mat-table
              [dataSource]="dataSource"
              multiTemplateDataRows
              class="mat-elevation-z1"
              matSort
            >
              <ng-container
                matColumnDef="{{ column }}"
                *ngFor="
                  let column of columnsToDisplay;
                  let i = index;
                  trackBy: PerformanceUtils.trackByIndex
                "
              >
                <th mat-header-cell *matHeaderCellDef>
                  {{ columnsToDisplayName[i] }}
                </th>

                <td
                  (click)="toggleDrawer(element, element.grade_id)"
                  mat-cell
                  *matCellDef="let element"
                >
                  <span *ngIf="column != 'teacher' && column != 'class'">
                    {{ element[column] }}
                  </span>

                  <span *ngIf="column == 'weeklyHours'">
                    {{ getWeeklyHours(element) }}
                  </span>

                  <span *ngIf="column == 'teacher'">
                    {{ getTeacherNames(element) }}
                  </span>

                  <span *ngIf="column == 'class'">
                    {{ getClassNames(element) }}
                  </span>
                  <span *ngIf="column == 'actions'">
                    <button 
                      mat-icon-button class="button-rounded" 
                      color="warn" 
                      title="Deletar disciplina" 
                      (click)="deleteSubject(element)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </span>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: columnsToDisplay"
                class="example-element-row cursor-pointer-row"
              ></tr>
              <tr class="mat-row no-content" *matNoDataRow>
                <td class="mat-cell" colspan="4">Nenhuma disciplina</td>
              </tr>
            </table>
          </ng-container>
          <!-- Sessão de Disciplinas Especiais -->
          <div>
            <div class="d-flex">
              <h2 class="mt-20 mb-20">Disciplinas Especiais</h2>
            </div>
            <!-- <p *ngIf="dataSourceNotDefault && dataSourceNotDefault.length == 0">
                  Esta turma ainda não possui disciplinas especiais.
                </p> -->
          </div>
          <div *ngIf="isTableVisible">
            <app-loading-spinner
              [isLoading]="isTableVisible"
              [containerHeight]="'50vh'"
            ></app-loading-spinner>
          </div>
          <ng-container *ngIf="!isTableVisible">
            <table
              mat-table
              [dataSource]="dataSourceNotDefault"
              multiTemplateDataRows
              class="mat-elevation-z1"
              matSort
            >
              <ng-container
                matColumnDef="{{ column }}"
                *ngFor="
                  let column of columnsToDisplay;
                  let i = index;
                  trackBy: PerformanceUtils.trackByIndex
                "
              >
                <th mat-header-cell *matHeaderCellDef>
                  {{ columnsToDisplayName[i] }}
                </th>

                <td mat-cell *matCellDef="let element">
                  <span *ngIf="column != 'teacher' && column != 'class'">
                    {{ element[column] }}
                  </span>

                  <span *ngIf="column == 'weeklyHours'">
                    {{ getWeeklyHours(element) }}
                  </span>

                  <span *ngIf="column == 'teacher'">
                    {{ getTeacherNames(element) }}
                  </span>

                  <span *ngIf="column == 'class'">
                    {{ getClassNames(element) }}
                  </span>
                  <span *ngIf="column == 'actions'">
                    <button
                      mat-icon-button
                      class="button-rounded"
                      (click)="deleteSubject(element)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </span>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td
                  mat-cell
                  *matCellDef="let element"
                  [attr.colspan]="columnsToDisplay.length"
                >
                  <div
                    class="example-element-detail"
                    [@detailExpand]="
                      element == expandedElement ? 'expanded' : 'collapsed'
                    "
                  >
                    <div class="disciplina-list">
                      <div class="row">
                        <h3>Disciplina</h3>
                        <p>{{ element.title }}</p>
                      </div>
                      <div class="row">
                        <h3>Carga Horária</h3>
                        <p>{{ getWeeklyHours(element) }}</p>
                      </div>
                      <div class="row">
                        <h3>BNCC</h3>
                        <p>{{ element.subject_base.title }}</p>
                      </div>
                      <div class="row">
                        <h3>Turma</h3>
                        <p>{{ getClassNames(element) }}</p>
                      </div>
                      <div class="row">
                        <h3>Professor</h3>
                        <p>{{ getTeacherNames(element) }}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: columnsToDisplay"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="
                  expandedElement = expandedElement === element ? null : element
                "
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="example-detail-row"
              ></tr>
              <tr class="mat-row no-content" *matNoDataRow>
                <td class="mat-cell" colspan="4">
                  Nenhuma disciplina Especial
                </td>
              </tr>
            </table>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
