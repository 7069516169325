<div class="wrap-title">
  <h1>Cargos e permissões</h1>
</div>
<p class="sub-title">
  Defina os cargos e as permissões que cada perfil de cargo deve possuir.
</p>

<div class="container">
  <mat-card>
    <mat-card-content>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Colaborador">
          <div class="container-button-colaborator">
            
            <form [formGroup]="searchForm" class="form-search" (ngSubmit)="submitSearch()">
              <mat-form-field appearance="fill" class="search-container">
                  <mat-label>Buscar gestor</mat-label>
                  <input matInput formControlName="searchControl" placeholder="Buscar">
                  <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              <button 
                mat-raised-button 
                color="primary" 
                type="submit" 
                class="button-rounded button-seacrh"
                >
                  Pesquisar
              </button>
            </form>
            
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="button-rounded"
              title="Adicionar um colaborador"
              (click)="changeTab(1)"
            >
              <mat-icon fontIcon="add" />
              Colaborador
            </button>
          </div>

          <table 
            mat-table [dataSource]="dataSourceManagers" 
            class="mat-elevation-z8" 
            class="custom-table"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class ="custom-header-cell"> Nome </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{element.name}}</td>
            </ng-container>
        
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Cargo </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{ translateRoleName(element.role_name) }}</td>
            </ng-container>
        
            <ng-container matColumnDef="function">
              <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Função </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{ getFunctionValue(element.metadata) }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="custom-header-cell column-right"></th>
              <td mat-cell *matCellDef="let element" class="custom-cell column-right">
                <button 
                  data-test-id='test-btn-delet-teacher' 
                  mat-icon-button 
                  class="button-rounded"
                  color="primary"
                  title="Editar colaborador"
                  (click)="redirectToEditManager(element.id)"
                >
                  <mat-icon fontIcon="edit" />
                </button>
              </td>
            </ng-container>
        
            <tr 
              mat-header-row 
              *matHeaderRowDef="displayedColumnsCollaborator"
            >
            </tr>
            <tr 
              mat-row *matRowDef="let row; columns: displayedColumnsCollaborator;"
            >
            </tr>
          </table>
          
          <app-custom-pagination 
            [paginationData]="pagination" 
            (pageEventEmitter)="changeData($event)"
          >
          </app-custom-pagination>
        </mat-tab>

        <mat-tab label="Cadastrar colaborador">
          <div class="container-registe">
            <p class="card-sub-title">
              Preencha os dados abaixo para cadastrar um novo colaborador
            </p>
            <form [formGroup]="managerForm" class="form-student">
              <div class="div-form">
                <div class="col-form">
                  <h2>Dados do Colaborador</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline" class="form-field-container">
                    <mat-label>CPF</mat-label>
                    <input 
                      maxlength="14" 
                      matInput 
                      type="text" 
                      formControlName="cpf" 
                      placeholder="CPF"
                      mask="000.000.000-00" 
                      required 
                    />
                    <mat-error *ngIf="managerForm.get('cpf')?.hasError('required')">
                      CPF é obrigatório.
                    </mat-error>
                    <mat-error  *ngIf="managerForm.get('cpf')?.hasError('mask')">
                      CPF inválido.
                    </mat-error>
                  </mat-form-field>
                  

                  <mat-form-field appearance="outline">
                    <mat-label>Nome Completo</mat-label>
                    <input 
                      maxlength="80" 
                      matInput 
                      type="text" 
                      formControlName="name"
                      placeholder="Nome Completo"
                      required 
                    />
                    <mat-error *ngIf="managerForm.get('name')?.hasError('required')">
                      Nome completo é obrigatório.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input 
                      matInput 
                      [matDatepicker]="picker" 
                      formControlName="birthDate"
                      placeholder="Data de Nascimento"
                      required 
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-error *ngIf="managerForm.get('birthDate')?.hasError('required')">
                      Data de nascimento é obrigatória.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Contato</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input 
                      matInput 
                      type="email" 
                      formControlName="email"
                      placeholder="E-mail do estudante"
                      required 
                    />
                    <mat-error *ngIf="managerForm.get('email')?.hasError('required')">
                      E-mail é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="managerForm.get('email')?.hasError('email')">
                      E-mail inválido.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Celular</mat-label>
                    <input 
                      maxlength="15" 
                      matInput 
                      formControlName="phone"
                      type="text"
                      mask="(00) 0000-0000||(00) 00000-0000" 
                      placeholder="Celular do colaborador" 
                      required 
                    />
                    <mat-error *ngIf="managerForm.get('phone')?.hasError('required')">
                      Celular é obrigatório.
                    </mat-error>
                    <mat-error *ngIf="managerForm.get('phone')?.hasError('mask')">
                      Número de celular inválido.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Dados</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Formação</mat-label>
                    <mat-select required [(value)]="selectedFormacao" formControlName="formacao">
                      <mat-option *ngFor="let formacao of formacoes" [value]="formacao.value">
                        {{ formacao.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="managerForm.get('formacao')?.hasError('required')">
                      Formação é obrigatório.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Função</mat-label>
                    <mat-select required [(value)]="selectedFunction" formControlName="function">
                      <mat-option *ngFor="let function of functions" [value]="function.value">
                        {{ function.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="managerForm.get('function')?.hasError('required')">
                      Função é obrigatório.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Matrícula</mat-label>
                    <input 
                      matInput 
                      maxlength="80" 
                      formControlName="registration"
                      type="text" 
                      placeholder="Matrícula"
                      required 
                    />
                    <mat-error *ngIf="managerForm.get('registration')?.hasError('required')">
                      Matrícula é obrigatória.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="div-form">
                <div class="col-form">
                  <h2>Cargo</h2>
                </div>
                <div class="col-form">
                  <mat-form-field appearance="outline">
                    <mat-label>Perfil de Permissões</mat-label>
                    <mat-select required [(value)]="selectedPermission" formControlName="permission">
                      <mat-option *ngFor="let role of roles" [value]="role.id">
                        {{ translateRoleName(role.name) }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="managerForm.get('permission')?.hasError('required')">
                      Permissão é obrigatório.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="container-button-forms">
                <button 
                  mat-button 
                  mat-stroked-button 
                  (click)="cancel()"
                  class="button-rounded"
                >
                  Cancelar
                </button>

                <button 
                  mat-raised-button 
                  class="button-rounded" 
                  color="primary" 
                  type="submit"
                  (click)="submitManagers()"
                  [disabled]="isLoading"
                >
                  <span *ngIf="isLoading">
                    <mat-progress-spinner diameter="20" mode="indeterminate" />
                  </span>
                  <span *ngIf="!isLoading">
                    {{ isEditing ? 'Atualizar' : 'Salvar' }}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </mat-tab>

        <mat-tab label="Cargos">
          <div class="container-button-position">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              class="button-rounded"
              title="Adicionar um colaborador"
              (click)="openDialog()"
            >
              <mat-icon fontIcon="add" />
              Novo Cargo
            </button>
          </div>
          
          <div class="scroll-top" #scrollTop (scroll)="syncScroll(scrollTop, scrollContainer)">
            <div class="scroll-content"></div>
          </div>
          <section class="container-table mat-elevation-z8"  #scrollContainer (scroll)="syncScroll(scrollContainer, scrollTop)">
            <table 
              mat-table [dataSource]="dataSourcePositions" 
              class="custom-table"
            >
              <ng-container matColumnDef="permissions" sticky>
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell"> Permissões </th>
                  <td 
                    mat-cell 
                    *matCellDef="let element" 
                    class="custom-cell"
                  > 
                    {{ getPermissionTitle(element.permission) }} 
                  </td>
              </ng-container>
              
              <ng-container 
                *ngFor="let column of displayedColumnsPositions
                .slice(1); let i = index" 
                [matColumnDef]="column"
              >
                <th 
                  mat-header-cell 
                  *matHeaderCellDef 
                  class="custom-header-cell collumn-custom-color"
                  [ngClass]="{'color-gray': column === 'Administrador da Escola' || column === 'Administrador'}"
                  title={{column}}
                > 
                  <div class="container-button-icon-table">
                    {{ reducingColumnCharacters(column, 13) }}
          
                    <button
                      mat-icon-button
                      class="button-custom-delete"
                      title="Deletar cargo"
                      (click)="openDialogDelete(column)"
                      [disabled]="isModifyAllowed(column)"
                    >
                      <mat-icon fontIcon="delete"  />
                    </button>
                  </div>
                </th>
                <td 
                  mat-cell 
                  *matCellDef="let element" 
                  class="custom-cell custom-border-hover custom-cell-checkbox-center"
                  (mouseenter)="highlightRowAndColumn($event, i, column)" 
                  (mouseleave)="removeHighlight($event)"
                >
                  <mat-checkbox 
                    color="primary" 
                    (change)="togglePermission($event, column, element)"
                    [checked]="hasPermission(element, column)"
                    [disabled]="isModifyAllowed(column)"
                  />
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumnsPositions"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsPositions;"></tr>
            </table>
          </section>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

