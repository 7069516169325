import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ActivityService } from '../../../shared/services/activity.service';
import { TurmaService } from '../../../shared/services/turma.service';
import { UserSubjectClassService } from '../../../shared/services/user-subject-class.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { CustomPagination } from '../../../gestao/shared/models/custom-pagination.model';
import { PageEvent } from '@angular/material/paginator';
import { DeleteDialogComponent } from '../../../shared/components/delete-dialog/delete-dialog.component';

export interface AtividadeSample {
  id: number,
  title: string,
  description: string,
  banner_url: string,
  status: string,
  user_id: number,
  subject_id: number,
  class_id: number,
  start_date: any,
  end_date: any,
  created_at: any,
  updated_at: any
  class: any
  sub: any
}


@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.sass']
})


export class ManageComponent {


  updateSaves: AtividadeSample[] = [];
  dataSourceSaves: AtividadeSample[] = [];

  updatePub: AtividadeSample[] = [];
  dataSourcePub: AtividadeSample[] = [];

  loading = true;

  published: any;


  displayedColumns: string[] = ['activity', 'series', 'deadline', 'turmas', 'more-options'];
  displayedColumns2: string[] = ['activity', 'series', 'more-options'];

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  paginationPub: CustomPagination = {
    length: 0,
    pageSize: 10,
  };
  paginationSaves: CustomPagination = {
    length: 0,
    pageSize: 10,
  };

  changeData(event: PageEvent, pub: boolean) {
    this.getAllActivity(pub, false, event.pageIndex + 1, event.pageSize);
  }


  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activityService: ActivityService,
    private userSubjectClassService: UserSubjectClassService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    
  }

  currentUser: any

  sub: any

  activitys: any

  ngOnInit(): void {
    this.breadcrumbService.set('@read', 'Atividade');
    this.route.queryParamMap.subscribe((params) => {
      this.published = params.get("published");
    });
    this.currentUser = this.authService.getCurrentUser();
    this.getUserSubjectClassService();
    this.getAllActivity(true, true);
    this.getAllActivity(false, true);
  }

  ngAfterViewInit(): void {
    if (this.published == 'true' || this.published == null) {
      this.changeTab(0);  // Vai para a aba 0
    } else {
      this.changeTab(1);  // Vai para a aba 1
    }
  }

  searchForm = this.formBuilder.group({
    search: '',
  });

  changeTab(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  submitSearch(pub: boolean) {
    if (pub)
      this.searchPub(true, false, 1, 10, this.searchForm.value.search)
    else
      this.searchSaves(false, false, 1, 10, this.searchForm.value.search)
  }

  searchPub(pub = true, init = false, page = 1, limit = 10, value: any) {
    this.activityService.searchActivity(page, limit, pub, value)
      .subscribe({
        next: (data) => {
          if (pub) {
            this.dataSourcePub = data.data.data
            this.dataSourcePub.forEach(item => {
              this.sub.forEach((element: any) => {
                if (item.subject_id == element.subject.id) {
                  item.sub = element.subject
                }
              });
            })
            this.updatePub = this.dataSourcePub;
            if (init) {
              this.paginationPub = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          } else {
            this.dataSourceSaves = data.data.data
            this.updateSaves = data.data.data
            this.dataSourceSaves.forEach(item => {
              this.sub.forEach((element: any) => {
                if (item.subject_id == element.subject.id) {
                  item.sub = element.subject
                }
              });
            })
            this.updateSaves = this.dataSourceSaves;
            if (init) {
              this.paginationSaves = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          }
          this.loading = false;
        },
        error: (error) => {
          // console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
        },
        complete: () => {
          // console.log("fetch infos atividades");
        },
      });
  }

  searchSaves(pub = false, init = false, page = 1, limit = 10, value: any) {
    this.activityService.searchActivity(page, limit, pub, value)
      .subscribe({
        next: (data) => {
          if (pub) {
            this.dataSourcePub = data.data.data
            this.dataSourcePub.forEach(item => {
              this.sub.forEach((element: any) => {
                if (item.subject_id == element.subject.id) {
                  item.sub = element.subject
                }
              });
            })
            this.updatePub = this.dataSourcePub;
            if (init) {
              this.paginationPub = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          } else {
            this.dataSourceSaves = data.data.data
            this.updateSaves = data.data.data
            this.dataSourceSaves.forEach(item => {
              this.sub.forEach((element: any) => {
                if (item.subject_id == element.subject.id) {
                  item.sub = element.subject
                }
              });
            })
            this.updateSaves = this.dataSourceSaves;
            if (init) {
              this.paginationSaves = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          }
          this.loading = false;
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
        },
        complete: () => {
          // console.log("fetch infos atividades");
        },
      });
  }

  getAllActivity(pub = true, init = false, page = 1, limit = 10) {

    this.loading = true;
    this.activityService.getAllActivity(page, limit, pub)
      .subscribe({
        next: (data) => {
          if (pub) {
            this.dataSourcePub = data.data.data
            this.dataSourcePub.forEach(item => {
              if(this.sub){
                this.sub.forEach((element: any) => {
                  if (item.subject_id == element.subject.id) {
                    item.sub = element.subject
                  }
                });
              }
            })
            this.updatePub = this.dataSourcePub;
            if (init) {
              this.paginationPub = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          } else {
            this.dataSourceSaves = data.data.data
            this.updateSaves = data.data.data
            this.dataSourceSaves.forEach(item => {
              this.sub.forEach((element: any) => {
                if (item.subject_id == element.subject.id) {
                  item.sub = element.subject
                }
              });
            })
            this.updateSaves = this.dataSourceSaves;
            if (init) {
              this.paginationSaves = {
                length: data.data?.totalItems ?? 0,
                pageSize: data.data?.limit ?? 0,
              };
            }
          }
          this.loading = false;
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações das atividades: ", error);
        },
        complete: () => {
          // console.log("fetch infos atividades");
        },
      });
  }


  getUserSubjectClassService() {
    this.userSubjectClassService.getUserSubjectClassService(this.currentUser.id)
      .subscribe({
        next: (data: any) => {
          this.sub = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title
            },
            class: {
              id: item.class.id,
              title: item.class.title
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title
            }
          }));
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          // console.log("fetch infos estudante complete");
        },
      });
  }


  despublicar(id: any) {
    let atv: any
    atv = this.updatePub.filter(item => {
      if (item.id == id) {
        return true;
      } else {
        return false;
      }
    })

    atv[0].published = null

    let ativi = {
      "title": atv[0].title,
      "description": atv[0].description,
      "status": atv[0].status,
      "type": atv[0].type,
      "user_id": atv[0].user_id,
      "subject_id": atv[0].subject_id,
      "attachments": atv[0].attachments,
      "class_id": atv[0].class_id,
      "end_date": atv[0].end_date,
      "published": atv[0].published
    }

    this.activityService.editActivity(id, ativi)
      .subscribe({
        next: (data) => {
          this.snackBar.open('Enviado com sucesso', 'Fechar', {
            duration: 3000,
            panelClass: ['mat-snackbar-success'],
          });
          // this.router.navigate(['/privado/atividade']);
          this.getAllActivity(true, true);
          this.getAllActivity(false, true);
        },
        error: (error) => {
          // this.erro();
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          // console.log("fetch infos estudante complete");
        },
      });
  }

  publicar(id: any) {
    let now = new Date()
    let atv: any
    atv = this.updateSaves.filter(item => {
      if (item.id == id) {
        return true;
      } else {
        return false;
      }
    })

    atv[0].published = now

    let ativi = {
      "title": atv[0].title,
      "description": atv[0].description,
      "status": atv[0].status,
      "type": atv[0].type,
      "user_id": atv[0].user_id,
      "subject_id": atv[0].subject_id,
      "attachments": atv[0].attachments,
      "class_id": atv[0].class_id,
      "end_date": atv[0].end_date,
      "published": atv[0].published
    }

    this.activityService.editActivity(id, ativi)
      .subscribe({
        next: (data) => {
          this.snackBar.open('Enviado com sucesso', 'Fechar', {
            duration: 3000,
            panelClass: ['mat-snackbar-success'],
          });
          // this.router.navigate(['/privado/atividade']);
          this.getAllActivity(true, true);
          this.getAllActivity(false, true);
        },
        error: (error) => {
          // this.erro();
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          // console.log("fetch infos estudante complete");
        },
      });
  }


  deleteActivity(id: string, pub: boolean) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: { message: "Você tem certeza que deseja deletar essa atividade?" },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.activityService.delete(id).subscribe({
          next: (data) => {
            this.getAllActivity(pub);
          },
          error: (error) => {
            // console.error(
            //   "Erro no fetch e/ou processamento da informações das atividades: ",
            //   error
            // );
          },
          complete: () => {
            // console.log("fetch infos atividades");
            // console.log(this.update);
          },
        });
      } else {
        // console.log("Deletion canceled.");
      }
    });

  }

  openTurmasDialog(id: number, pub: boolean) {
    let dialogRef = this.dialog.open(TurmasDialog, {
      data: {
        activity_infos: pub ? this.updatePub : this.updateSaves,
        id: id
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllActivity(pub);
    });
  }
}

@Component({
  selector: 'turmas-dialog',
  templateUrl: './turmas-dialog.html',
  styleUrls: ['./turmas-dialog.sass'],
  standalone: true,
  imports: [MatDialogModule, NgIf, SharedModule],
})
export class TurmasDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public activity_infos: any,
    private userSubjectClassService: UserSubjectClassService,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private activityService: ActivityService,
  ) {
    this.currentUser = this.authService.getCurrentUser();
  }

  ngOnInit(): void {
    this.getUserSubjectClassService();
  }

  displayedColumns: string[] = ['select', 'turma'];
  sub: any
  currentUser: any

  activity_selected: any

  not_selected_ids: number[] = [];

  selected_ids: number[] = [];

  all_ids: number[] = [];

  getUserSubjectClassService() {
    // console.log(this.activity_infos)
    this.userSubjectClassService.getUserSubjectClassService(this.currentUser.id)
      .subscribe({
        next: (data: any) => {
          this.sub = data.data.map((item: any) => ({
            subject: {
              id: item.subject.id,
              title: item.subject.title
            },
            class: {
              id: item.class.id,
              title: item.class.title
            },
            grade: {
              id: item.class.grade.id,
              title: item.class.grade.title
            }
          }));
        },
        error: (error) => {
          //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
        },
        complete: () => {
          this.checkSelected()
          this.filterSelected()
          // console.log("fetch infos estudante complete");
        },
      });
  }

  selectAll(checked: boolean): void {
    this.sub.forEach((turma: any) => (turma.isSelected = checked));
    this.updateSelectedIds();
  }

  // Lógica para selecionar/deselecionar um estudante individualmente
  selectTurma(turma: any, checked: boolean): void {
    turma.isSelected = checked;
    this.updateSelectedIds();
  }

  // Atualiza o array selected_ids com os IDs dos estudantes selecionados
  updateSelectedIds(): void {
    this.selected_ids = this.sub
      .filter((turma: any) => turma.isSelected)
      .map((turma: any) => turma.class.id);
    // console.log(this.activity_infos.id);
  }

  checkSelected() {
    this.activity_selected = this.activity_infos.activity_infos.find((item: any) => item.id == this.activity_infos.id)

    this.sub.forEach((turma: any) => {
      if (this.activity_selected.class_id == turma.class.id) {
        turma.isSelected = true
      }
    });
    this.updateSelectedIds();
  }


  filterSelected() {

    this.activity_infos.activity_infos.forEach((item: any) => {
      this.all_ids.push(item.id)
    })

    this.not_selected_ids = this.all_ids.filter(numero => !this.selected_ids.includes(numero));

    // console.log(this.activity_selected)
    // console.log(this.sub)
    // console.log(this.all_ids, this.selected_ids, this.not_selected_ids);

  }

  confirmar() {

    this.selected_ids.forEach((classId: any, index) => {

      if (index > 0) {
        let activity

        activity = {
          "title": this.activity_selected.title,
          "description": this.activity_selected.comand,
          "status": 'Aberta',
          "type": "Atividade",
          "user_id": this.currentUser.id,
          "subject_id": this.activity_selected.discipline,
          "attachments": this.activity_selected.attachments,
          "class_id": classId,
          "start_date": this.activity_selected.start_date,
          "end_date": this.activity_selected.end_date
        }


        this.activityService.postActivity(activity)
          .subscribe({
            next: (data) => {
              this.snackBar.open('Enviado com sucesso', 'Fechar', {
                duration: 3000,
                panelClass: ['mat-snackbar-success'],
              });
              this.router.navigate(['/privado/atividade']);
            },
            error: (error) => {
              // this.erro();
              //console.error("Erro no fetch e/ou processamento da informações do estudante: ", error);
            },
            complete: () => {
              // console.log("fetch infos estudante complete");
            },
          });
      }
    })

  }

}
