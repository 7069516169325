import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: "app-mini-card",
  templateUrl: "./mini-card.component.html",
  styleUrls: ["./mini-card.component.sass"],
})
export class MiniCardComponent {
  @Input() route!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() icon: string = "list_alt_check";

  constructor(private router: Router, private currentRoute: ActivatedRoute) {}

  navigate(): void {
    this.router.navigate([`${this.route}`], {
      relativeTo: this.currentRoute,
    });
  }
}
