<mat-card class="clickable-card" (click)="navigate()">
  <mat-card-header>
    <div class="icon-circle">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-card-title>{{ title }}</mat-card-title>
    <!-- <p>{{ description }}</p> -->
  </mat-card-content>
</mat-card>
