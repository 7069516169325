import { Component, ViewChild, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { FormBuilder, Validators } from "@angular/forms";
import { StudentService } from "../../../shared/services/student.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule } from "@angular/material/table";
import { CustomPagination } from "../../shared/models/custom-pagination.model";
import { PageEvent } from "@angular/material/paginator";
import cpfValidator from "../../shared/validators/cpf-validator";
import { User } from "src/app/core/models/user.model";
import { DeleteDialogComponent } from "../../../shared/components/delete-dialog/delete-dialog.component";

import { AlunosSample } from "../../../shared/models/student.model"

// export interface AlunosSample {
//   created_at: any;
//   updated_at: any;
//   id: number;
//   name: string;
//   username: string;
//   turma?: string;
//   status?: boolean;
//   isSelected?: boolean;
//   metadata?: [
//     {
//       name: string;
//       value: string;
//     }
//   ];
//   invalid_data?: string;
// }

export interface ListPass {
  statusCode: number;
  message: string;
  data: [
    {
      id: number;
      password: string;
    }
  ];
}

@Component({
  selector: "app-read-alunos",
  templateUrl: "./read-alunos.component.html",
  styleUrls: ["./read-alunos.component.sass"],
})
export class ReadAlunosComponent {
  tabIndex!: number;
  update: AlunosSample[] = [];
  dataSource: AlunosSample[] = [];
  list_pass?: ListPass;
  list_acess: any;
  displayedColumns: string[] = [
    "select",
    "name",
    "username",
    "turma",
    "status",
    "actions",
  ];
  isLoading = false;

  hasAlunoWoutAccess = false;
  hasAlunoWoutAccessTxt =
    "Você ainda tem Estudantes sem acesso. Lembre-se de gerar Login e Senha para que possam acessar.";

  hasAlunoWError = false;
  hasAlunoWErrorTxt =
    "Há Estudantes solicitando alterações em seus dados de cadastro. Lembre-se de atualizar as informações dos alunos com 'Revisar Dados'.";

  hasAlunoWoutTurma = false;
  hasAlunoWoutTurmaTxt =
    "Você ainda não tem Estudantes vinculados à sua escola. Que tal incluí-los?";

    today: Date = new Date();

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.tabIndex = 0;
    this.getAllStudents();
    this.breadcrumbService.set("@read", "Estudantes");
    //TODO: Implementar a lógica de busca de estudantes
  }

  submited: boolean = false;

  selected_ids: string[] = [];

  searchForm = this.formBuilder.group({
    search: "",
  });

  studentForm = this.formBuilder.group({
    full_name: ["", Validators.required],
    student_email: ["", Validators.required],
    student_cellphone: ["", Validators.required],
    cpf: ["", Validators.required],
    birthday: ["", Validators.required],
    responsible: ["", Validators.required],
    parentage: ["", Validators.required],
    responsible_email: ["", Validators.required],
    responsible_telephone: ["", Validators.required],
    address: ["", Validators.required],
    complement: ["", Validators.required],
    number_address: ["", Validators.required],
    cep: ["", Validators.required],
  });

  pagination: CustomPagination = {
    length: 0,
    pageSize: 20,
  };
  changeData(event: PageEvent) {
    this.getAllStudents(event.pageIndex + 1, event.pageSize);
  }

  submitSearch() {
    this.search(1, 10, this.searchForm.value.search);
  }

  getAllStudents(pageIndex: number = 1, pageSize: number = 20) {
    this.studentService.getAllStudents(pageIndex, pageSize).subscribe({
      next: (data) => {
        this.update = data.data.data;
        this.update.forEach((student) => {
          if (student.created_at != student.updated_at) {
            student.status = true;
          } else {
            this.hasAlunoWoutAccess = true;
          }
          student.metadata?.forEach((meta) => {
            if (meta.name == "invalid_data" && meta.value == "true") {
              student.invalid_data = "true";
              this.hasAlunoWError = true;
            }
          });
        });
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        // console.log("fetch infos estudante complete");
      },
    });
  }

  search(
    pageIndex: number = 1,
    pageSize: number = 20,
    nome: string | null | undefined
  ) {
    this.studentService.searchAluno(pageIndex, pageSize, nome).subscribe({
      next: (data) => {
        this.update = data.data.data;
        this.update.forEach((student) => {
          if (student.created_at != student.updated_at) {
            student.status = true;
          }
          student.metadata?.forEach((meta) => {
            if (meta.name == "invalid_data" && meta.value == "true") {
              student.invalid_data = "true";
              this.hasAlunoWError = true;
            }
          });
        });
        this.dataSource = data.data.data;
        this.pagination = {
          length: data.data?.totalItems ?? 0,
          pageSize: data.data?.limit ?? 0,
        };
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  createAcessos() {
    if (this.selected_ids[0] == null) {
      return;
    }
    this.studentService.resetPass({ user_ids: this.selected_ids }).subscribe({
      next: (data) => {
        this.list_acess = this.concatenarJSONs(data.data, this.update);
        this.sucesso();
        this.openDialog();
      },
      error: (error) => {
        this.erro();
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        // console.log("fetch infos estudante complete");
      },
    });
  }

  concatenarJSONs(array1: any[], array2: any[]) {
    const resultado: any = [];
    // console.log(array1);
    // console.log(array2);
    array1.forEach((obj1) => {
      const usuarioCorrespondente = array2.find(
        (obj2) => obj2.id === obj1.id.toString()
      );

      if (usuarioCorrespondente) {
        const objetoConcatenado = {
          id: obj1.id,
          password: obj1.password,
          name: usuarioCorrespondente.name,
          username: usuarioCorrespondente.username,
        };
        resultado.push(objetoConcatenado);
      }
    });

    return resultado;
  }
  submitUser() {
    this.isLoading = true;
    const matricula = Math.floor(Math.random() * 900000) + 100000;
    if (this.studentForm.valid) {
      const student = {
        name: this.f.full_name.value,
        username: matricula,
        password: "ativedu",
        role_id: 1,
        metadata: [
          { name: "student_email", value: this.f.student_email.value },
          { name: "student_cellphone", value: this.f.student_cellphone.value },
          { name: "cpf", value: this.f.cpf.value },
          { name: "birthday", value: this.f.birthday.value },
          { name: "responsible", value: this.f.responsible.value },
          { name: "parentage", value: this.f.parentage.value },
          { name: "responsible_email", value: this.f.responsible_email.value },
          {
            name: "responsible_telephone",
            value: this.f.responsible_telephone.value,
          },
          { name: "address", value: this.f.address.value },
          { name: "complement", value: this.f.complement.value },
          { name: "number_address", value: this.f.number_address.value },
          { name: "cep", value: this.f.cep.value },
        ],
      };

      this.studentService.save(student).subscribe({
        next: (data) => {
          this.studentForm.reset();
          this.getAllStudents();
          this.sucesso();
          this.mudarTab();
        },
        error: (error) => {
          this.erro();
          // console.error(
          //   "Erro no fetch e/ou processamento da informações do estudante: ",
          //   error
          // );
        },
        complete: () => {
          this.isLoading = false;
          // console.log("fetch infos estudante complete");
        },
      });
    } else {
      this.isLoading = false;
      this.submited = true;
      this.snackBar.open(
        "Operação com problema, preencha os campos obrigatórios",
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
    }
  }

  sucesso(): void {
    this.snackBar.open("Operação concluída com sucesso", "Fechar", {
      duration: 3000,
      panelClass: ["mat-snackbar-success"],
    });
  }
  erro(): void {
    this.snackBar.open(
      "Operação com problema, tente novamente, caso o problema persista entre em contato com o suporte",
      "Fechar",
      {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      }
    );
  }

  mudarTab(): void {
    this.tabGroup.selectedIndex = 0;
  }

  get f() {
    return this.studentForm.controls;
  }

  selectAll(checked: boolean): void {
    this.update.forEach((student) => (student.isSelected = checked));
    this.updateSelectedIds();
  }

  // Lógica para selecionar/deselecionar um estudante individualmente
  selectStudent(student: AlunosSample, checked: boolean): void {
    student.isSelected = checked;
    this.updateSelectedIds();
  }

  // Atualiza o array selected_ids com os IDs dos estudantes selecionados
  updateSelectedIds(): void {
    this.selected_ids = this.update
      .filter((student) => student.isSelected)
      .map((student) => student.id);
    // console.log(this.selected_ids);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverview, {
      data: this.list_acess,
      minWidth: "70%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("The dialog was closed");
      // this.animal = result;
    });
  }
  onCancelClick(): void {
    this.tabGroup.selectedIndex = 0;
  }
  deleteStudent(student: User) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "350px",
      data: { message: "Você tem certeza que deseja deletar esse aluno(a)?" },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.studentService.delete(student.id!).subscribe({
          next: (apiData) => {
            // console.log(`Aluno com id ${student.id} deletedo(a) com sucesso`);
          },
          error: (error) => {
            // console.log(`Error no delete do aluno com id ${student.id}`);
          },
          complete: () => {
            this.getAllStudents();
          },
        });
      }
    });
  }
  onPhoneInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, "");

    if (value.length > 11) {
      value = value.slice(0, 11);
    }

    if (value.length > 2) {
      value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
    }

    if (value.length > 13) {
      value = `${value.slice(0, 10)}-${value.slice(10)}`;
    } else if (value.length > 6) {
      value = `${value.slice(0, 9)}-${value.slice(9)}`;
    }

    input.value = value;
  }
}

export interface ListAcess {
  id: number;
  password: string;
  name: string;
  username: string;
  turma?: string;
  status?: boolean;
  isSelected?: boolean;
  metadata?: [
    {
      name: string;
      value: string;
    }
  ];
}
@Component({
  selector: "dialog-list",
  templateUrl: "./dialog-list.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
  ],
})
export class DialogOverview {
  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  list = this.data;
  dataSource: ListAcess[] = [];
  displayedColumns: string[] = ["name", "username", "password"];

  ngOnInit(): void {
    // console.log(this.list);
  }

  generateCsv() {
    const header = ["id", "Nome", "Login", "Senha"];
    const data = this.list.map(
      (item: { id: any; name: any; username: any; password: any }) => [
        item.id,
        item.name,
        item.username,
        item.password,
      ]
    );

    const csvContent = this.arrayToCSV([header, ...data]);
    const blob = new Blob([csvContent], { type: "text/csv" });

    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "dados.csv";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  arrayToCSV(data: any[][]): string {
    return data.map((row) => row.join(",")).join("\n");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
