import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExamType } from "src/app/core/enum/examType.enum";
import { SchoolExamsModel } from "src/app/modulos/private/shared/models/school-exams.model";

@Component({
  selector: "app-aval-card",
  templateUrl: "./aval-card.component.html",
  styleUrls: ["./aval-card.component.sass"],
})
export class AvalCardComponent {
  @Input() isAvaliacao: ExamType | undefined = ExamType.aval;
  @Input() avalCounter!: number | null;
  @Input() content!: Partial<SchoolExamsModel>;
  @Output() formData: EventEmitter<any> = new EventEmitter();
  title: string = "Avaliação";
  id!: string;
  school_exam_model_id!: string;
  notaMaxima: number = 10;
  isPesoEnabled: boolean = true;
  selectedPeso: number | null = null;
  selectedType: string = "Avaliação";

  ngOnInit(): void {
    if (this.isAvaliacao) {
      this.selectedType = this.isAvaliacao;
    }
    if (this.content) {
      if(this.content.id){
        this.id = this.content?.id || "1";
      }
      this.school_exam_model_id = this.content?.school_exam_model_id || "1";
      this.title = this.content?.title || "Avaliação";
      this.notaMaxima = this.content.max_score || 10;
      this.selectedPeso = this.content.weight || 2;
      this.selectedType = this.content.exam_type || "Avaliação";
      if (this.selectedType === "Recuperação") {
        this.isPesoEnabled = false;
      }
    }
  }

  increase(): void {
    if (this.notaMaxima < 10) {
      this.notaMaxima++;
    }
    this.emitFormData();
  }

  decrease(): void {
    if (this.notaMaxima > 0) {
      this.notaMaxima--;
    }
    this.emitFormData();
  }

  onTypeChange(value: string): void {
    if (value === "Recuperação") {
      this.isPesoEnabled = false;
      this.selectedPeso = null;
      this.title = "Recuperação";
    } else {
      this.isPesoEnabled = true;
      this.title = "Avaliação";
    }
    this.emitFormData();
  }
  onPesoChange(value: number) {
    this.emitFormData();
  }
  emitFormData(): void {
    let formData: Partial<SchoolExamsModel> = {
      school_exam_model_id: this.school_exam_model_id,
      title: this.title,
      max_score: this.notaMaxima,
      weight: Number(this.selectedPeso),
    };
    if(this.selectedType==ExamType.aval){
      formData.exam_type = ExamType.aval
    } else if (this.selectedType==ExamType.rec){
      formData.exam_type = ExamType.rec;
    }
    if(this.id){
      formData.id = this.id
    }
    this.formData.emit(formData);
  }
}
