import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

// Função para validar se o start_time não é maior que o end_time
export function startTimeBeforeEndTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime = control.get("start_time")?.value;
    const endTime = control.get("end_time")?.value;

    if (startTime && endTime) {
      const startTimeDate = convertToDate(startTime);
      const endTimeDate = convertToDate(endTime);

      // Se o start_time for maior que o end_time, retorna um erro
      if (startTimeDate > endTimeDate) {
        return { startTimeAfterEndTime: true };
      }
    }

    return null; // Se a validação for bem-sucedida
  };
}

// Função para converter o valor "HH:mm" para um objeto Date
function convertToDate(time: string): Date {
  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // Define hora e minutos
  return date;
}
