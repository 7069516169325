<mat-card class="clickable-card" (click)="onClick()">
  <mat-card-header>
    <div class="icon-circle">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-card-title>{{ title }}</mat-card-title>
    <!-- <p>{{ description }}</p> -->
  </mat-card-content>
</mat-card>

<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" position="end">
    <p>Sidenav content goes here.</p>
  </mat-sidenav>
</mat-sidenav-container>