export enum PermissionEnum {
  VIEW_LOGS = "view_logs",
  DASHBOARD = "dashboard",
  AUTHENTICATION = "authentication",
  REGISTER_MANAGER = "register_manager",
  EDIT_MANAGER_PROFILE = "edit_manager_profile",
  REGISTER_STUDENT = "register_student",
  EDIT_STUDENT_PROFILE = "edit_student_profile",
  GENERATE_STUDENT_PASSWORD = "generate_student_password",
  REGISTER_TEACHER = "register_teacher",
  EDIT_TEACHER_PROFILE = "edit_teacher_profile",
  GENERATE_TEACHER_PASSWORD = "generate_teacher_password",
  CREATE_CLASS = "create_class",
  UPDATE_CLASS = "update_class",
  ADD_SPECIAL_SUBJECTS = "add_special_subjects",
  ADD_STUDENTS_TO_CLASS = "add_students_to_class",
  FILL_CLASS_SCHEDULE = "fill_class_schedule",
  CREATE_SUBJECT = "create_subject",
  EDIT_SUBJECT = "edit_subject",
  EXPORT_REPORT_CARD = "export_report_card",
  ACCESS_ATTENDANCE = "access_attendance",
  EDIT_SUBJECT_HOURS = "edit_subject_hours",
  EDIT_CLASS_EVALUATION_MODEL = "edit_class_evaluation_model",
  MANAGE_EVALUATIONS = "manage_evaluations",
  TEACHER_AREA = "teacher_area",
  STUDENT_AREA = "student_area",
  MANAGE_PERMISSIONS = "manage_permissions",
  MANAGE_REPORTS = "manage_reports",
  SEND_REPORTS = "send_reports",
  MANAGE_SCHOOLS = "manage_schools",
  MANAGE_BATCH_REGISTRATION = "manage_batch_registration",
  SEND_INVITATIONS = "send_invitations",
}

export const PermissionsDetails = {
  [PermissionEnum.VIEW_LOGS]: {
    title: "Gestão de Logs",
    description: "Permite visualização de logs no sistema",
  },
  [PermissionEnum.AUTHENTICATION]: {
    title: "Autenticação",
    description: "Permite a autenticação no sistema",
  },
  [PermissionEnum.REGISTER_MANAGER]: {
    title: "Cadastrar gestor",
    description: "Permite o cadastro de novos gestores no sistema",
  },
  [PermissionEnum.EDIT_MANAGER_PROFILE]: {
    title: "Editar perfil de gestor",
    description: "Permite a edição do perfil de gestores no sistema",
  },
  [PermissionEnum.REGISTER_STUDENT]: {
    title: "Cadastrar aluno",
    description: "Permite o cadastro de novos alunos no sistema",
  },
  [PermissionEnum.EDIT_STUDENT_PROFILE]: {
    title: "Editar perfil de aluno",
    description: "Permite a edição do perfil de alunos no sistema",
  },
  [PermissionEnum.GENERATE_STUDENT_PASSWORD]: {
    title: "Gerar senha de acesso do aluno",
    description: "Permite a geração de senha de acesso para alunos",
  },
  [PermissionEnum.REGISTER_TEACHER]: {
    title: "Cadastrar professor",
    description: "Permite o cadastro de novos professores no sistema",
  },
  [PermissionEnum.EDIT_TEACHER_PROFILE]: {
    title: "Editar cadastro de professor",
    description: "Permite a edição do cadastro de professores no sistema",
  },
  [PermissionEnum.GENERATE_TEACHER_PASSWORD]: {
    title: "Gerar senha de acesso do professor",
    description: "Permite a geração de senha de acesso para professores",
  },
  [PermissionEnum.CREATE_CLASS]: {
    title: "Criar turma",
    description: "Permite a criação de novas turmas no sistema",
  },
  [PermissionEnum.UPDATE_CLASS]: {
    title: "Atualizar turma",
    description: "Permite a atualização de turmas no sistema",
  },
  [PermissionEnum.ADD_SPECIAL_SUBJECTS]: {
    title: "Adicionar disciplinas especiais à turma",
    description: "Permite adicionar disciplinas especiais a uma turma",
  },
  [PermissionEnum.ADD_STUDENTS_TO_CLASS]: {
    title: "Adicionar e remover alunos da turma",
    description: "Permite a adição e remoção de alunos a uma turma específica",
  },
  [PermissionEnum.FILL_CLASS_SCHEDULE]: {
    title: "Preencher grade da turma",
    description: "Permite o preenchimento da grade curricular de uma turma",
  },
  [PermissionEnum.CREATE_SUBJECT]: {
    title: "Criar disciplina",
    description: "Permite a criação de novas disciplinas no sistema",
  },
  [PermissionEnum.EDIT_SUBJECT]: {
    title: "Editar disciplina",
    description: "Permite a edição das disciplinas existentes no sistema",
  },
  [PermissionEnum.EXPORT_REPORT_CARD]: {
    title: "Exportar boletim",
    description: "Permite a exportação de boletins dos alunos",
  },
  [PermissionEnum.ACCESS_ATTENDANCE]: {
    title: "Acessar frequência",
    description: "Permite o acesso aos registros de frequência dos alunos",
  },
  [PermissionEnum.EDIT_SUBJECT_HOURS]: {
    title: "Editar carga horária de disciplina",
    description: "Permite a edição da carga horária das disciplinas",
  },
  [PermissionEnum.EDIT_CLASS_EVALUATION_MODEL]: {
    title: "Editar modelo de avaliação de uma turma",
    description: "Permite editar o modelo de avaliação de uma turma específica",
  },
  [PermissionEnum.MANAGE_EVALUATIONS]: {
    title: "Gerenciamento das avaliações",
    description: "Permite o gerenciamento completo das avaliações dos alunos",
  },
  [PermissionEnum.TEACHER_AREA]: {
    title: "Acesso as funcionalidades como professor",
    description: "Permite acesso as funcionalidades como professor",
  },
  [PermissionEnum.STUDENT_AREA]: {
    title: "Acesso as funcionalidades como estudante",
    description: "Permite acesso as funcionalidades como estudante",
  },
  [PermissionEnum.DASHBOARD]: {
    title: "Dashboard",
    description: "Permite acesso ao dashboard do sistema",
  },
  [PermissionEnum.MANAGE_PERMISSIONS]: {
    title: "Gestor de permissões e papéis do gestor",
    description: "Permite a edição das permissões e dos papéis dos gestores",
  },
  [PermissionEnum.MANAGE_BATCH_REGISTRATION]: {
    title: "Envio de matrículas em lote",
    description: "Permite o envio de matrículas em lote",
  },
  [PermissionEnum.MANAGE_REPORTS]: {
    title: "Gestor de relatórios",
    description: "Permite a gestão e envio de relatórios",
  },
  [PermissionEnum.SEND_REPORTS]: {
    title: "Enviar relatórios",
    description: "Permite o envio de relatórios",
  },
  [PermissionEnum.MANAGE_SCHOOLS]: {
    title: "Gestão da escola",
    description: "Permite a gestão de escolas",
  },
  [PermissionEnum.SEND_INVITATIONS]: {
    title: "Enviar convites",
    description: "Permite o envio e visualização dos convites da escola",
  },
};
