import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DrawerService } from "../../services/drawer.service";

@Component({
  selector: "app-drawer-text",
  templateUrl: "./drawer-text.component.html",
  styleUrls: ["./drawer-text.component.sass"],
})
export class DrawerTextComponent {
  @Input() content!: {
    subject: any;
    grade: any;
  };
  constructor(private router: Router, private drawerService: DrawerService) {}

  ngOnInit(): void {
  }
  // navigateToEditModel(): void {
  //   this.router.navigate([
  //     "privado",
  //     "planoletivo",
  //     "aval",
  //     "edit",
  //     this.exams.id,
  //   ]);
  //   this.drawerService.closeDrawer();
  // }
  getWeeklyHours(element: { weekly_hours: number }) {
    // console.log(element);
    return element.weekly_hours;
  }
  getTeacherNames(element: { user_subject_class: any[] }) {
    if (
      !element.user_subject_class ||
      element.user_subject_class.length === 0
    ) {
      return "Disciplina sem professor";
    }

    const names = [
      ...new Set(
        element.user_subject_class
          .map((usc) => (usc.user && usc.user.name ? usc.user.name : null))
          .filter((name) => name !== null)
      ),
    ];

    return names.length > 0 ? names.join(", ") : "Disciplina sem professor";
  }

  getClassNames(element: { user_subject_class: any[] }) {
    if (
      !element.user_subject_class ||
      element.user_subject_class.length === 0
    ) {
      return "Disciplina sem turma";
    }

    const titles = [
      ...new Set(
        element.user_subject_class
          .map((usc) => (usc.class && usc.class.title ? usc.class.title : null))
          .filter((title) => title !== null)
      ),
    ];

    return titles.length > 0 ? titles.join("; ") : "Disciplina sem turma";
  }

  closeDrawer():void {
    this.drawerService.closeDrawer();
  }
}
