<div class="wrap-title">
  <h1>Horário</h1>
</div>
<p class="sub-title">
  Crie grades de horários de acordo com uma turma ou turno
</p>
<p *ngIf="!hasHorario" class="sub-title primary">
  Você ainda não tem nenhum modelo de horário
</p>
<button
  mat-raised-button
  color="primary"
  class="button-rounded"
  (click)="createSchoolSchedule()"
  [disabled]="editingInProgress || creationInProgress"
  [ngClass]="{ 'disabled-text-white': editingInProgress || creationInProgress }"
>
  + Configurar novo horário
</button>
<div *ngIf="!isLoading" class="time-slot-container">
  <div *ngFor="let timeSlotSchedule of timeSlotSchoolItems; let i = index">
    <app-schedule-time-slot
      [timeSlots]="timeSlotSchedule"
      (disableUpdateBtn)="editingSchoolSchedule($event)"
      (disableCreateBtn)="creatingSchoolSchedule($event)"
      (duplicateCurrentTimeSlotSchedule)="createDuplicate($event)"
      (openTimeSlotDialog)="saveTimeSchedule($event,i)"
      (deleteCurrentTimeSlotSchedule)="deleteSchedule(i)"
    ></app-schedule-time-slot>
  </div>
</div>
