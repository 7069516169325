<div class="wrap-title">
  <h1>Plano letivo</h1>
</div>
<p class="sub-title">
  Organize e planeje as atividades de ensino e aprendizagem dentro de sua escola.
</p>
<div class="card-container">
  <app-mini-card
    [route]="'aval'"
    [title]="'Modelo de avaliação'"
    [description]="'subtítulo'"
    [icon]="'list_alt_check'"
  >
  </app-mini-card>
  <!-- <app-mini-card
    [route]="'aval'"
    [title]="'Critérios de aprovação'"
    [description]="'subtítulo'"
    [icon]="'school'"
  >
  </app-mini-card> -->
  <app-mini-card
    [route]="'school-schedule'"
    [title]="'Modelo de horário'"
    [description]="'subtítulo'"
    [icon]="'schedule_outline'"
  >
  </app-mini-card>

  <app-mini-card
  [route]="'school-grade-topics'"
  [title]="'Tópicos do plano letivo'"
  [description]="'subtítulo'"
  [icon]="'format_list_bulleted'"
>
</app-mini-card>
</div>
