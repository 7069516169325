import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CreateTurmasComponent } from "./gestao/turmas/create-turmas/create-turmas.component";
import { GradeEscolarComponent } from "./gestao/grade-escolar/grade-escolar.component";
import { TurmaComponent } from "./gestao/turmas/turma/turma.component";
import { EditTurmasComponent } from "./gestao/turmas/edit-turmas/edit-turmas.component";
import { HomeComponent } from "./home/home.component";
import { ReadTurmasComponent } from "./gestao/turmas/read-turmas/read-turmas.component";
import { ReadAlunosComponent } from "./gestao/alunos/read-alunos/read-alunos.component";
import { EditAlunosComponent } from "./gestao/alunos/edit-alunos/edit-alunos.component";
import { PerfilComponent } from "./perfil/perfil.component";
import { DisciplinaComponent } from "./gestao/disciplina/disciplina.component";
import { ReadDisciplinaComponent } from "./gestao/disciplina/read-disciplina/read-disciplina.component";
import { CreateDisciplinaComponent } from "./gestao/disciplina/create-disciplina/create-disciplina.component";
import { EditDisciplinaComponent } from "./gestao/disciplina/edit-disciplina/edit-disciplina.component";
import { ReadProfessoresComponent } from "./gestao/professores/read-professores/read-professores.component";
import { EditProfessoresComponent } from "./gestao/professores/edit-professores/edit-professores.component";
import { AtividadeComponent } from "./content/atividade/atividade/atividade.component";
import { ManageComponent } from "./content/atividade/manage/manage.component";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { CreateComponent } from "./content/atividade/create/create.component";
import { EditAtividadeComponent } from "./content/atividade/edit-atividade/edit-atividade.component";
import { ViewAtividadeComponent } from "./content/atividade/view-atividade/view-atividade.component";
import { ConteudoComponent } from "./content/conteudo/conteudo.component";
import { ReadConteudoComponent } from "./content/conteudo/read-conteudo/read-conteudo.component";
import { CreateConteudoComponent } from "./content/conteudo/create-conteudo/create-conteudo.component";
import { ManageConteudoComponent } from "./content/conteudo/manage-conteudo/manage-conteudo.component";
import { MinhasTurmasComponent } from "./professor/minhas-turmas/minhas-turmas.component";
import { LivroClasseComponent } from "./professor/livro-classe/livro-classe.component";
import { ProfessorBaseComponent } from "./professor/professor-base/professor-base.component";
import { ReportCardComponent } from "./shared/report-card/report-card.component";
import { RequestExamComponent } from "./professor/request-exam/request-exam.component";
import { GestoresComponent } from "./gestao/gestores/gestores/gestores.component";
import { ReadGestoresComponent } from "./gestao/gestores/read-gestores/read-gestores.component";
import { EditGestoresComponent } from "./gestao/gestores/edit-gestores/edit-gestores.component";
import { PermissionEnum } from "../../../app/core/enum/permissions.enum";
import { PlanoLetivoComponent } from "./gestao/planoletivo/plano-letivo/plano-letivo.component";
import { ReadAvalComponent } from "./gestao/planoletivo/read-aval/read-aval.component";
import { PlanoLetivoContentComponent } from "./gestao/planoletivo/plano-letivo-content/plano-letivo-content.component";
import { CreateAvalComponent } from "./gestao/planoletivo/create-aval/create-aval.component";
import { CargoEPermissaoComponent } from "./gestao/cargos-e-permissoes/cargo-e-permissao/cargo-e-permissao.component";
import { ReadCargosEPermissoesComponent } from "./gestao/cargos-e-permissoes/read-cargos-e-permissoes/read-cargos-e-permissoes.component";
import { SchoolScheduleComponent } from './gestao/planoletivo/school-schedule/school-schedule.component';
import { unsavedChangesGuard } from './gestao/shared/guards/unsaved-changes.guard';
import { SchoolGradeTopicsComponent } from './gestao/planoletivo/school-grade-topics/school-grade-topics.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "main",
    data: { breadcrumb: "Início", roles: [PermissionEnum.AUTHENTICATION] },
  },
  {
    path: "main",
    component: HomeComponent,
    data: { breadcrumb: "Início", roles: [PermissionEnum.AUTHENTICATION] },
    canActivate: [AuthGuard],
  },
  {
    path: "report",
    component: ReportCardComponent,
    data: {
      breadcrumb: { alias: "report" },
      roles: [PermissionEnum.EXPORT_REPORT_CARD],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    component: PerfilComponent,
    data: {
      breadcrumb: { alias: "account" },
      roles: [PermissionEnum.AUTHENTICATION],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "turma",
    component: TurmaComponent,
    data: {
      breadcrumb: { alias: "Turmas" },
      roles: [
        PermissionEnum.CREATE_CLASS,
        PermissionEnum.UPDATE_CLASS,
        PermissionEnum.ADD_SPECIAL_SUBJECTS,
        PermissionEnum.ACCESS_ATTENDANCE,
        PermissionEnum.ADD_STUDENTS_TO_CLASS,
        PermissionEnum.FILL_CLASS_SCHEDULE,
      ],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadTurmasComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [
            PermissionEnum.CREATE_CLASS,
            PermissionEnum.UPDATE_CLASS,
            PermissionEnum.ADD_SPECIAL_SUBJECTS,
            PermissionEnum.ACCESS_ATTENDANCE,
            PermissionEnum.ADD_STUDENTS_TO_CLASS,
            PermissionEnum.FILL_CLASS_SCHEDULE,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateTurmasComponent,
        data: {
          breadcrumb: { alias: "create" },
          roles: [PermissionEnum.CREATE_CLASS, PermissionEnum.UPDATE_CLASS],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditTurmasComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [
            PermissionEnum.CREATE_CLASS,
            PermissionEnum.UPDATE_CLASS,
            PermissionEnum.ADD_SPECIAL_SUBJECTS,
            PermissionEnum.ACCESS_ATTENDANCE,
            PermissionEnum.ADD_STUDENTS_TO_CLASS,
            PermissionEnum.FILL_CLASS_SCHEDULE,
          ],
        },
        canActivate: [AuthGuard],
        canDeactivate: [unsavedChangesGuard],
      },
      {
        path: "grade",
        component: GradeEscolarComponent,
        data: {
          breadcrumb: { alias: "grade" },
          roles: [
            PermissionEnum.CREATE_CLASS,
            PermissionEnum.UPDATE_CLASS,
            PermissionEnum.ADD_SPECIAL_SUBJECTS,
            PermissionEnum.ACCESS_ATTENDANCE,
            PermissionEnum.ADD_STUDENTS_TO_CLASS,
            PermissionEnum.FILL_CLASS_SCHEDULE,
          ],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "disciplina",
    component: DisciplinaComponent,
    data: {
      breadcrumb: { alias: "Disciplina" },
      roles: [
        PermissionEnum.CREATE_SUBJECT,
        PermissionEnum.EDIT_SUBJECT,
        PermissionEnum.EDIT_SUBJECT_HOURS,
      ],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadDisciplinaComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [
            PermissionEnum.CREATE_SUBJECT,
            PermissionEnum.EDIT_SUBJECT,
            PermissionEnum.EDIT_SUBJECT_HOURS,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateDisciplinaComponent,
        data: {
          breadcrumb: { alias: "create" },
          roles: [
            PermissionEnum.CREATE_SUBJECT,
            PermissionEnum.EDIT_SUBJECT,
            PermissionEnum.EDIT_SUBJECT_HOURS,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditDisciplinaComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [
            PermissionEnum.EDIT_SUBJECT,
            PermissionEnum.EDIT_SUBJECT_HOURS,
          ],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "aluno",
    component: TurmaComponent,
    data: {
      breadcrumb: { alias: "Estudantes" },
      roles: [
        PermissionEnum.REGISTER_STUDENT,
        PermissionEnum.EDIT_STUDENT_PROFILE,
        PermissionEnum.GENERATE_STUDENT_PASSWORD,
      ],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadAlunosComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [
            PermissionEnum.REGISTER_STUDENT,
            PermissionEnum.EDIT_STUDENT_PROFILE,
            PermissionEnum.GENERATE_STUDENT_PASSWORD,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditAlunosComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [
            PermissionEnum.REGISTER_STUDENT,
            PermissionEnum.EDIT_STUDENT_PROFILE,
            PermissionEnum.GENERATE_STUDENT_PASSWORD,
          ],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "cargosepermissoes",
    component: CargoEPermissaoComponent,
    data: {
      breadcrumb: { alias: "Cargos e Permissões" },
      /* definir role */
      roles: [PermissionEnum.REGISTER_STUDENT],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadCargosEPermissoesComponent,
        data: {
          breadcrumb: { alias: "read" },
          /* definir role */
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditAlunosComponent,
        data: {
          breadcrumb: { alias: "edit" },
          /* definir role */
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "professores",
    component: TurmaComponent,
    data: {
      breadcrumb: { alias: "Professores" },
      roles: [
        PermissionEnum.REGISTER_TEACHER,
        PermissionEnum.EDIT_TEACHER_PROFILE,
        PermissionEnum.GENERATE_TEACHER_PASSWORD,
      ],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadProfessoresComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [
            PermissionEnum.REGISTER_TEACHER,
            PermissionEnum.EDIT_TEACHER_PROFILE,
            PermissionEnum.GENERATE_TEACHER_PASSWORD,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditProfessoresComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [
            PermissionEnum.REGISTER_TEACHER,
            PermissionEnum.EDIT_TEACHER_PROFILE,
            PermissionEnum.GENERATE_TEACHER_PASSWORD,
          ],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "gestores",
    component: GestoresComponent,
    data: {
      breadcrumb: { alias: "Gestor" },
      roles: [
        PermissionEnum.REGISTER_MANAGER,
        PermissionEnum.EDIT_MANAGER_PROFILE,
      ],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ReadGestoresComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [
            PermissionEnum.REGISTER_MANAGER,
            PermissionEnum.EDIT_MANAGER_PROFILE,
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditGestoresComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [
            PermissionEnum.REGISTER_MANAGER,
            PermissionEnum.EDIT_MANAGER_PROFILE,
          ],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "planoletivo",
    component: PlanoLetivoComponent,
    data: {
      breadcrumb: { alias: "read" },
      roles: [PermissionEnum.REGISTER_STUDENT],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: PlanoLetivoContentComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "aval",
        component: ReadAvalComponent,
        data: {
          breadcrumb: { alias: "create" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "aval/create",
        component: CreateAvalComponent,
        data: {
          breadcrumb: { alias: "new" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "aval/edit/:id",
        component: CreateAvalComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "school-schedule",
        component: SchoolScheduleComponent,
        data: {
          breadcrumb: { alias: "schedule" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "school-grade-topics",
        component: SchoolGradeTopicsComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [PermissionEnum.REGISTER_STUDENT],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "atividade",
    component: AtividadeComponent,
    data: {
      breadcrumb: { alias: "Atividades" },
      roles: [PermissionEnum.TEACHER_AREA],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ManageComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: EditAtividadeComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateComponent,
        data: {
          breadcrumb: { alias: "create" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "view/:id",
        component: ViewAtividadeComponent,
        data: {
          breadcrumb: { alias: "view" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: "livrodeclasse",
    component: ProfessorBaseComponent,
    data: {
      breadcrumb: { alias: "MyClass" },
      roles: [PermissionEnum.TEACHER_AREA],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: MinhasTurmasComponent,
      },
      {
        path: "turma/:turmaId/subject/:subjectId",
        component: LivroClasseComponent,
        data: {
          breadcrumb: { alias: "turma" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "turma/:turmaId/subject/:subjectId/comments/:idExam",
        component: RequestExamComponent,
        data: {
          breadcrumb: { alias: "turmaComment" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "conteudo",
    component: ConteudoComponent,
    data: {
      breadcrumb: { alias: "Conteúdo" },
      roles: [PermissionEnum.TEACHER_AREA],
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ManageConteudoComponent,
        data: {
          breadcrumb: { alias: "manage" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "read/:id",
        component: ReadConteudoComponent,
        data: {
          breadcrumb: { alias: "read" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CreateConteudoComponent,
        data: {
          breadcrumb: { alias: "create" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
      {
        path: "edit/:id",
        component: CreateConteudoComponent,
        data: {
          breadcrumb: { alias: "edit" },
          roles: [PermissionEnum.TEACHER_AREA],
        },
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
