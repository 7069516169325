<div mat-dialog-content class="dialog-form">
  <h2>
    Dê um nome para este modelo de horário.
  </h2>
  <mat-form-field appearance="outline">
    <mat-label>Nome do Modelo</mat-label>
    <input
      matInput
      [(ngModel)]="formData"
      maxlength="14"
      type="text"
      placeholder="Modelo Padrão"
      required
    />
    <mat-hint>Ex.: Manhã, tarde ou noite</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    [disabled]="!formData"
    (click)="onYesClick()"
    class="button-rounded"
  >
    Salvar
  </button>
</div>
