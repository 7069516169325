<mat-toolbar>
  <img src="assets/images/logo/logo azul 1.png" class="logo" alt="Logo Alt Text" />
  <div class="display-none-mobile">
    <div *ngIf="isLoggedIn()" class="mt-20 school-selector">
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="onSelect($event)" [(ngModel)]="selectedSchoolId">
          <mat-option *ngFor="let school of schools" [value]="school.id">
            {{ school.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="toolbar-spacer"></span>
    
  </div>
  <div *ngIf="isLoggedIn()">
    <button mat-button [matMenuTriggerFor]="menu" class="button-menu-user">
      <div class="profile-dropdown">
        <p title={{userName}}>{{ getFirstAndLastName(userName) }}</p>
        <div>
          <div class="container-img">
            <img 
              [src]="userAvatar" 
              alt="{{ userName }} Avatar"
            >
          </div>
            <mat-icon class="icon-size">keyboard_arrow_down</mat-icon>
        </div>
      </div>
    </button>
    <mat-menu #menu="matMenu" class="mat-elevation-z2">
      <button mat-menu-item (click)="goToProfile()">
        <mat-icon>person_outline</mat-icon>
        Perfil
      </button>
      <button mat-menu-item>
        <mat-icon>north_east</mat-icon>
        Suporte
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        Sair
      </button>
    </mat-menu>
  </div>

  <div class="display-none-report-card">
    <app-report-card></app-report-card>
  </div>
</mat-toolbar>