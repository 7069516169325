<div class="container">
  <div class="perfil-container">
    <div class="row">
      <h2>Alterar Senha</h2>
      <p>Deixe em branco caso não queira alterá-la</p>
      
      <div class="form-row" *ngIf="!formEnabled">
        <button data-test-id='test-btn-abrir-form' mat-raised-button color="primary" (click)="enableForm()">
          Deseja trocar sua senha?
        </button>
      </div>
    </div>
    <div class="row">
      <form
        *ngIf="formEnabled"
        [formGroup]="changePasswordForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Senha Atual</mat-label>
            <input
              matInput
              placeholder="Insira sua senha atual aqui"
              formControlName="oldPassword"
              type="password"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Senha Nova</mat-label>
            <input
              matInput
              placeholder="Insira sua nova senha aqui"
              formControlName="newPassword"
              type="password"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>Confirme a nova senha</mat-label>
            <input
              matInput
              placeholder="Confirme sua senha"
              formControlName="confirmPassword"
              type="password"
              required
            />
          </mat-form-field>
        </div>

        <div class="form-row">
          <button
            mat-raised-button
            class="button-rounded"
            color="primary"
            type="submit"
          >
            Atualizar Senha
          </button>
        </div>
        <div class="form-row">
          <button
            mat-stroked-button
            class="button-rounded"
            color="primary"
            (click)="cancelForm()"
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
