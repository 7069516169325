<div class="time-slots-container mat-elevation-z1">
  <div class="header">
    <mat-icon *ngIf="!timeSlots.title">schedule</mat-icon>
    <p *ngIf="timeSlots.title">{{ timeSlots.title }}</p>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="items do menu de horários"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="duplicate()">
        <span>Duplicar</span>
      </button>
      <button mat-menu-item (click)="save()">
        <span>Editar nome</span>
      </button>
      <button mat-menu-item (click)="deleteSchedule()">
        <span>Excluir</span>
      </button>
    </mat-menu>
  </div>

  <ng-container
    *ngFor="let slot of timeSlots.schedule_time_slots; let i = index"
  >
    <div
      class="time-slot-container"
      (mouseenter)="setHoveredIndex(i)"
      (mouseleave)="clearHoveredIndex()"
    >
      <mat-form-field class="time-slot" appearance="outline">
        <app-time-slot-input
          [isEditing]="isEditing"
          [timeSlotContent]="slot"
          (timeSlotChange)="updateTimeSlot($event, i)"
        ></app-time-slot-input>
      </mat-form-field>
      <button
        *ngIf="
          isEditing &&
          hoveredIndex === i &&
          timeSlots.schedule_time_slots.length === i + 1
        "
        mat-stroked-button
        matSuffix
        class="close-button"
        (click)="removeTimeSlot(i)"
        title="close button"
      >
        <div class="circle">
          <div class="cross"></div>
        </div>
      </button>
    </div>
    <mat-divider *ngIf="i < timeSlots.schedule_time_slots.length"></mat-divider>
  </ng-container>

  <div class="add-time-slot" *ngIf="isEditing" (click)="addTimeSlot()">
    <mat-icon>add</mat-icon>
  </div>

  <button
    mat-raised-button
    class="button-rounded save-button"
    color="primary"
    (click)="toggleEdit()"
  >
    {{ isEditing ? "Salvar" : "Editar" }}
  </button>
</div>
