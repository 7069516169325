import { Component } from "@angular/core";
import { SchoolExamsModModels } from "../../../shared/models/exams-school-model.model";
import { MatDialog } from "@angular/material/dialog";
import { AvalDialogComponent } from "../../shared/component/aval-dialog/aval-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ExamSchoolService } from "../../../shared/services/exam-school.service";
import { ExamSchoolModelService } from "../../../shared/services/exam-school-model.service";
import { BreadcrumbService } from "xng-breadcrumb";
import { SchoolExamsModel } from "../../../shared/models/school-exams.model";
import { ExamType } from "src/app/core/enum/examType.enum";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/core/services/auth.service";
import { SchoolService } from "../../../shared/services/school.service";

@Component({
  selector: "app-create-aval",
  templateUrl: "./create-aval.component.html",
  styleUrls: ["./create-aval.component.sass"],
})
export class CreateAvalComponent {
  examModelId!: string;
  examModel: SchoolExamsModModels = {
    title: "",
    passing_score: 0,
    school_id: "",
  };
  examsList!: Partial<SchoolExamsModel>[];

  avaliacaoCounter: number[] = [1, 2, 3, 4];
  placeholderExam: Partial<SchoolExamsModel>[] = [
    {
      title: "Avaliação",
      weight: 2,
      max_score: 0,
      exam_type: ExamType.aval,
      position: 1,
    },
    {
      title: "Avaliação",
      weight: 2,
      max_score: 0,
      exam_type: ExamType.aval,
      position: 2,
    },
    {
      title: "Recuperação",
      weight: 1,
      max_score: 0,
      exam_type: ExamType.rec,
      position: 3,
    },
    {
      title: "Avaliação",
      weight: 2,
      max_score: 0,
      exam_type: ExamType.aval,
      position: 4,
    },
    {
      title: "Avaliação",
      weight: 2,
      max_score: 0,
      exam_type: ExamType.aval,
      position: 5,
    },
    {
      title: "Recuperação",
      weight: 1,
      max_score: 0,
      exam_type: ExamType.rec,
      position: 6,
    },
  ];
  formDataArray: any[] = [];
  currentSchool: any;

  constructor(
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private schoolService: SchoolService,
    private examSchoolService: ExamSchoolService,
    private examSchoolModelService: ExamSchoolModelService
  ) {
    this.breadcrumbService.set("@new", "Avaliações");
    this.breadcrumbService.set("@edit", "Edição de Avaliação");
    this.schoolService.getSelectedSchoolId().subscribe((id) => {
      this.currentSchool = id;
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.examModelId = params["id"];
    });
    if (this.examModelId) {
      this.examSchoolModelService.getOne(this.examModelId).subscribe({
        next: (response) => {
          if (response.data) {
            this.examModel = response.data;
          }
        },
        error: (error) => {},
        complete: () => {},
      });
      this.examSchoolService
        .getOneBySchoolExamModel(this.examModelId)
        .subscribe({
          next: (response) => {
            if (response.data?.data) {
              this.examsList = response.data?.data;
            }
          },
          error: (error) => {},
          complete: () => {
            if (this.examsList.length === 0) {
              this.examsList = this.placeholderExam;
            }
          },
        });
    } else {
      this.examsList = this.placeholderExam;
    }
  }

  getAvalCounter(index: number): number {
    let avaliacaoCounter = 0;
    for (let i = 0; i <= index; i++) {
      if (this.examsList[i].exam_type === ExamType.aval) {
        avaliacaoCounter++;
      }
    }
    return avaliacaoCounter;
  }

  updateFormData(index: number, data: any): void {
    this.formDataArray[index] = data;
  }

  saveAll(): void {
    this.openAvalDialog();
    //console.log("Saving:", this.formDataArray);
  }

  openAvalDialog() {
    const title = this.examModel?.title || "";
    const passing_score = this.examModel?.passing_score || 0;
    const dialogRef = this.dialog.open(AvalDialogComponent, {
      width: "424px",
      data: {
        title: title,
        passing_score: passing_score,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        //console.log("Dialog result:", result);
        try {
          this.examModel.title = result.title;
          this.examModel.passing_score = result.passing_score;
          if (this.examModelId) {
            this.examSchoolModelService
              .update(this.examModelId, this.examModel)
              .subscribe({
                next: (response) => {},
                complete: () => {
                  //console.log("Chamada concluída Modelo");
                },
              });
            this.formDataArray.forEach((schoolExam: SchoolExamsModel) => {
              if (schoolExam.id) {
                this.examSchoolService
                  .update(schoolExam.id, schoolExam)
                  .subscribe({
                    next: (response) => {
                      //console.log(response);
                    },
                    error: (error) => {
                      //console.log(error);
                    },
                    complete: () => {
                      //console.log("Chamada concluída Avaliação", schoolExam.id);
                    },
                  });
              } else {
                schoolExam.school_exam_model_id = this.examModelId;
                this.examSchoolService.save(schoolExam).subscribe({
                  next: (response) => {},
                  complete: () => {
                    // console.log("aval criado com sucesso");
                  },
                });
              }
            });

            this.openSnackBar(
              "Modelo de avaliação atualizado com sucesso",
              "X"
            );
          } else {
            let examModelId: string;
            this.examModel.school_id = this.currentSchool;
            this.examSchoolModelService.save(this.examModel).subscribe({
              next: (response) => {
                if (response.data?.id) examModelId = response.data?.id;
              },
              complete: () => {
                //console.log("modelo criado com sucesso");
                this.formDataArray.forEach((schoolExam: SchoolExamsModel) => {
                  schoolExam.school_exam_model_id = examModelId;
                  this.examSchoolService.save(schoolExam).subscribe({
                    next: (response) => {},
                    complete: () => {
                      //console.log("aval criado com sucesso");
                    },
                  });
                });
              },
            });
            this.openSnackBar("Modelo de avaliação criado com sucesso", "X");
          }
        } catch (error) {
          this.openSnackBar(
            "Ocorreu um erro ao salvar, tente novamente mais tarde",
            "X"
          );
        }
      } else {
        // console.log("Dialog was cancelled");
      }
    });
  }
  openSnackBar(message: string, action: string) {
    const snackbarRef = this._snackBar.open(message, action);
    snackbarRef.afterDismissed().subscribe(() => {
      this.router.navigate(["../.."], {
        relativeTo: this.route,
      });
    });
  }
}
