<div class="drawer-title" *ngIf="exams">
    <h3>Modelo {{exams.title}}</h3>
    <button mat-button class="button-rounded" (click)="navigateToEditModel()"><mat-icon>edit</mat-icon>Editar</button>
</div>
<div class="exam-card-container" *ngIf="exams && exams.content.length > 0">
  <mat-card
    *ngFor="let exam of exams.content"
    class="exam-card mat-elevation-z1 styled-card"
  >
    <mat-card-content>
      <h2>{{ exam.title }}</h2>
      <p *ngIf="getExamType(exam.exam_type)"><strong>Peso:</strong> x{{ exam.weight }}</p>
      <p *ngIf="!getExamType(exam.exam_type)"><strong>Peso:</strong> {{ exam.exam_type }}</p>
    </mat-card-content>
  </mat-card>
</div>
<div *ngIf="!exams || exams.content.length === 0" class="no-content">
  <p>Esse modelo nao possui avaliações criadas</p>
</div>
