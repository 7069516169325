<div
  class="time-slots-container mat-elevation-z1"
  [ngStyle]="{ opacity: isEditable ? '1' : '0.5' }"
  (click)="isEditable ? null : openDialog()"
  [ngClass]="{ 'rigth-rounded-border': diaDaSemana[1] === diaDaSemana[0] }"
>
  <div class="header" [ngClass]="{ 'adjust-padding': isScheduleDisabled }">
    <p>{{ diasDaSemana[getDiaDaSemana()] }}</p>
  </div>

  <ng-container
    *ngFor="let slot of gradeInterativa?.class_slots; let i = index"
  >
    <div
      class="time-slot-container"
      (mouseenter)="setHoveredIndex(i)"
      (mouseleave)="clearHoveredIndex()"
    >
      <div class="time-slot">
        <button
          *ngIf="
            !(getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
            getCellType(slot, 1)
          "
          (click)="focusAndOpenSelect(slot)"
          (mouseenter)="onHover(true)"
          (mouseleave)="onHover(false)"
          mat-raised-button
          class="grade-button"
          matTooltip="Vá em 'Selecione uma disciplina' para preencher um aula aqui"
          matTooltipPosition="right"
          matTooltipShowDelay="1000"
          matTooltipHideDelay="1000"
        >
          + Disciplina
        </button>
        <button
          *ngIf="
            !(getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
            getCellType(slot, 2)
          "
          mat-raised-button
          (click)="callTeste(slot)"
          class="teacher-button"
        >
          <div class="label-text">
            <span class="main-text">{{
              slot.user_subject_class?.subject?.title
            }}</span>
            <span class="sub-text"
              >({{ slot.user_subject_class?.user?.name }})</span
            >
          </div>
        </button>
        <button
          *ngIf="
            (getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
            getCellType(slot, 2)
          "
          mat-raised-button
          (click)="callTeste(slot)"
          class="teacher-button"
        >
          <div class="label-text">
            <span class="main-text" [ngClass]="{ 'is-unavailable': getDisponibilidade(gradeInterativaFiltered,i) }">{{
              slot.user_subject_class?.subject?.title
            }}</span>
            <span class="sub-text"
              >({{ slot.user_subject_class?.user?.name }})</span
            >
          </div>
        </button>

        <button
          *ngIf="getDisponibilidade(gradeInterativaFiltered, i) && isFiltered && !slot.user_subject_class?.subject?.title"
          disabled="true"
          mat-raised-button
          class="indisponible-button"
        >
          INDISPONÍVEL
        </button>
        <button
          *ngIf="
            !(getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
            getCellType(slot, 3)
          "
          mat-raised-button
          class="grade-button"
        >
          INTERVALO
        </button>
        <button
          *ngIf="
            !(getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
            getCellType(slot, 4)
          "
          mat-raised-button
          class="vacant-button"
        >
          VAGO
        </button>
      </div>
      <button
        *ngIf="
          !(getDisponibilidade(gradeInterativaFiltered, i) && isFiltered) &&
          isEditing &&
          isScheduleDisabled &&
          hoveredIndex === i &&
          getSlotType(slot.slot_type)
        "
        mat-stroked-button
        class="close-button"
        [ngClass]="getSlotTypeText(slot.slot_type)"
        (click)="cleanTimeSlot(slot, i)"
        title="close button"
      >
        <div class="circle">
          <div class="cross"></div>
        </div>
      </button>
    </div>
    <mat-divider *ngIf="i < gradeInterativa.class_slots.length"></mat-divider>
  </ng-container>
  <div class="add-placeholder" *ngIf="isTimeSlotEditable && isScheduleDisabled">
    <mat-icon>add</mat-icon>
  </div>
  <button
    *ngIf="isScheduleDisabled"
    disabled="true"
    mat-raised-button
    class="button-placeholder"
    color="primary"
  >
    {{ isEditing ? "Salvar" : "Editar" }}
  </button>
</div>
