import { Injectable } from '@angular/core';
import { BaseModel } from 'src/app/core/models/base.model';
import { SchoolExamsModModels } from "../models/exams-school-model.model";
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: "root",
})
export class ExamSchoolModelService extends BaseService<SchoolExamsModModels> {
  override baseUrl: string = `${this.baseUrl}/school-exam-models`;
}
