import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TimeSlotModel } from "../../models/time-slot.model";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TimeSlotDialogComponent } from "../time-slot-dialog/time-slot-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-schedule-time-slot",
  templateUrl: "./schedule-time-slot.component.html",
  styleUrls: ["./schedule-time-slot.component.sass"],
})
export class ScheduleTimeSlotComponent {
  @Input() timeSlots: TimeSlotModel = {
    title: "modelo",
    schedule_time_slots: [
      {
        start_time: "00:00",
        end_time: "00:00",
      },
      {
        start_time: "00:00",
        end_time: "00:00",
      },
    ],
  };
  @Output() openTimeSlotDialog = new EventEmitter<any>();
  @Output() disableCreateBtn = new EventEmitter<boolean>();
  @Output() disableUpdateBtn = new EventEmitter<boolean>();
  @Output() duplicateCurrentTimeSlotSchedule = new EventEmitter<any>();
  @Output() deleteCurrentTimeSlotSchedule = new EventEmitter<boolean>();
  isEditing: boolean = false;
  hoveredIndex: number | null = null;

  form: FormGroup = new FormGroup({
    tel: new FormControl(null),
  });

  constructor(private dialog: MatDialog) {}
  addTimeSlot() {
    this.timeSlots.schedule_time_slots.push({
      start_time: "00:00",
      end_time: "00:00",
    });
  }

  updateTimeSlot(event: { key: string; value: string }, index: number) {
    if (event.key === "start_time") {
      this.timeSlots.schedule_time_slots[index].start_time = event.value;
    } else if (event.key === "end_time") {
      this.timeSlots.schedule_time_slots[index].end_time = event.value;
    } else {
      // console.error("error in schedule time slot logic");
    }
  }

  duplicate() {
    let newTimeSlot = { ...this.timeSlots };
    newTimeSlot.title = this.timeSlots.title;
    this.duplicateCurrentTimeSlotSchedule.emit(newTimeSlot);
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
    if (this.timeSlots.id) {
      if (this.isEditing) {
        //console.log("Editing mode activated.");
        this.disableUpdateBtn.emit(true);
      } else {
        // console.log("Changes saved.");
        this.update();
      }
    } else {
      if (this.isEditing) {
        //console.log("Creation mode activated.");
        this.disableCreateBtn.emit(true);
      } else {
        //console.log("Changes saved.");
        this.save();
      }
    }
  }
  handleEditToggle(isEditing: boolean) {
    this.isEditing = isEditing;
  }

  save() {
    //console.log("Time slots saved:", this.timeSlots);
    const dialogRef = this.dialog.open(TimeSlotDialogComponent, {
      maxWidth: "320px",
      data: { title: this.timeSlots.title },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.timeSlots.title = result;
        this.openTimeSlotDialog.emit(this.timeSlots);
      }
    });
    this.disableCreateBtn.emit(false);
  }

  update() {
    //console.log("Time slots updated:", this.timeSlots);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "320px",
      data: {
        text: "A mudança de horário irá alterar todas as turmas que utilizam este modelo de horário.Deseja continuar?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openTimeSlotDialog.emit(this.timeSlots);
      }
    });
    this.disableUpdateBtn.emit(false);
  }

  deleteSchedule() {
    //console.log("Time slots deleted:", this.timeSlots);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "320px",
      data: {
        text: "Deletar essa grade de horário irá alterar todas as turmas que utilizam este modelo. Deseja mesmo continuar?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteCurrentTimeSlotSchedule.emit(true);
        this.disableCreateBtn.emit(false);
      }
    });
  }

  removeTimeSlot(index: number): void {
    this.timeSlots.schedule_time_slots.splice(index, 1);
  }

  setHoveredIndex(index: number) {
    this.hoveredIndex = index;
  }

  clearHoveredIndex() {
    this.hoveredIndex = null;
  }
}
