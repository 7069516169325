import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TimeSlotModel } from "../../models/time-slot.model";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { gradeInterativaDayDTO } from "src/app/modulos/private/shared/models/classSchedule/gradeInterativaDayDTO.model";
import { SlotType } from "src/app/core/enum/slotType.enum";
import { gradeInterativaCellDTO } from "src/app/modulos/private/shared/models/classSchedule/gradeInterativaCellDTO.model";
import { WarningDialogComponent } from "../warning-dialog/warning-dialog.component";

@Component({
  selector: "app-schedule-grade-teacher-subject",
  templateUrl: "./schedule-grade-teacher-subject.component.html",
  styleUrls: ["./schedule-grade-teacher-subject.component.sass"],
})
export class ScheduleGradeTeacherSubjectComponent {
  @Input() diaDaSemana!: [number, number];
  @Input() gradeInterativa!: gradeInterativaDayDTO;
  @Input() gradeInterativaFiltered!: gradeInterativaDayDTO;
  @Input() isGradeFiltered: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isScheduleEditable!: boolean;
  @Input() isScheduleDisabled!: boolean;
  @Input() isTimeSlotEditable: boolean = false;
  @Input() selectedValue: any;
  @Output() openTimeSlotDialog = new EventEmitter<any>();
  @Output() openSelect = new EventEmitter<gradeInterativaCellDTO>();
  @Output() newIntervalo = new EventEmitter<gradeInterativaCellDTO>();
  @Output() newClassSlot = new EventEmitter<gradeInterativaCellDTO>();
  @Output() cleanIntervalo = new EventEmitter<gradeInterativaCellDTO>();
  @Output() cleanCell = new EventEmitter<gradeInterativaCellDTO>();
  @Output() hoverStatus = new EventEmitter<boolean>();
  selectedTimeSlot!: TimeSlotModel | null;
  isEditing: boolean = false;
  isFiltered = false;
  hoveredIndex: number | null = null;

  diasDaSemana: string[] = [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  slots = [
    { slot_type: SlotType.intervalo, name: "intervalo-slot" },
    { slot_type: SlotType.vago, name: "vago-slot" },
    { slot_type: SlotType.aula, name: "aula-slot" },
  ];

  constructor(private dialog: MatDialog) {}
  ngOnInit(): void {
    this.isEditing = this.isEditable;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isTimeSlotEditable = this.isTimeSlotEditable;
    if (this.gradeInterativa) this.isScheduleDisabled = this.isScheduleDisabled;
    if (this.isScheduleDisabled) {
    }
    if (this.selectedValue) {
    }
    this.isEditing = this.isEditable;
    if (this.gradeInterativaFiltered) this.isFiltered = this.isGradeFiltered;
    /* console.log(this.selectedValue); */
  }

  getCellType(cellInfo: any, typeButton: number) {
    switch (typeButton) {
      case 1:
        return cellInfo.slot_type == SlotType.pending ? true : false;
      case 2:
        return cellInfo.slot_type == SlotType.aula ? true : false;
      case 3:
        return cellInfo.slot_type == SlotType.intervalo ? true : false;
      case 4:
        return cellInfo.slot_type == SlotType.vago ? true : false;
      default:
        return false;
    }
  }
  getDisponibilidade(slotFiltered: gradeInterativaDayDTO, index: number) {
    return slotFiltered.class_slots[index].is_unavailable;
  }

  callTeste(slot: any) {
    console.log(slot);
  }
  setHoveredIndex(index: number) {
    this.hoveredIndex = index;
  }

  clearHoveredIndex() {
    this.hoveredIndex = null;
  }
  cleanTimeSlot(cellSlot: gradeInterativaCellDTO, index: number): void {
    if (cellSlot.slot_type == SlotType.aula) {
      this.closeDialog(cellSlot);
    } else if (cellSlot.slot_type == SlotType.vago) {
      cellSlot.slot_type = SlotType.pending;
      this.cleanCell.emit(cellSlot);
    } else if (cellSlot.slot_type == SlotType.intervalo) {
      cellSlot.slot_type = SlotType.pending;
      this.cleanIntervalo.emit(cellSlot);
    } else {
      cellSlot.slot_type = SlotType.pending;
    }
  }
  getSlotType(slotType: SlotType) {
    return slotType !== SlotType.pending;
  }
  getSlotTypeText(slotType: SlotType){
    return this.slots.find((slot) => slot.slot_type === slotType)?.name
  }
  openDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: "Defina os horários de aulas para incluir as disciplinas",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("User confirmed");
      } else {
        console.log("User canceled");
      }
    });
  }

  closeDialog(cellSlot: gradeInterativaCellDTO) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: "Deseja retirar esta disciplina desse horário?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("User confirmed");
        cellSlot.slot_type = SlotType.pending;
        this.cleanCell.emit(cellSlot);
      } else {
        console.log("User canceled");
      }
    });
  }
  focusAndOpenSelect(cellSlot: gradeInterativaCellDTO) {
    if (this.selectedValue?.id) {
      if (this.selectedValue.has_max_weekly_hours) {
        this.dialog.open(WarningDialogComponent, {
          data: {
            text: "Esta disciplina já possui todas as aulas atribuídas. Exclua primeiro uma das aulas para preencher aqui",
          },
          maxWidth: "356px",
        });
      } else {
        cellSlot.slot_type = SlotType.aula;
        cellSlot.user_subject_class_id = this.selectedValue.id;
        cellSlot.user_subject_class = {
          user: this.selectedValue.user,
          subject: this.selectedValue.subject,
        };
        this.newClassSlot.emit(cellSlot);
      }
    } else if (this.selectedValue?.class_id) {
      if (this.selectedValue.slot_type === SlotType.vago) {
        cellSlot.slot_type = SlotType.vago;
        this.newClassSlot.emit(cellSlot);
      } else if (this.selectedValue.slot_type === SlotType.intervalo) {
        cellSlot.slot_type = SlotType.intervalo;
        this.newIntervalo.emit(cellSlot);
      }
    } else {
      this.openSelect.emit(cellSlot);
    }
  }

  onHover(isHovered: boolean): void {
    this.hoverStatus.emit(isHovered);
  }

  getDiaDaSemana() {
    return this.diaDaSemana[0] - 1;
  }
}
