import { BreadcrumbService } from "xng-breadcrumb";
import { Component } from "@angular/core";
import { TurmaService } from "../../../shared/services/turma.service";
import { LoggingService } from "src/app/core/services/logging.service";
import { Turma } from "../../../shared/models/turma.model";
import { PageEvent } from "@angular/material/paginator";
import { PaginationData } from "src/app/core/models/pagination-data";
import { ApiResponse } from "src/app/core/models/api-response";
import { CustomPagination } from "../../shared/models/custom-pagination.model";
import { DeleteDialogComponent } from "../../../shared/components/delete-dialog/delete-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { GradeService } from "../../../shared/services/grade.service";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { environment } from "src/environments/env";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { FormBuilder, FormGroup } from "@angular/forms";

const API_URL = environment.api_url;
interface Grade {
  id: string;
  nome: string;
}

interface Disciplina {
  id: string;
  nome: string;
}

@Component({
  selector: "app-school-grade-topics",
  templateUrl: "./school-grade-topics.component.html",
  styleUrls: ["./school-grade-topics.component.sass"],
})
export class SchoolGradeTopicsComponent {
  grades: Grade[] = [];
  disciplinas: Disciplina[] = [];
  gradeSelected: string | undefined;
  disciplinaSelected: string | undefined;
  receivedGradeFile: any;
  dragActive: boolean = false;
  uploaderActive: boolean = false;
  topicForm: FormGroup;
  
  isUploading: boolean = false;
  uploadSuccess: boolean = false;
  uploadedFileName: string = '';
  downloadUrl: string = '';

    public uploader: FileUploader = new FileUploader({
      url: API_URL + "/topic-school/null",
      itemAlias: "file",
      headers: [{ name: "Accept", value: "application/json" }],
    });
  
  constructor(
    private breadcrumbService: BreadcrumbService,
    private subjectService: DisciplineService,
    private gradeService: GradeService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder
  ) {
    this.uploader.onBeforeUploadItem = (item) => {
      this.isUploading = true;
      this.uploadSuccess = false;
    };
    this.topicForm = this.fb.group({
      grade: [''],
      disciplina: [''],
      url: ['']
    });
  }

  async ngOnInit(): Promise<void> {
    this.breadcrumbService.set("@read", "Plano Letivo");
    this.breadcrumbService.set("@edit", "Tópicos do plano letivo");
    this.gradeService.fetchGradeBySchool();
    this.loadGrades();

    this.listeners();
  }

  listeners() {
    this.topicForm.get('grade')?.valueChanges.subscribe(gradeId => {
      if (gradeId) {
        this.onGradeChange(gradeId);
        this.resetUpload();
      }
    });
    this.topicForm.get('disciplina')?.valueChanges.subscribe(disciplineId => {
      if (disciplineId) {
        this.onDisciplinaChange(disciplineId, this.topicForm.get('grade')?.value);
        this.resetUpload();
      }
    });
  }

  loadGrades() {
    this.gradeService.getGrades().subscribe({
      next: (data: any) => {
        console.log(data);
        if (!data) {
          return;
        }
        this.grades = data!.map((grade: any) => {
          return {
            id: grade.id,
            nome: grade.title,
          };
        });

        console.log(this.grades);
      },
      complete: () => {},
    });
  }

  onGradeChange(gradeId: string) {
    if (gradeId) {
      this.uploaderActive = false;
      this.topicForm.patchValue({disciplina: ''});

      this.subjectService.getAllSubjects().subscribe({
        next: (data: any) => {
          if (!data) {
            return;
          }
          this.disciplinas = data.data!.data!.map((disciplina: any) => {
            return {
              id: disciplina.id,
              nome: disciplina.title,
            };
          });
        },
      });
    }
  }

  onDisciplinaChange(disciplineId: string, gradeId: string) {
    if (disciplineId && gradeId && this.localStorageService.getSelectedSchool()) {
      const token_session = this.localStorageService.getToken();
      this.uploader.setOptions({
        url: API_URL + "/topic-school/"+this.localStorageService.getSelectedSchool()+"/"+gradeId+"/"+ disciplineId,
        headers : [
          { name: "Accept", value: "application/json" },
          { name: "Authorization", value: `Bearer ${token_session}` },
        ]
      });
      this.uploader.onErrorItem = (item, response, status) =>
        this.onErrorItem(item, response, status);
      this.uploader.onSuccessItem = (item, response, status) =>
        this.onSuccessItem(item, response, status);
      this.uploaderActive = true;
    }else{
      alert("Selecione uma disciplina e uma série antes de enviar o arquivo");
      this.uploaderActive = false;
    }
  }


   onDrop(event: any) {
      event.preventDefault();
      event.stopPropagation();
  
      // this.uploader = event.dataTransfer.files;
      this.uploader.uploadAll();
  
      this.dragActive = false;
    }
  
    onDragOver(event: any) {
      event.preventDefault();
      event.stopPropagation();
  
      // const dragdrop = document.getElementsByClassName('full');
  
      // dragdrop[0].classList.add('full-active');
  
      this.dragActive = true;
    }
  
    onDragLeave(event: any) {
      event.preventDefault();
      event.stopPropagation();
  
      // const dragdrop = document.getElementsByClassName('full');
  
      // dragdrop[0].classList.remove('full-active');
  
      this.dragActive = false;
    }
  
    onFileChange(event: any) {
      const files = event.target.files;
      this.uploader.uploadAll();
      // this.uploadFiles(files);
    }
  
    onSuccessItem(item: FileItem, response: string, status: number): any {
      const responseData = JSON.parse(response);
      this.isUploading = false;
      this.uploadSuccess = true;
      this.uploadedFileName = item.file.name!;
      this.downloadUrl = responseData.downloadUrl; // ajuste conforme sua API
      alert("Arquivo enviado com sucesso");
      // alert('Arquivo enviado com sucesso');
      // console.log(this.array_files)
    }
    //user suject class
    //subject_id
    onErrorItem(item: FileItem, response: string, status: number): any {
      let error: any = JSON.parse(response); 
      this.isUploading = false;
      this.uploadSuccess = false;
      this.uploaderActive = true;
      alert("Erro ao enviar imagem : " + error.message);
    }
    resetUpload() {
      this.uploadSuccess = false;
      this.uploaderActive = true;
      this.uploadedFileName = '';
      this.downloadUrl = '';
    }
    receiveObjectFromChild(object: any) {
      this.receivedGradeFile = object;
  
    }
}
