import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { DialogTableComponent } from "../../shared/component/dialog-table/dialog-table.component";
import { UserService } from "../../../shared/services/user.service";
import { ApiResponse } from "src/app/core/models/api-response";
import { User } from "src/app/core/models/user.model";
import { TurmaService } from "../../../shared/services/turma.service";
import { PaginationData } from "src/app/core/models/pagination-data";
import { Observable } from "rxjs";
import { DisciplineService } from "../../../shared/services/discipline.service";
import { Subject } from "../../../shared/models/subject.model";
import { GradeClassService } from "../../../shared/services/grade-class.service";
import { GradeClass } from "../../../shared/models/gradeClass.model";
import { LoggingService } from "src/app/core/services/logging.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TeacherClassSubjectService } from "../../../shared/services/teacher-class-subject.service";
import { environment } from "src/environments/env";
import { ApiService } from "src/app/core/services/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FileItem, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { BoletimPdfService } from "../../../shared/services/boletim-pdf.service";
import { CustomPagination } from "../../shared/models/custom-pagination.model";
import { PageEvent } from "@angular/material/paginator";
import { SchoolExamsService } from "../../../shared/services/school-exams.service";
import { SchoolExamsModel } from "../../../shared/models/school-exams.model";
import { ClassScheduleSubjectService } from "../../../shared/services/class-schedule-subject.service";
import { ClassScheduleSubjectModel } from "../../../shared/models/class-schedule-subject.model";
import { TimeSlotModel } from "../../shared/models/time-slot.model";
import { SchoolScheduleService } from "../../../shared/services/school-schedule.service";
import { Turma } from "../../../shared/models/turma.model";
import { MatSelect } from "@angular/material/select";
import { ClassScheduleSlot } from "../../../shared/models/ClassScheduleSlot.model";
import { AddScheduleModelDTO } from "../../../shared/models/addScheduleModelDTO.model";
import { gradeInterativaDTO } from "../../../shared/models/classSchedule/gradeInterativaDTO.model";
import { gradeInterativaDayDTO } from "../../../shared/models/classSchedule/gradeInterativaDayDTO.model";
import { gradeInterativaCellDTO } from "../../../shared/models/classSchedule/gradeInterativaCellDTO.model";
import { SlotType } from "src/app/core/enum/slotType.enum";
import { UnsavedChangesComponent } from "../../shared/guards/unsaved-changes.guard";

const API_URL = environment.api_url;
export interface disciplineSample {
  disciplina: string;
  cargaHoraria: string;
  professor: string;
}

const ELEMENT_DATA: disciplineSample[] = [
  {
    disciplina: "Português",
    cargaHoraria: "68h",
    professor: "",
  },
  {
    disciplina: "Matemática",
    cargaHoraria: "68h",
    professor: "",
  },
  {
    disciplina: "Literatura",
    cargaHoraria: "48h",
    professor: "",
  },
  {
    disciplina: "História Geral",
    cargaHoraria: "48h",
    professor: "",
  },
  {
    disciplina: "História do Brasil",
    cargaHoraria: "48h",
    professor: "",
  },
  {
    disciplina: "Geografia",
    cargaHoraria: "48h",
    professor: "",
  },
  {
    disciplina: "Quimica",
    cargaHoraria: "62h",
    professor: "",
  },
  {
    disciplina: "Física",
    cargaHoraria: "62h",
    professor: "",
  },
];

@Component({
  selector: "app-edit-turmas",
  templateUrl: "./edit-turmas.component.html",
  styleUrls: ["./edit-turmas.component.sass"],
})
export class EditTurmasComponent implements UnsavedChangesComponent, OnDestroy {
  @ViewChildren(MatTable) tables!: QueryList<MatTable<any>>;
  //dataSource = ELEMENT_DATA;
  displayedColumns: string[] = [
    "title",
    // "cargaSemanal",
    "professor",
    //"actions",
  ];

  API_URL = environment.api_url;
  displayedStudentsColumns: string[] = ["select", "name", "id", "actions"];
  myForm!: FormGroup;
  turmaId!: string;
  gradeId!: string;
  turmaTitle!: string;
  alunos!: User[];

  alunosInClass!: User[];
  disciplinaInClass!: Subject[];
  disciplinaInClassNotDefault: Subject[] = [];
  currentDisciplinaInClassNotDefault: Subject[] = [];

  disciplinaInClass$!: Observable<ApiResponse<PaginationData<Subject>> | null>;
  selectedDisciplina: any;
  professorList!: User[];
  professorList$!: Observable<ApiResponse<PaginationData<User>> | null>;
  disciplinaWithTeacherInClass$!: Observable<ApiResponse<
    PaginationData<Subject>
  > | null>;

  gradeInClass!: GradeClass;
  receivedGradeFile: any;
  gradeImage: any;
  isGradeWoutClass: boolean = true;
  gradeInteractiveData: ClassScheduleSubjectModel[] = [];
  currentGradeInteractive: any;

  dragActive: boolean = false;

  selected_ids: number[] = [];
  total_alunos: number = 0;

  pagination: CustomPagination = {
    length: 0,
    pageSize: 10,
  };

  hasNoSubject = false;
  hasSubjectWoutTeacher = false;
  hasNoStudent = false;
  hasNoGrade = false;

  isPanel1Active = false;
  isPanel2Active = false;
  isUsingScheduleModel: boolean = false;
  isTimeSlotEditable = false;
  isScheduleEditable = false;
  fetchSize: boolean = true;
  scheduleModelId!: string | null;
  selectedSubjectTeacher: any;

  timeSlotSchoolItems: TimeSlotModel[] = [];
  timeSlotCreate: TimeSlotModel = {
    title: "title",
    schedule_time_slots: [{ start_time: "00:00", end_time: "00:00" }],
  };
  currentTimeSlot: TimeSlotModel = {
    id: undefined,
    title: "",
    schedule_time_slots: [
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
    ],
  };
  baseGradeInterativaDay: gradeInterativaDayDTO = {
    week_day: 0,
    class_slots: Array(6).fill({
      is_unavailable: false,
      week_day: 0,
      slot_type: null,
      schedule_time_slot_id: "",
      class_id: "",
      user_subject_class_id: "",
      user_subject_class: "",
      start_time: "",
      end_time: "",
    }),
  };
  gradeInterativa!: gradeInterativaDTO;
  gradeInterativaFiltered!: gradeInterativaDTO;
  isGradeFiltered: boolean = false;
  isGradeWPending: boolean = false;
  diasDaSemana: number[] = [1, 2, 3, 4, 5, 6];
  isCellBtnHovered!: boolean;
  @ViewChild("matSelect") matSelect!: MatSelect;

  hasNoSubjecttxt =
    "Esta turma ainda não tem disciplinas. Crie disciplinas padrão para esta serie no menu Disciplinas para poder vincular professores.";
  subjectSplit = "Disciplinas";
  linkSubject = "disciplina";

  hasSubjectWoutTeachettxt =
    "Esta turma tem disciplinas sem professores. Adicione um professor para cada Disciplina desta Turma.";

  hasNoStudenttxt =
    "Sua turma ainda não possui estudantes. Adicione Estudantes para preencher esta Turma.";
  studentSplit = "Estudantes";
  linkStudent = "aluno";

  hasNoGradetxt =
    "Inclua uma grade na aba Grade da Turma para que os estudantes desta turma saibam seus horários de aula.";

  hasPendingInGrade =
    "Para definir uma disciplina a um horário da semana, clique em uma disciplina no quadro e selecione uma na caixa 'Selecione a disciplina' ou vá direto na caixa 'Selecione a disciplina', escolha uma e veja as disponibilidades de horários dela";
  changeData(event: PageEvent) {
    this.getStudentsPag(event.pageIndex + 1, event.pageSize);
  }

  getStudentsPag(pageIndex: number = 1, pageSize: number = 10) {
    // console.log(
    //   "PAGE ",
    //   pageIndex,
    //   " LIMIT ",
    //   pageSize,
    //   " TURMA ",
    //   this.turmaId
    // );
    this.userService
      .getUserByClassPagination(this.turmaId, pageIndex, pageSize)
      .subscribe({
        next: (data: ApiResponse<PaginationData<User>>) => {
          //console.log("ALUNO", data.data);
          this.alunosInClass = data.data!.data;
          if (this.alunosInClass.length == 0) {
            this.hasNoStudent = true;
          }
          this.total_alunos = data.data!.totalItems;
          this.pagination = {
            length: data.data?.totalItems ?? 0,
            pageSize: data.data?.limit ?? 0,
          };
        },
      });
  }

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private turmaService: TurmaService,
    private disciplinaService: DisciplineService,
    private gradeClassService: GradeClassService,
    private logService: LoggingService,
    private teacherClassSubjectService: TeacherClassSubjectService,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private boletimPdfService: BoletimPdfService,
    private schoolExamService: SchoolExamsService,
    private classScheduleSubjectService: ClassScheduleSubjectService,
    private schoolScheduleService: SchoolScheduleService
  ) {}

  public uploader: FileUploader = new FileUploader({
    url: API_URL + "/files/upload",
    itemAlias: "file",
    headers: [{ name: "Accept", value: "application/json" }],
  });

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.turmaId = params["id"];
    });
    this.getDisciplinasByGrade();
    this.getSubjectWithTeacher();
    //this.alunosInClass$ = this.userService.getUserByClass(Number(this.turmaId));
    //this.getStudentsByClass();
    this.getStudentsPag();
    this.getStudents();
    this.getProfessor();
    this.getGradeClass(this.turmaId);
    this.uploadFiles();
    this.updateSelector();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.isScheduleEditable = false;
  }
  @HostListener("window:beforeunload", ["$event"])
  handleBeforeUnload(event: BeforeUnloadEvent): void {
    if (this.isScheduleEditable) {
      event.preventDefault();
      event.returnValue = ""; // Display a confirmation dialog
    }
  }
  file(download: boolean) {
    this.apiService
      .downloadFile(`${this.API_URL}/${this.gradeInClass.file_url}`)
      .subscribe((data) => {
        const blob = new Blob([data], { type: data.type });
        const url = window.URL.createObjectURL(blob);

        if (download) {
          const link = document.createElement("a");
          link.href = url;
          link.download = this.extractFileName(this.gradeInClass.file_url);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          window.open(url);
        }
      });
  }

  selectAll(checked: boolean): void {
    this.alunosInClass.forEach(
      (student: any) => (student.isSelected = checked)
    );
    this.updateSelectedIds();
  }

  selectStudent(student: any, checked: boolean): void {
    student.isSelected = checked;
    this.updateSelectedIds();
  }

  a(idsSelected: any[]) {
    this.boletimPdfService.changeData(this.turmaId, idsSelected);
    // alert(any)
  }
  updateSelectedIds(): void {
    this.selected_ids = this.alunosInClass
      .filter((student: any) => student.isSelected)
      .map((student: any) => student.id);
    //console.log(this.selected_ids);
  }

  extractFileName(fileUrl: string): string {
    const urlParts = fileUrl.split("/");
    return urlParts[urlParts.length - 1];
  }

  removeFile() {
    this.deleteGrade();
  }

  deleteGrade() {
    this.gradeClassService.deleteGrade(this.gradeInClass.id).subscribe({
      next: (data) => {
        this.gradeInClass = {} as GradeClass;
      },
      error: (error) => {
        // console.error(
        //   "Erro no fetch e/ou processamento da informações do estudante: ",
        //   error
        // );
      },
      complete: () => {
        //console.log("fetch infos estudante complete");
      },
    });
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // this.uploader = event.dataTransfer.files;
    this.uploader.uploadAll();

    this.dragActive = false;
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.add('full-active');

    this.dragActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // const dragdrop = document.getElementsByClassName('full');

    // dragdrop[0].classList.remove('full-active');

    this.dragActive = false;
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.uploader.uploadAll();
    // this.uploadFiles(files);
  }

  uploadFiles() {
    //file
    // let token_session = this.jwtService.getTokenSession();
    var uo: FileUploaderOptions = {
      url: API_URL + "/files/upload",
    };
    const token_session = this.localStorageService.getToken();
    uo.headers = [
      { name: "Accept", value: "application/json" },
      { name: "Authorization", value: `Bearer ${token_session}` },
    ];
    this.uploader.setOptions(uo);
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    //this.uploader.onCompleteItem = (item: FileItem,response: string, status: any) => {
    //  alert('File successfully uploaded!');
    //};
    this.uploader.onErrorItem = (item, response, status) =>
      this.onErrorItem(item, response, status);
    this.uploader.onSuccessItem = (item, response, status) =>
      this.onSuccessItem(item, response, status);
  }

  onSuccessItem(item: FileItem, response: string, status: number): any {
    let data = JSON.parse(response); //success server response
    // this.gradeInClass = data.data
    this.receiveObjectFromChild(data.data);
    // alert('Arquivo enviado com sucesso');
    // console.log(this.array_files)
  }
  //user suject class
  //subject_id
  onErrorItem(item: FileItem, response: string, status: number): any {
    let error: any = JSON.parse(response); //error server response
    // alert('Erro ao enviar imagem : ' + error.message);
    alert("Erro ao enviar imagem : " + error.message);
  }

  @ViewChild("fileInput")
  fileInput!: ElementRef;

  onFileSelected(event: any) {
    // Lógica para lidar com o arquivo selecionado
    const files = event.target.files;
    // console.log(files);
    // this.uploader.uploadAll()
    // Adicione aqui a lógica para processar os arquivos, por exemplo, enviá-los para o servidor.
  }

  onDisciplinaChange(selectedItem: any): void {
    this.isGradeFiltered = true;
    this.currentGradeInteractive = selectedItem;
    if (typeof selectedItem === "string") {
      if (selectedItem === "VAGO") {
        this.selectedDisciplina = {
          slot_type: SlotType.vago,
          class_id: this.turmaId,
        };
      } else if (selectedItem === "INTERVALO") {
        this.selectedDisciplina = this.selectedDisciplina = {
          slot_type: SlotType.intervalo,
          class_id: this.turmaId,
        };
      }
    } else {
      this.selectedDisciplina = selectedItem;
    }
    if (this.scheduleModelId) {
      this.schoolScheduleService
        .getOneByClassAndUserSubjectClass(
          this.scheduleModelId,
          this.turmaId,
          selectedItem.id
        )
        .subscribe({
          next: (data) => {
            if (data.data) this.gradeInterativaFiltered = data.data;
          },
          error: (err) => {},
        });
    }

    // console.log(this.selectedDisciplina);
  }

  legendSelectedProgressBar() {
    if (this.currentGradeInteractive.current_weekly_hours_count === 0) {
      return "Nenhuma aula foi atribuída a essa disciplina";
    } else if (
      this.currentGradeInteractive.current_weekly_hours_count > 0 &&
      this.currentGradeInteractive.current_weekly_hours_count <
        this.currentGradeInteractive.subject.weekly_hours
    ) {
      return "Falta atribuir aula a essa disciplina";
    } else if (
      this.currentGradeInteractive.current_weekly_hours_count ===
      this.currentGradeInteractive.subject.weekly_hours
    ) {
      return "✔ Todas as aulas dessa disciplina foram atribuídas";
    }
    return "";
  }

  getColorClassLegendProgressBar(): string {
    if (this.currentGradeInteractive.current_weekly_hours_count === 0) {
      return "red-text";
    } else if (
      this.currentGradeInteractive.current_weekly_hours_count > 0 &&
      this.currentGradeInteractive.current_weekly_hours_count <
        this.currentGradeInteractive.subject.weekly_hours
    ) {
      return "blue-text";
    } else if (
      this.currentGradeInteractive.current_weekly_hours_count ===
      this.currentGradeInteractive.subject.weekly_hours
    ) {
      return "green-text";
    }
    return "";
  }

  getDisciplinasByGrade() {
    this.turmaService.getOne(this.turmaId).subscribe({
      next: (apiData) => {
        this.turmaTitle = apiData.data?.title!;
        this.breadcrumbService.set("@edit", `${this.turmaTitle}`);
        let gradeId = apiData.data?.grade_id;
        this.gradeId = gradeId ?? "0";
        this.isUsingScheduleModel = apiData.data?.is_using_schedule_model!;
        this.scheduleModelId = apiData.data?.schedule_model_id!;

        this.disciplinaInClass$ = this.disciplinaService.getSubjectsByGradeId(
          this.gradeId,
          true
        );
        this.disciplinaInClass$.subscribe({
          next: (disciplinaData) => {
            const result = this.disciplinaService.getRawDisciplinaContent(
              disciplinaData!
            );
            this.disciplinaInClass = result?.map((subject) => {
              const filteredUserSubjectClass =
                subject.user_subject_class.filter(
                  (usc: { class: { id: string } }) =>
                    usc.class.id == this.turmaId
                );
              // Verifica se o array filtrado está vazio e atualiza hasY
              if (filteredUserSubjectClass.length === 0) {
                this.hasSubjectWoutTeacher = true;
              }
              return {
                ...subject,
                user_subject_class: subject.user_subject_class.filter(
                  (usc: { class: { id: string } }) =>
                    usc.class.id == this.turmaId
                ),
              };
            });

            const fullDisciplinas = this.disciplinaInClass;
            if (fullDisciplinas.length == 0) {
              this.hasNoSubject = true;
            }
            this.disciplinaInClass = fullDisciplinas.filter(
              (item) => item.isDefault
            );
            this.disciplinaInClassNotDefault = fullDisciplinas.filter(
              (item) => !item.isDefault
            );
            console.table(this.disciplinaInClassNotDefault);
          },
          error: (error) => {
            this.logService.error(
              `error in get disciplinas by gradeId ${gradeId}`
            );
          },
        });
      },
      error: (error) => {
        this.logService.error(
          `error in getOne Turma with id ${this.turmaId}`,
          error
        );
      },
      complete: () => {
        if (this.isUsingScheduleModel) {
          this.isPanel1Active = false;
          this.isPanel2Active = true;
        } else {
          this.isPanel1Active = true;
          this.isPanel2Active = false;
        }
        this.getGradeInterativa(this.turmaId);
      },
    });
  }

  getProfessorNames(element: { user_subject_class: any[] }): string | null {
    const names = element.user_subject_class
      .map((usc) => (usc.user ? usc.user.name : null))
      .filter((name) => name !== null);

    return names.length > 0 ? names.join(", ") : "Sem professor";
  }
  getSubjectWithTeacher() {
    this.disciplinaService.getSubjectsByClassId(this.turmaId, false).subscribe({
      next: (apiData) => {
        const disciplinaNotInClass = this.filterDisciplinaInClass(
          apiData.data!.data!
        );
        this.currentDisciplinaInClassNotDefault = disciplinaNotInClass;
      },
      error: (error) => {
        this.logService.error(
          `error in returning turma with subjects and users ${this.turmaId}`,
          error
        );
      },
    });
  }
  getStudents() {
    this.userService.getUserByRoleWithUserClass(1).subscribe({
      next: (data) => {
        this.alunos = data.data!.data!;
        this.logService.log(`Alunos`, this.alunos);
        this.alunos = this.alunos.filter(
          (aluno: any) => Object.keys(aluno.user_class).length === 0
        );
        this.logService.log(`Alunos filtrados`, this.alunos);
      },
      error: (error) => {
        this.logService.error(`error in getUserByRole ${error}`);
      },
      complete: () => {
        this.logService.info(`Complete get user by role call`);
      },
    });
  }

  getStudentsByClass() {
    this.userService.getUserByClass(this.turmaId).subscribe({
      next: (apiData) => {
        this.alunosInClass = apiData.data!.data;

        this.logService.log(this.alunosInClass);
        this.total_alunos = apiData.data!.totalItems;
      },
      error: (error) => {
        this.logService.error(`error in fetch the student by turma`);
      },
    });
  }

  removeStudentFromClass(studentClassRelationId: any) {
    this.turmaService
      .removeStudent(studentClassRelationId.user_class.id)
      .subscribe({
        next: () => {
          this.logService.log(
            `success in deleting user_class with id ${studentClassRelationId.id}`
          );
          this.getStudentsPag();
        },
        error: (error) => {
          this.logService.error(
            `error in deleting user_class with id ${studentClassRelationId.id}`,
            error
          );
        },
      });
  }

  getProfessor() {
    this.professorList$ = this.userService.getUserByTwoRoleWithMeta(2, 3);
    this.professorList$.subscribe({
      next: (apiData) => {
        this.professorList = apiData?.data?.data!;
      },
      error: (error) => {
        this.logService.error(
          `error in retrieving data from user by role of teacher`,
          error
        );
      },
    });
  }

  getGradeClass(turmaId: string) {
    this.gradeClassService.getByClassId(turmaId).subscribe({
      next: (apiData) => {
        this.gradeInClass = apiData.data!;
        this.isGradeWoutClass = false;
        // this.gradeClassService.fileService
        //   .getFileDownloadLink(this.gradeInClass.file_url)
        //   .subscribe({
        //     next: (fileserverData) => {
        //       this.logService.log("fileServerData:", fileserverData);
        //       this.gradeImage = fileserverData;
        //     },
        //     error: (error) => {
        //       this.logService.error(`error in fetch the file in fileserver`);
        //     },
        //   });
      },
      error: (error) => {
        this.logService.error(`error in fetch the student by turma`);
        this.isGradeWoutClass = true;
      },
    });
  }

  receiveObjectFromChild(object: any) {
    this.receivedGradeFile = object;

    if (!this.isGradeWoutClass) {
      this.changeGradeClassButton();
    } else {
      this.saveGradeClass(object);
    }
  }

  saveGradeClass(object: any) {
    //this.gradeImage = object;
    let gradeClass: GradeClass = {
      title: this.receivedGradeFile.filename,
      description: `Grade da turma ${this.turmaTitle}`,
      file_url: this.receivedGradeFile.url,
      class_id: this.turmaId,
    };

    this.gradeClassService.save(gradeClass).subscribe({
      next: (apiData) => {
        this.gradeInClass = apiData.data!;
        this.isGradeWoutClass = false;
        this._snackBar.open("Salvamos a grade escolar com sucesso", "Fechar", {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        });
      },
      error: (error) => {
        // console.log(
        //   `error in saving the file inside GradeClass`,
        //   error.message
        // );
      },
    });
  }

  changeGradeClassButton() {
    if (this.gradeInClass.id === undefined) {
      this._snackBar.open(
        '"Ocorreu um erro, atualize a página e tente novamente',
        "Fechar",
        {
          duration: 3000,
          panelClass: ["mat-snackbar-success"],
        }
      );
      return;
    }

    let gradeClass: GradeClass = {
      title: this.receivedGradeFile.filename,
      description: `Grade da turma ${this.turmaTitle}`,
      file_url: this.receivedGradeFile.url,
      class_id: this.turmaId,
    };
    this.gradeClassService.update(this.gradeInClass.id, gradeClass).subscribe({
      next: (apiData) => {
        this.gradeInClass = apiData.data!;
        this.isGradeWoutClass = false;
        this._snackBar.open(
          "Atualizamos a grade escolar com sucesso",
          "Fechar",
          {
            duration: 3000,
            panelClass: ["mat-snackbar-success"],
          }
        );
      },
      error: (error) => {
        //console.log(`error in saving the file inside GradeClass`);
      },
    });
  }

  /**
   * type 1 for student
   * type 2 for teacher
   * type 3 for subject
   */
  openDialog(
    content: any[],
    showLogin: boolean = false,
    type: number,
    subjectId?: string,
    editElement?: string | null,
    Subdefault?: boolean
  ): void {
    const dialogRef = this.dialog.open(DialogTableComponent, {
      data: { content, showLogin, type, gradeId: this.gradeId },
      minWidth: "70%",
    });
    // Handle the result when the dialog is closed, there is 2 possible returns, a User[] and a Subject[]
    dialogRef.afterClosed().subscribe((selectedItems: any[]) => {
      if (selectedItems && selectedItems.length > 0) {
        switch (type) {
          case 1:
            this.logService.log("alunos para matricular", selectedItems);
            this.saveStudents(selectedItems);
            break;
          case 2:
            this.logService.log("Professores para matricular", selectedItems);

            if (editElement != null) {
              this.updateTeacherSubject(
                selectedItems,
                subjectId ?? "0",
                editElement,
                Subdefault
              );
            } else {
              this.saveTeacherSubject(
                selectedItems,
                subjectId ?? "0",
                Subdefault
              );
            }
            break;
          case 3:
            this.logService.log(
              "disciplinas especiais para adicionar",
              selectedItems
            );
            this.addSpecialSubject(selectedItems);

            break;

          default:
            break;
        }
      }
    });
  }

  saveStudents(student2Add: User[]) {
    const turmaWithStudent = this.turmaService.createRelationWithStudent(
      student2Add,
      this.turmaId
    );
    this.logService.log(turmaWithStudent);
    this.turmaService.addStudent(turmaWithStudent).subscribe({
      next: (data) => {
        this.logService.log(`student added to the class with success`, data);
        this.getStudentsPag();
        this.tables.forEach((table) => table.renderRows());
      },
      error: () => {
        this.logService.error(`error handling the addStudent2Class`);
      },
      complete: () => {},
    });
  }

  saveTeacherSubject(teacherAdd: User[], subjectId: string, Subdefault = true) {
    const teacherSubjectClassRelation = {
      ids: [
        {
          user_id: teacherAdd[0].id,
          class_id: this.turmaId,
          subject_id: subjectId,
        },
      ],
    };
    this.logService.log(teacherSubjectClassRelation);

    this.teacherClassSubjectService
      .addTeacherSubject(teacherSubjectClassRelation)
      .subscribe({
        next: (data) => {
          this.logService.log(
            `teacher added to the class and subject with success`,
            data
          );
          if (Subdefault) {
            this.getDisciplinasByGrade();
          } else {
            this.getSubjectWithTeacher();
          }
          this.tables.forEach((table) => table.renderRows());
        },
        error: () => {
          this.logService.error(`error handling the addTeacherSubject`);
        },
        complete: () => {
          this.logService.info(`addTeacherSubject call concluded`);
        },
      });
  }
  updateTeacherSubject(
    teacherAdd: User[],
    subjectId: string,
    editElement?: string,
    Subdefault = true
  ) {
    if (editElement === undefined) return;
    const teacherSubjectClassRelation = {
      user_id: teacherAdd[0].id,
      class_id: this.turmaId,
      subject_id: subjectId,
    };

    this.logService.log(teacherSubjectClassRelation);

    this.teacherClassSubjectService
      .updateTeacherSubject(editElement, teacherSubjectClassRelation)
      .subscribe({
        next: (data) => {
          this.logService.log(
            `teacher added to the class and subject with success`,
            data
          );
          if (Subdefault) {
            this.getDisciplinasByGrade();
          } else {
            this.getSubjectWithTeacher();
          }
          this.tables.forEach((table) => table.renderRows());
        },
        error: () => {
          this.logService.error(`error handling the addTeacherSubject`);
        },
        complete: () => {
          this.logService.info(`addTeacherSubject call concluded`);
        },
      });
  }
  teacherSubjectClassRelation() {}

  filterDisciplinaInClass(data: Subject[]) {
    const disciplinaInClass = data.map((subject) => {
      return {
        ...subject,
        user_subject_class: subject.user_subject_class.filter(
          (usc: { class: { id: string } }) => usc.class?.id == this.turmaId
        ),
      };
    });
    return disciplinaInClass;
  }

  addSpecialSubject(subject2Add: Subject[]) {
    const disciplinaNotInClass = this.filterDisciplinaInClass(subject2Add);

    let dataToDisplay = [...this.currentDisciplinaInClassNotDefault];
    disciplinaNotInClass.forEach((element) => {
      dataToDisplay = [...dataToDisplay, element];
    });
    this.currentDisciplinaInClassNotDefault = dataToDisplay;
    this.tables.forEach((table) => table.renderRows());
    //console.log(this.currentDisciplinaInClassNotDefault)
  }

  deleteSpecialSubject(elementIndex: number) {
    let dataToDisplay = [...this.currentDisciplinaInClassNotDefault];
    dataToDisplay.splice(elementIndex, 1);
    this.currentDisciplinaInClassNotDefault = dataToDisplay;
    this.tables.forEach((table) => table.renderRows());
  }

  //** Seção de código relacionado a seção de frequência e boletim dos alunos ***/

  getListOfSubjectWithTeacher(limit: number) {
    this.disciplinaWithTeacherInClass$ =
      this.disciplinaService.getListOfSubjectAndTeacher(this.turmaId, 1, limit);
    this.listSubjectWTeacher = [];
    this.disciplinaWithTeacherInClass$.subscribe({
      next: (response) => {
        this.listSubjectWTeacherResponse = response?.data?.data;
      },
      complete: () => {
        this.listSubjectWTeacherResponse.forEach((subject: any) => {
          const subjWTeacher: subjectSelector = {
            title: "",
            subjectId: "",
            model_id: "",
          };
          let texto = `${subject.title}`;
          subjWTeacher.subjectId = subject.id;
          subject.user_subject_class.forEach((teacher: any) => {
            texto += ` - ${teacher.user.name}`;
            subjWTeacher.title = texto;
            subjWTeacher.model_id = teacher.class?.school_exam_model_id;
          });
          this.listSubjectWTeacher.push(subjWTeacher);
        });
      },
    });
  }
  selectedTab = 0;
  defaultLimit = 20;
  listSubjectWTeacherResponse!: any;
  listSubjectWTeacher: subjectSelector[] = [];
  selectedSubject: any = "";
  selectControl = new FormControl("");
  tableContent: any;
  schoolExams!: SchoolExamsModel[] | undefined;
  tableExamsHeaders!: any;
  loadTabContent(tabIndex: number) {
    if (tabIndex === 3) {
      this.getListOfSubjectWithTeacher(this.defaultLimit);
    }
  }
  updateSelector() {
    this.selectControl.valueChanges.subscribe(async (value) => {
      if (value) {
        this.selectedSubject = value;
        const model_id = await this.listSubjectWTeacher.find(async (i) => {
          i.subjectId === value;
        });
        this.schoolExamService
          .getExamsClassModel(model_id?.model_id ? model_id.model_id : "1")
          .subscribe({
            next: (response) => {
              this.schoolExams = response.data?.data;
              this.tableExamsHeaders = this.schoolExams;
            },
            error: (error) => {
              // console.error(
              //   "Problemas na recuperação das avaliações da escola",
              //   error
              // );
            },
            complete: () => {
              this.loadClassTable(value);
            },
          });
      } else {
        this.tableContent = null;
      }
    });
  }
  loadClassTable(subjectId: string) {
    // this.tableContent = `Loaded content for subject ${subjectId}`;
    this.userService
      .getUserByClassAndSubjectWithExams(this.turmaId, subjectId)
      .subscribe({
        next: (response) => {
          this.tableContent = response.data?.data;
        },
        error: (error) => {
          // console.error(
          //   "error getting content from users filtered by class and subject",
          //   error
          // );
        },
        complete: () => {},
      });
  }
  getGradeInterativa(class_id: string) {
    this.schoolScheduleService.getAllWoutPagination().subscribe({
      next: (response) => {
        if (response.data) {
          this.timeSlotSchoolItems = response.data;
        }
      },
      complete: () => {
        if (!this.timeSlotSchoolItems && !this.scheduleModelId) {
          this.isScheduleEditable = true;
          this.isTimeSlotEditable = true;
        }
        if (this.timeSlotSchoolItems && !this.scheduleModelId) {
          this.isScheduleEditable = true;
          this.isTimeSlotEditable = true;
        }
        if (this.timeSlotSchoolItems && this.scheduleModelId) {
          this.isTimeSlotEditable = false;
          this.isScheduleEditable = false;
        }
      },
    });
    this.getGradeUpdtInfos();
  }
  getGradeUpdtInfos(disciplinaSelected?: gradeInterativaCellDTO) {
    if (this.scheduleModelId) {
      this.schoolScheduleService
        .getOneByClassAndUserSubjectClass(this.scheduleModelId, this.turmaId)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.gradeInterativa = response.data;
              this.isGradeWPending = this.gradeInterativa.week_days.some(
                (day) =>
                  day.class_slots.some(
                    (classSlot) => classSlot.slot_type === SlotType.pending
                  )
              );
              this.diasDaSemana = this.extractWeekDays(
                this.gradeInterativa.week_days
              );
            }
          },
          error:(error) => {
            console.log(error)
          },
          complete: () => {},
        });
    }

    this.classScheduleSubjectService
      .getGradeInterativa(this.turmaId)
      .subscribe({
        next: (response) => {
          this.gradeInteractiveData = Array.isArray(response.data)
            ? response.data
            : ([] as ClassScheduleSubjectModel[]);

          // console.log("Dados recebidos:", this.gradeInteractiveData);

          // ordenação
          this.gradeInteractiveData.sort((a, b) => {
            // primeiro esse
            if (!a.has_max_weekly_hours && !a.is_teacher_unavailable) {
              return -1;
            }
            if (!b.has_max_weekly_hours && !b.is_teacher_unavailable) {
              return 1;
            }

            // dps esse
            if (a.has_max_weekly_hours && !a.is_teacher_unavailable) {
              return -1;
            }
            if (b.has_max_weekly_hours && !b.is_teacher_unavailable) {
              return 1;
            }

            // dps esse
            if (!a.has_max_weekly_hours && a.is_teacher_unavailable) {
              return -1;
            }
            if (!b.has_max_weekly_hours && b.is_teacher_unavailable) {
              return 1;
            }

            // dps esse has_max_weekly_hours && is_teacher_unavailable
            return 0;
          });

          // console.log("ordenados:", this.gradeInteractiveData);
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.cdr.detectChanges();
          setTimeout(() => {
            if (disciplinaSelected) {
              this.currentGradeInteractive = this.gradeInteractiveData.find(
                (item) => item.id === disciplinaSelected.id
              );
            }
          }, 150);
        },
      });
  }

  getColorClass(item: any, elementType: string): string {
    if (!item.has_max_weekly_hours && item.is_teacher_unavailable) {
      return "gray-text";
    } else if (!item.has_max_weekly_hours && !item.is_teacher_unavailable) {
      return elementType === "span" ? "black-text" : "light-gray-text";
    } else if (item.has_max_weekly_hours && item.is_teacher_unavailable) {
      return "light-blue-text";
    } else if (item.has_max_weekly_hours && !item.is_teacher_unavailable) {
      return "blue-text";
    }
    return "";
  }
  getTooltipText(item: any): string {
    if (!item.has_max_weekly_hours && item.is_teacher_unavailable) {
      return "Sem disponibilidade de dia e horário"; // gray-text
    } else if (!item.has_max_weekly_hours && !item.is_teacher_unavailable) {
      return "Com disponibilidade de dia e horário"; // black-text e light-gray-text
    } else if (item.has_max_weekly_hours && item.is_teacher_unavailable) {
      return "Com aulas totais atribuídas e sem disponibilidade no dia e horário"; // light-blue-text
    } else if (item.has_max_weekly_hours && !item.is_teacher_unavailable) {
      return "Com aulas totais atribuídas mas com disponibilidade no dia e horário"; // b lue-text
    }
    return "";
  }

  toggleSwitch(panel: number): void {
    if (panel === 1) {
      if (!this.isPanel1Active && !this.isPanel2Active) {
        this.isPanel2Active = true;
      } else {
        if (!this.isPanel1Active) {
          this.isPanel1Active = true;
          this.isPanel2Active = false;
        } else {
          this.isPanel1Active = true;
          this.isPanel2Active = false;
        }
      }
    } else {
      if (!this.isPanel1Active && !this.isPanel2Active) {
        this.isPanel1Active = true;
      } else {
        if (!this.isPanel2Active) {
          this.isPanel2Active = true;
          this.isPanel1Active = false;
        } else {
          this.isPanel2Active = true;
          this.isPanel1Active = false;
        }
      }
    }
    const updtIsUsingScheduleModel: Partial<Turma> = {
      is_using_schedule_model: this.isPanel2Active,
    };
    this.turmaService.update(this.turmaId, updtIsUsingScheduleModel).subscribe({
      complete: () => {
        // console.log("chamada concluída com sucesso");
      },
    });
  }

  getCurrentTimeSlot(currentTimeSlot: any) {
    const isEqual = this.currentTimeSlot;
    this.currentTimeSlot = currentTimeSlot;
    if (this.currentTimeSlot?.id) {
      const addScheduleModel: AddScheduleModelDTO = {
        schedule_model_id: this.currentTimeSlot.id,
      };
      if (this.currentTimeSlot?.id == this.scheduleModelId) {

      } else {
        this.schoolScheduleService
          .addScheduleModel2Class(this.turmaId, addScheduleModel)
          .subscribe({
            next: (response) => {
              if(response.data){
                this.scheduleModelId = response.data.schedule_model_id
              }
            },
            complete: () => {
              this.getGradeUpdtInfos();
            },
          });
      }
    }
  }

  fetchIsEditable(isEditable: boolean) {
    this.fetchSize = isEditable;
  }

  isCurrentTimeSlotSaved() {
    return this.currentTimeSlot?.id ? true : false;
  }
  saveChanges() {
    // Perform save logic
    this.isScheduleEditable = false; // Reset after saving
  }
  openMatSelect(cellSlot: gradeInterativaCellDTO) {
    if (this.matSelect) {
      this.classScheduleSubjectService
        .getGradeInterativa(this.turmaId, cellSlot.id)
        .subscribe({
          next: (response) => {
            if (response.data)
              this.gradeInteractiveData = Array.isArray(response.data)
                ? response.data
                : ([] as ClassScheduleSubjectModel[]);

            // console.log("Dados recebidos:", this.gradeInteractiveData);

            // ordenação
            this.gradeInteractiveData.sort((a, b) => {
              // primeiro esse
              if (!a.has_max_weekly_hours && !a.is_teacher_unavailable) {
                return -1;
              }
              if (!b.has_max_weekly_hours && !b.is_teacher_unavailable) {
                return 1;
              }

              // dps esse
              if (a.has_max_weekly_hours && !a.is_teacher_unavailable) {
                return -1;
              }
              if (b.has_max_weekly_hours && !b.is_teacher_unavailable) {
                return 1;
              }

              // dps esse
              if (!a.has_max_weekly_hours && a.is_teacher_unavailable) {
                return -1;
              }
              if (!b.has_max_weekly_hours && b.is_teacher_unavailable) {
                return 1;
              }

              // dps esse has_max_weekly_hours && is_teacher_unavailable
              return 0;
            });
          },
          complete: () => {
            this.matSelect.open(); // Open the material select
          },
        });
    }
  }

  toggleEdit() {
    this.isScheduleEditable = !this.isScheduleEditable;
  }

  extractWeekDays(weekSchedule: gradeInterativaDayDTO[]): number[] {
    // Extract unique week_day values from the input array
    const days = weekSchedule.map((day) => day.week_day);

    // Use Set to ensure the days are unique and then convert back to an array
    return Array.from(new Set(days)).sort((a, b) => a - b);
  }

  getDiasDaSemana(day: number, weekLength: number): [number, number] {
    return [day, weekLength];
  }

  getGradeInterativaByDay(gradeInterativa: gradeInterativaDTO, index: any) {
    if (gradeInterativa) {
      return gradeInterativa.week_days[index];
    } else {
      return this.baseGradeInterativaDay;
    }
  }
  isSelectorAndFilterOn() {
    if (this.isScheduleEditable) {
      return true;
    }
    if (this.scheduleModelId) {
      if (this.isScheduleEditable) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onFilterToggleChange() {
    this.isGradeFiltered = !this.isGradeFiltered;
  }

  updateClassIntervalo(event: any) {
    this.updateSlotType(
      this.gradeInterativa,
      event,
      event.start_time,
      event.end_time
    );
  }

  classSlotEvent(event: gradeInterativaCellDTO) {
    this.updateClassSlot(event);
  }

  cleanClassIntervalo(event: any) {
    this.cleanSlotType(
      this.gradeInterativa,
      event,
      event.start_time,
      event.end_time
    );
  }

  updateClassSlot(targetObject: gradeInterativaCellDTO) {
    if (targetObject.id) {
      const updateClassSlot: Partial<gradeInterativaCellDTO> = {
        slot_type: targetObject.slot_type,
        user_subject_class_id: targetObject.user_subject_class_id,
      };
      this.classScheduleSubjectService
        .updtClassSlotId(targetObject.id, updateClassSlot)
        .subscribe({
          next: (response) => {
            // console.log(response.data);
            this.currentGradeInteractive = this.selectedDisciplina;
            this.getGradeUpdtInfos(this.currentGradeInteractive);
          },
          error: (err) => {
            this._snackBar.open(
              "Problemas em definir a aula no horário, tente novamente mais tarde",
              "Fechar",
              {
                duration: 3000,
                panelClass: ["mat-snackbar-error"],
              }
            );
          },
        });
    }
  }

  updateSlotType(
    targetObject: gradeInterativaDTO,
    targetCell: gradeInterativaCellDTO,
    startTime: string,
    endTime: string
  ) {
    targetObject.week_days.forEach((day: gradeInterativaDayDTO) => {
      day.class_slots = day.class_slots.map((slot: gradeInterativaCellDTO) => {
        if (slot.start_time === startTime && slot.end_time === endTime) {
          slot.slot_type = SlotType.intervalo;
        } else {
          if (slot.slot_type == SlotType.intervalo) {
            slot.slot_type = SlotType.pending;
          }
        }
        return slot;
      });
    });
    const updatedCell: Partial<gradeInterativaCellDTO> = {
      slot_type: SlotType.intervalo,
      user_subject_class_id: "",
    };
    this.classScheduleSubjectService
      .updtClassSlotId(targetCell.id!, updatedCell)
      .subscribe({
        next: (data) => {},
        complete: () => {
          this.currentGradeInteractive = this.selectedDisciplina;
          this.getGradeUpdtInfos(this.currentGradeInteractive);
        },
      });
  }
  cleanSlotType(
    targetObject: gradeInterativaDTO,
    targetCell: gradeInterativaCellDTO,
    startTime: string,
    endTime: string
  ) {
    targetObject.week_days.forEach((day: gradeInterativaDayDTO) => {
      day.class_slots = day.class_slots.map((slot: gradeInterativaCellDTO) => {
        if (slot.start_time === startTime && slot.end_time === endTime) {
          slot.slot_type = SlotType.pending;
          const cleanCell: Partial<ClassScheduleSlot> = {
            slot_type: slot.slot_type,
            user_subject_class_id: "",
          };
          if (slot.id) this.cleanCell(slot.id, cleanCell);
        }
        return slot;
      });
    });
  }
  cleanCellCall(event: any) {
    const cleanCell: Partial<ClassScheduleSlot> = {
      slot_type: SlotType.pending,
      user_subject_class_id: "",
    };
    this.cleanCell(event.id, cleanCell);
  }
  cleanCell(classSlotId: string, classSlotContent: Partial<ClassScheduleSlot>) {
    this.classScheduleSubjectService
      .updtClassSlotId(classSlotId!, classSlotContent)
      .subscribe({
        next: (data) => {
          // console.log("exclusao feita com sucesso");
          this.currentGradeInteractive = this.selectedDisciplina;
          this.getGradeUpdtInfos(this.currentGradeInteractive);
        },
        error: (err) => {
          console.log("erro na limpeza");
        },
      });
  }
  handleHover(hoverStatus: boolean): void {
    this.isCellBtnHovered = hoverStatus;
  }
  saveTimeSchedule(timeSlot2Create: TimeSlotModel) {
    //console.log(timeSlot2Create);
    if (timeSlot2Create.id) {
      this.schoolScheduleService
        .update(timeSlot2Create.id, timeSlot2Create)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.cdr.detectChanges();
            }
            this._snackBar.open(
              "A grade de horário escolar foi atualizada com sucesso",
              "Fechar",
              {
                duration: 2000,
                panelClass: ["mat-snackbar-success"],
              }
            );
          },
          error: (err) => {
            //console.log("error in updating new timeSchedule", err);
            this._snackBar.open(
              "Houve um problema ao atualizar a grade de horário escolar, tente novamente mais tarde",
              "Fechar",
              {
                duration: 2000,
                panelClass: ["mat-snackbar-error"],
              }
            );
          },
          complete: () => {},
        });
    } else {
      this.schoolScheduleService.save(timeSlot2Create).subscribe({
        next: (response) => {
          if (response.data) {
            this.timeSlotSchoolItems.push(response.data);
            this.cdr.detectChanges();
          }
          this._snackBar.open(
            "A grade de horário escolar foi salva com sucesso",
            "Fechar",
            {
              duration: 2000,
              panelClass: ["mat-snackbar-success"],
            }
          );
        },
        error: (err) => {
          //console.log("error in creating new timeSchedule", err);
          this._snackBar.open(
            "Houve um problema ao salvar a grade de horário escolar, tente novamente mais tarde",
            "Fechar",
            {
              duration: 2000,
              panelClass: ["mat-snackbar-error"],
            }
          );
        },
        complete: () => {},
      });
    }
  }
}

export interface subjectSelector {
  title: string;
  subjectId: string;
  model_id?: string;
}
