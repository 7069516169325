import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExamType } from 'src/app/core/enum/examType.enum';
import { DrawerService } from '../../services/drawer.service';

@Component({
  selector: "app-drawer-card",
  templateUrl: "./drawer-card.component.html",
  styleUrls: ["./drawer-card.component.sass"],
})
export class DrawerCardComponent {
  @Input() exams!: {
    id: string;
    title: string;
    content: {
      id: string;
      title: string;
      weight: number;
      exam_type: ExamType;
    }[];
  };

  constructor(private router: Router, private drawerService: DrawerService) {}

  ngOnInit(): void {}

  getExamType(examType: ExamType) {
    return examType === ExamType.aval;
  }

  navigateToEditModel(): void {
    this.router.navigate(["privado","planoletivo","aval","edit",this.exams.id]);
    this.drawerService.closeDrawer()
  }
}
