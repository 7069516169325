import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: "root",
})
export class StudentService extends BaseService<any> {
  override baseUrl: string = `${this.baseUrl}/user`;

  public getAllStudentsFromClassAndSubject(
    class_id: string,
    subject_id: string
  ): Observable<any> {
    const url = `${this.baseUrl}/?limit=50&class_id=${class_id}&subject_id=${subject_id}`;
    return this.api
      .get<any>(url)
      .pipe(
        tap(),
        catchError(this.handleError("Problemas no retorno dos alunos da turma"))
      );
  }

  public getAllStudentsWithAttendanceFromClassAndSubject(
    class_id: any,
    subject_id: any,
    attendanceMonth: any = 0,
    roleId: number = 1
  ): Observable<any> {
    const url = `${this.baseUrl}?limit=50&role=${roleId}&class_id=${class_id}&subject_id=${subject_id}&attendanceMonth=${attendanceMonth}`;
    return this.api
      .get<any>(url)
      .pipe(
        tap(),
        catchError(this.handleError("Problemas no retorno dos alunos da turma"))
      );
  }
}
