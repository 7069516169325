import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TimeSlotModel } from "../../models/time-slot.model";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TimeSlotDialogComponent } from "../time-slot-dialog/time-slot-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-schedule-grade-time-slot",
  templateUrl: "./schedule-grade-time-slot.component.html",
  styleUrls: ["./schedule-grade-time-slot.component.sass"],
})
export class ScheduleGradeTimeSlotComponent {
  @Input() timeSlotsArray!: TimeSlotModel[];
  @Input() isEditable: boolean = false;
  @Input() isScheduleEditable: boolean = false;
  @Input() scheduleModelId!: string | null;
  @Output() openTimeSlotDialog = new EventEmitter<any>();
  @Output() currentTimeSlot = new EventEmitter<TimeSlotModel>();
  @Output() getTimeSlotIsEditable = new EventEmitter<boolean>();
  timeSlot: TimeSlotModel = {
    title: "",
    schedule_time_slots: [
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
      { start_time: "00:00", end_time: "00:00" },
    ],
  };
  selectedTimeSlot!: TimeSlotModel | undefined;
  isScheduleTimeEditable: boolean = false;
  isFirstScheduleTimeSLot: boolean = false;
  isMenuOpen: boolean = false;
  isEditing: boolean = false;
  hoveredIndex: number | null = null;
  oldTimeSlotSchedule!: TimeSlotModel;

  form: FormGroup = new FormGroup({
    tel: new FormControl(null),
  });

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.timeSlotsArray && this.timeSlotsArray.length > 0) {
      this.isFirstScheduleTimeSLot = false;
    } else {
      this.isFirstScheduleTimeSLot = true;
    }
    this.isEditing = this.isEditable;
    if (this.scheduleModelId) {
      this.changeTimeSlotSchedule(undefined, this.scheduleModelId);
      this.isScheduleTimeEditable = this.isScheduleEditable;
    } else {
      this.isScheduleTimeEditable = true;
    }
    this.getTimeSlotIsEditable.emit(this.isEditing);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.timeSlotsArray && this.timeSlotsArray.length > 0) {
      this.isFirstScheduleTimeSLot = false;
    } else {
      this.isFirstScheduleTimeSLot = true;
    }
    this.isEditing = this.isEditable;
    if (this.scheduleModelId) {
      this.changeTimeSlotSchedule(undefined, this.scheduleModelId);
      this.isScheduleTimeEditable = this.isScheduleEditable;
    } else {
      this.isScheduleTimeEditable = true;
    }
  }
  addTimeSlot() {
    this.timeSlot.schedule_time_slots.push({
      start_time: "00:00",
      end_time: "00:00",
    });
  }

  updateTimeSlot(event: { key: string; value: string }, index: number) {
    if (event.key === "start_time") {
      this.timeSlot.schedule_time_slots[index].start_time = event.value;
    } else if (event.key === "end_time") {
      this.timeSlot.schedule_time_slots[index].end_time = event.value;
    } else {
      // console.error("error in schedule time slot logic");
    }
  }

  restartScheduleModel() {
    this.timeSlot = {
      title: "",
      schedule_time_slots: [
        { start_time: "00:00", end_time: "00:00" },
        { start_time: "00:00", end_time: "00:00" },
        { start_time: "00:00", end_time: "00:00" },
        { start_time: "00:00", end_time: "00:00" },
        { start_time: "00:00", end_time: "00:00" },
        { start_time: "00:00", end_time: "00:00" },
      ],
    };
    this.selectedTimeSlot = this.timeSlot;
    this.currentTimeSlot.emit(this.selectedTimeSlot);
  }

  changeTimeSlotSchedule(timeSlot?: TimeSlotModel, timeSlotId?: string) {
    if (timeSlotId) {
      this.selectedTimeSlot = this.timeSlotsArray.find(
        (timeSlot) => timeSlot.id === timeSlotId
      );
      this.timeSlot = this.selectedTimeSlot!;
    } else {
      this.selectedTimeSlot = timeSlot;
      this.timeSlot = timeSlot!;
    }
    this.currentTimeSlot.emit(this.selectedTimeSlot);
  }

  changeTimeSlotScheduleDropDown(
    timeSlot?: TimeSlotModel,
    timeSlotId?: string
  ) {
    if (!this.timeSlot.id) {
      if (timeSlotId) {
        this.selectedTimeSlot = this.timeSlotsArray.find(
          (timeSlot) => timeSlot.id === timeSlotId
        );
        this.timeSlot = this.selectedTimeSlot!;
      } else {
        this.selectedTimeSlot = timeSlot;
        this.timeSlot = timeSlot!;
      }
      this.currentTimeSlot.emit(this.selectedTimeSlot);
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "320px",
        data: {
          text: "Ao mudar de Modelo de horário para a grade, tudo o que foi preenchido será deletado. Deseja continuar?",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (timeSlotId) {
            this.selectedTimeSlot = this.timeSlotsArray.find(
              (timeSlot) => timeSlot.id === timeSlotId
            );
            this.timeSlot = this.selectedTimeSlot!;
          } else {
            this.selectedTimeSlot = timeSlot;
            this.timeSlot = timeSlot!;
          }
          this.currentTimeSlot.emit(this.selectedTimeSlot);
        } else {
        }
      });
    }
  }

  toggleMenu(state: boolean): void {
    this.isMenuOpen = state;
  }
  toggleEdit(): void {
    if (!this.isEditing) {
      this.oldTimeSlotSchedule = JSON.parse(JSON.stringify(this.timeSlot));
    }
    this.isEditing = !this.isEditing;
    if (this.timeSlot.id) {
      if (this.isEditing) {
        //console.log("Editing mode activated.");
      } else {
        // console.log("Changes saved.");
        if (
          this.areScheduleTimeSlotsEqual(
            this.timeSlot,
            this.oldTimeSlotSchedule
          )
        ) {
        } else {
          this.update();
        }
      }
    } else {
      if (this.isEditing) {
        //console.log("Creation mode activated.");
      } else {
        //console.log("Changes saved.");
        if (
          this.areScheduleTimeSlotsEqual(
            this.timeSlot,
            this.oldTimeSlotSchedule
          )
        ) {
        } else {
          this.save();
        }
      }
    }
    this.getTimeSlotIsEditable.emit(this.isEditing);
  }

  areScheduleTimeSlotsEqual(object1: any, object2: any): boolean {
    if (!object1.schedule_time_slots || !object2.schedule_time_slots) {
      return false; // Ensure both objects have schedule_time_slots
    }

    // Compare lengths first for a quick check
    if (
      object1.schedule_time_slots.length !== object2.schedule_time_slots.length
    ) {
      return false;
    }

    // Compare every item in the schedule_time_slots
    return object1.schedule_time_slots.every((slot1: any) =>
      object2.schedule_time_slots.some(
        (slot2: any) =>
          slot1.start_time === slot2.start_time &&
          slot1.end_time === slot2.end_time &&
          slot1.schedule_model_id === slot2.schedule_model_id
      )
    );
  }

  save() {
    //console.log("Time slots saved:", this.timeSlots);
    const dialogRef = this.dialog.open(TimeSlotDialogComponent, {
      maxWidth: "320px",
      data: { title: this.timeSlot.title },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.timeSlot.title = result;
        this.openTimeSlotDialog.emit(this.timeSlot);
      } else {
        this.isEditing = !this.isEditing;
        this.getTimeSlotIsEditable.emit(this.isEditing);
      }
    });
  }

  update() {
    //console.log("Time slots updated:", this.timeSlots);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "320px",
      data: {
        text: "A mudança de horário irá alterar todas as turmas que utilizam este modelo de horário.Deseja continuar?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openTimeSlotDialog.emit(this.timeSlot);
      } else {
        this.isEditing = !this.isEditing;
        this.getTimeSlotIsEditable.emit(this.isEditing);
      }
    });
  }

  removeTimeSlot(index: number): void {
    this.timeSlot.schedule_time_slots.splice(index, 1);
  }

  setHoveredIndex(index: number) {
    this.hoveredIndex = index;
  }

  clearHoveredIndex() {
    this.hoveredIndex = null;
  }
}
