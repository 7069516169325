<div class="time-slots-container mat-elevation-z1">
  <div class="header">
    <mat-icon *ngIf="!timeSlot?.title" class="time-btn">schedule</mat-icon>
    <p *ngIf="timeSlot?.title">{{ timeSlot.title }}</p>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="isScheduleEditable && isEditing"
      aria-label="items do menu de horários"
      (menuOpened)="toggleMenu(true)"
      (menuClosed)="toggleMenu(false)"
    >
      <mat-icon [ngClass]="{ rotated: isMenuOpen }">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let slotTime of timeSlotsArray; index as i " (click)="changeTimeSlotScheduleDropDown(slotTime)" >
        <span>{{slotTime.title}}</span>
        <mat-icon
        *ngIf="selectedTimeSlot === slotTime"
        class="selected-icon"
        aria-hidden="true"
        color="primary"
      >
        done
      </mat-icon>
      </button>
      <button mat-menu-item (click)="restartScheduleModel()">
        <span>+ Criar novo</span>
      </button>
    </mat-menu>
  </div>

  <ng-container
    *ngFor="let slot of timeSlot?.schedule_time_slots; let i = index"
  >
    <div
      class="time-slot-container"
      (mouseenter)="setHoveredIndex(i)"
      (mouseleave)="clearHoveredIndex()"
    >
      <mat-form-field class="time-slot" appearance="outline">
        <app-time-slot-input
          [isEditing]="isEditing"
          [timeSlotContent]="slot"
          (timeSlotChange)="updateTimeSlot($event, i)"
        ></app-time-slot-input>
      </mat-form-field>
      <button
        *ngIf="
          isEditing &&
          hoveredIndex === i &&
          timeSlot.schedule_time_slots.length === i + 1
        "
        mat-stroked-button
        matSuffix
        class="close-button"
        (click)="removeTimeSlot(i)"
        title="Remover horário"
      >
        <div class="circle">
          <div class="cross"></div>
        </div>
      </button>
    </div>
    <mat-divider *ngIf="i < timeSlot.schedule_time_slots.length"></mat-divider>
  </ng-container>

  <div class="add-time-slot" *ngIf="isEditing && isScheduleTimeEditable" (click)="addTimeSlot()">
    <mat-icon>add</mat-icon>
  </div>

  <button
    *ngIf="isScheduleTimeEditable"
    mat-raised-button
    class="button-rounded save-button"
    color="primary"
    (click)="toggleEdit()"
  >
    {{ isEditing ? "Salvar" : "Editar" }}
  </button>
</div>
