import { Component, ElementRef, HostListener, Renderer2, ViewChild } from "@angular/core";
import { BreadcrumbService } from "xng-breadcrumb";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { AuthService } from "src/app/core/services/auth.service";
import { UserRoles } from "src/app/core/enum/user-role.enum";
import permissionIndex from "src/app/core/guards/permissions-index";
import { DrawerService } from "../shared/services/drawer.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SelectSchoolComponent } from "../../public/select-school/select-school.component";
@Component({
  selector: "app-layoutbase",
  templateUrl: "./layoutbase.component.html",
  styleUrls: ["./layoutbase.component.sass"],
})
export class LayoutbaseComponent {
  @ViewChild("drawer") drawer!: DrawerService;

  navLinks = permissionIndex;
  defaultSchool: any;
  currentUser: any;
  userRole: string = "Admin";
  userRoleId: number = 5;
  drawerContent: any;
  drawerCondition: boolean = false;
  //todo: criar enum para drawerType
  drawerType: boolean = false;

  screenWidth: number = 0;

  error = null;
  isLoading = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private drawerService: DrawerService,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    public dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.breadcrumbService.set("@home", "Início");
    this.currentUser = this.authService.getCurrentUser();
    this.drawerService.drawerContent$.subscribe((content) => {
      if(content){
        this.drawerContent = content;
        if(content.isText)
          this.drawerType = content.isText;
      }
    });
    this.drawerService.drawerOpenState$.subscribe((isOpen) => {
      this.drawerCondition = isOpen
    });
    this.checkScreenSize();
  }


  // Método para verificar o tamanho da tela
  checkScreenSize() {
    this.screenWidth = window.innerWidth;
    const sidebar = this.el.nativeElement.querySelector('#sidebar');
    const hasClass = sidebar.classList.contains('side-bar-mobile');
    if (this.screenWidth < 768 && hasClass) {
      this.renderer.removeClass(sidebar, 'side-bar-mobile');
    } else {
      this.renderer.addClass(sidebar, 'side-bar-mobile');
    }

  }

  // Atualiza a largura da tela ao redimensionar
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    const sidebar = this.el.nativeElement.querySelector('#sidebar');
    const hasClass = sidebar.classList.contains('side-bar-mobile');
    if (this.screenWidth < 768 && hasClass) {
      this.renderer.removeClass(sidebar, 'side-bar-mobile');
    } else {
      this.renderer.addClass(sidebar, 'side-bar-mobile');
    }
  }

  isLinkVisible(link: string[]): boolean {
    //const userRole = this.userRoleId;
    //const user = this.authService.getCurrentUser();
    //const userRole = Number(this.authService.getCurrentUser().role_id);
    const tokenPermissions = this.authService.getTokenData().user_info.permissions ?? [];
    const hasPermission = link.some((permission) =>
      tokenPermissions.includes(permission),
    );
    return hasPermission;
  }

  mapRolesToNumbers(roles: string[]): number[] {
    return roles.map((role) => UserRoles[role as keyof typeof UserRoles] || 0);
  }

  getUserRoleText(): string {
    const userRole = this.getUserRole();
    return this.getRoleTextByNumber(userRole);
  }

  getRoleTextByNumber(roleNumber: number): string {
    switch (roleNumber) {
      case UserRoles.Aluno:
        return "Aluno";
      case UserRoles.Professor:
        return "Professor";
      case UserRoles.ProfessorAdmin:
        return "Professor Admin";
      case UserRoles.Gestor:
        return "Gestor";
      case UserRoles.Admin:
        return "Admin";
      default:
        return "Unknown Role";
    }
  }

  getUserRole(): number {
    return this.authService.getCurrentUser().role_id; // For example, assuming the user has role 'ProfessorAdmin'
  }


  leftMenu() {
    const sidebar = this.el.nativeElement.querySelector('#sidebar');
    const hasClass = sidebar.classList.contains('side-bar-mobile');
    if (hasClass) {
      this.renderer.removeClass(sidebar, 'side-bar-mobile');
    } else {
      this.renderer.addClass(sidebar, 'side-bar-mobile');
    }
  }

  isLoggedIn() {
    return this.authService.getCurrentUser() != null && this.authService.getCurrentUser() != undefined && this.authService.getCurrentUser().id != null;
  }

  logout(label: string) {
    if (label == "Sair") {
      this.authService.purgeAuth();
      this.router.navigate(["/login"]);
    } else if (label == "Mudar escola") {
      this.redirectTo()
    }
  }

  redirectTo() {
    const dialogRef = this.dialog.open(SelectSchoolComponent, {
      width: "350px",
      data: { message: "Selecione a escola que deseja? " },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      this.router.navigate(["/privado"]);
    });
  }

}
