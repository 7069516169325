import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { DialogTableComponent } from "./component/dialog-table/dialog-table.component";
import { BaseTableComponent } from "./component/base-table/base-table.component";
import { SharedModule as PrivateSharedModule } from "../../shared/shared.module";
import { CustomPaginationComponent } from "./component/custom-pagination/custom-pagination.component";
import { LinkDialogComponent } from "./component/link-dialog/link-dialog.component";
import { MiniCardComponent } from "./component/mini-card/mini-card.component";
import { MiniCardWSidenavComponent } from "./component/mini-card-w-sidenav/mini-card-w-sidenav.component";
import { MiniAddCardComponent } from "./component/mini-add-card/mini-add-card.component";
import { AvalCardComponent } from "./component/aval-card/aval-card.component";
import { AvalDialogComponent } from './component/aval-dialog/aval-dialog.component';
import { PermissionDialogComponent } from './component/permission-dialog/permission-dialog.component';
import { ScheduleTimeSlotComponent } from './component/schedule-time-slot/schedule-time-slot.component';
import { TimeSlotDialogComponent } from './component/time-slot-dialog/time-slot-dialog.component';
import { TimeSlotInputComponent } from './component/time-slot-input/time-slot-input.component';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { ScheduleGradeTimeSlotComponent } from './component/schedule-grade-time-slot/schedule-grade-time-slot.component';
import { ScheduleGradeTeacherSubjectComponent } from './component/schedule-grade-teacher-subject/schedule-grade-teacher-subject.component';
import { WarningDialogComponent } from './component/warning-dialog/warning-dialog.component';

@NgModule({
  declarations: [
    DialogTableComponent,
    LinkDialogComponent,
    BaseTableComponent,
    CustomPaginationComponent,
    MiniCardComponent,
    MiniCardWSidenavComponent,
    MiniAddCardComponent,
    AvalCardComponent,
    AvalDialogComponent,
    PermissionDialogComponent,
    ScheduleTimeSlotComponent,
    TimeSlotDialogComponent,
    TimeSlotInputComponent,
    ConfirmDialogComponent,
    ScheduleGradeTimeSlotComponent,
    ScheduleGradeTeacherSubjectComponent,
    WarningDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatOptionModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonToggleModule,
    PrivateSharedModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatOptionModule,
    MatInputModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CustomPaginationComponent,
    DatePipe,
    MiniCardComponent,
    MiniCardWSidenavComponent,
    MiniAddCardComponent,
    AvalCardComponent,
    ScheduleTimeSlotComponent,
    TimeSlotInputComponent,
    ScheduleGradeTimeSlotComponent,
    ScheduleGradeTeacherSubjectComponent
  ],
})
export class SharedModule {}
