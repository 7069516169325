<div class="wrap-title">
  <h1>Envio de plano de Tópicos</h1>
</div>
<p class="sub-title">
  Faça o envio do plano de tópicos para a escola, turma e disciplina.
</p>
<mat-card>
  <mat-card-content>
    <div class="container">
      <p>
        Baixe o modelo de planilha para envio: 
        <a href="/assets/modelo-planilha.xlsx" download>Download Planilha</a>
      </p>
      <form [formGroup]="topicForm">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Série</mat-label>
            <mat-select formControlName="grade">
              <mat-option [value]="0">Selecione uma série</mat-option>
              <mat-option *ngFor="let grade of grades" [value]="grade.id">
                {{ grade.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Disciplina</mat-label>
            <mat-select formControlName="disciplina" [disabled]="!topicForm.get('grade')?.value">
              <mat-option [value]="0">Selecione uma disciplina</mat-option>
              <mat-option *ngFor="let disc of disciplinas" [value]="disc.id">
                {{ disc.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <div class="container-upload" *ngIf="uploaderActive && !isUploading && !uploadSuccess"> 
        <div>
          <div
            ng2FileDrop
            (click)="fileInput.click()"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            [uploader]="uploader"
            class="file-upload"
          >
            <div class="div-icon-upload">
              <mat-icon>cloud_upload</mat-icon>
            </div>
            <p>Procure e escolha os arquivos que você deseja enviar</p>
            <div class="form-group">
              <input
                ng2FileSelect
                [uploader]="uploader"
                #fileInput
                formControlName="url"
                type="file"
                style="display: none"
                (change)="uploader.uploadAll()"
                multiple
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Loading -->
      <div class="loading-container" *ngIf="isUploading">
        <mat-spinner diameter="40"></mat-spinner>
        <p>Enviando arquivo...</p>
      </div>

      <!-- Sucesso -->
      <div class="success-container" *ngIf="uploadSuccess">
        <mat-icon color="primary">check_circle</mat-icon>
        <p>Arquivo enviado com sucesso!</p>
        <div class="file-info">
          <mat-icon>description</mat-icon>
          <a download>{{uploadedFileName}}</a>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>
