<div class="modal-container">
  <span mat-dialog-title>{{ data.title }}</span>

  <mat-dialog-content class="container-form">
    <mat-form-field appearance="outline" *ngIf="data.isInputVisible">
      <mat-label>{{ data.inputLabel }}</mat-label>
      <input class="width-input" matInput [(ngModel)]="permissionTitle" />
    </mat-form-field>
    <div *ngIf="data.message" class="descriptionSubtitle">
      <p>{{ data.message }}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="data.buttonVisible">
    <button 
      mat-button mat-stroked-button 
      class="button-rounded" 
      (click)="onCancel()"
      style="color: #3A40C7;"
    >
      Cancelar
    </button>
    <button 
      mat-raised-button 
      class="button-rounded" 
      color="primary" 
      (click)="onContinue()"
    >
      {{data.titleConfirmationButton}}
    </button>
  </mat-dialog-actions>
</div>

