import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DrawerService {
  private drawerToggleSubject = new Subject<void>();
  private drawerContentSubject = new BehaviorSubject<any>(null);
  private drawerOpenStateSubject = new BehaviorSubject<boolean>(false);

  drawerToggle$ = this.drawerToggleSubject.asObservable();
  drawerContent$ = this.drawerContentSubject.asObservable();
  drawerOpenState$ = this.drawerOpenStateSubject.asObservable();

  // Method to trigger drawer toggle
  toggleDrawer(content?: any): void {
    const isOpen = this.drawerOpenStateSubject.value;
    if (isOpen) {
      // Close the drawer and clean the content
      this.drawerOpenStateSubject.next(false);
      this.drawerContentSubject.next(null);
    } else {
      // Open the drawer with new content (if provided)
      this.drawerOpenStateSubject.next(true);
      this.drawerContentSubject.next(content || null);
    }
  }

  closeDrawer(): void {
    this.drawerOpenStateSubject.next(false);
    this.drawerContentSubject.next(null);
  }
}
